import React, { useEffect, useState } from "react";
import DraftEditor from "./Common/DraftEditor/DraftEditor";
import PrimaryButton from "./Common/PrimaryButton";
import AuthAction from "./auth/AuthAction";
import Tooltip from "./Tooltip";
import EditButton from "./Buttons/EditButton";
import { useGlobalContext } from "context/GlobalContext";
import { useParams, useLocation } from "react-router-dom";
import { updatePage } from "api";
import Permission from "./auth/Permission";

const CommonEditor = () => {
  const [page, setPage] = useState("");
  const { pageId } = useParams();
  const location = useLocation();

  const { designSystemMeta, setDesignSystemMeta, setLoading } =
    useGlobalContext();
  const [isEditing, setIsEditing] = useState(false);
  const [editorState, setEditorState] = useState("");

  useEffect(() => {
    const currentPage = designSystemMeta?.pages?.find(
      (page) => page.id == pageId
    );
    setPage(currentPage);

    if (currentPage?.content) {
      setEditorState(currentPage.content);
    } else {
      setEditorState(""); // Reset if content is empty
    }
  }, [pageId, location, designSystemMeta]);

  const saveChanges = async () => {
    const contentHtml = editorState; // editorState already holds HTML
    const dataToSubmit = {
      id: pageId,
      content: contentHtml,
    };

    try {
      setLoading(true);
      await updatePage(dataToSubmit, page?.id);
      setIsEditing(false);
      setPage({ ...page, content: contentHtml });
      updateState(contentHtml);
    } catch (error) {
      console.error("Failed to update page:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateState = (contentHtml) => {
    const newDesignSystemMeta = { ...designSystemMeta };
    const newPages = newDesignSystemMeta.pages.map((p) => {
      if (p.id !== page.id) {
        return p;
      } else {
        return {
          ...p,
          content: contentHtml,
        };
      }
    });
    newDesignSystemMeta.pages = newPages;
    setDesignSystemMeta(newDesignSystemMeta);
  };

  return (
    <div className='plt-contents-container w-100'>
      {isEditing ? (
        <div>
          <DraftEditor
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <div className='dis-mt-20 dis-mb-40'>
            <PrimaryButton
              onClick={() => setIsEditing(false)}
              text='Cancel'
              // className='dss-btn dss-btn-secondary dss-btn-lg dis-mr-20'
              variant= "secondary"
              size= "lg"
              className='dis-mr-20'
            />
            <PrimaryButton
              onClick={saveChanges}
              text='Save'
              // className='dss-btn dss-btn-primary dss-btn-lg'
              variant= "primary"
              size= "lg"
            />
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-between'>
          <div
            className='content-display'
            dangerouslySetInnerHTML={{
              __html: editorState,
            }}
          />
          <AuthAction>
            <Permission designSystem={designSystemMeta}>
              <span className='dis-ml-24' onClick={() => setIsEditing(true)}>
                <Tooltip position='top' arrow='btm-center' text='Edit content'>
                  <EditButton />
                </Tooltip>
              </span>
            </Permission>
          </AuthAction>
        </div>
      )}
    </div>
  );
};

export default CommonEditor;
