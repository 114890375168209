import { useGlobalContext } from "context/GlobalContext";
import { getValueByPath, replaceCharfromKey } from "helpers/token";
import React from "react";

const ElevationColumn = ({ rowData }) => {
  const { designSystem } = useGlobalContext();
  const data = JSON.parse(rowData?.value);
  const colorKey = replaceCharfromKey(data?.color);
  const color = getValueByPath(designSystem, `core.${colorKey}`);
  const inset = data?.type === "innerShadow" ? "inset" : "";
  return (
    <>
      <span className='plt-color-text plt-body-compact-sm'>
        {` ${inset} ${data?.x} ${data?.y} ${data?.blur} ${data?.spread} ${color?.value}`}
      </span>
    </>
  );
};

export default ElevationColumn;
