import React, { useEffect, useState } from "react";
import "assets/scss/pages/DesignSystem.scss";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import { useGlobalContext } from "context/GlobalContext";
import CommonEditor from "components/CommonEditor";
import { useParams } from "react-router-dom";

import PrimaryButton from "components/Common/PrimaryButton";
import { ButtonIcon } from "components/Icons";
import AvatarComponent from "components/Common/AvatarComponent";
import CheckboxComponent from "components/Common/CheckboxComponent";
import Inputs from "components/Inputs";
import RadioComponent from "components/Common/RadioComponent";
import Textarea from "components/Textarea";
import ToggleComponent from "components/Common/ToggleComponent";
import Tag from "components/Tag";
import Tooltip from "components/Tooltip";
import ToasterDisplay from "components/Common/ToasterComponent";
import AlertDisplay from "components/Common/AlertComponent";
import Tabs from "components/Tabs";

const ComponentlistPage = () => {
	const { id } = useParams();
	const [page, setPage] = useState("");
	const [pages, setPages] = useState([]);
	const [selectedTab, setSelectedTab] = useState(0);
	const [bannerImage, setBannerImage] = useState("");
	const { designSystemMeta } = useGlobalContext();

	useEffect(() => {
		if (designSystemMeta?.pages) {
			const ComponentlistPages = designSystemMeta.pages.filter((page) => page.type == "componentlist");
			setPages(ComponentlistPages);
			const currentPage = designSystemMeta.pages.find((page) => page.type == "componentlist");
			setPage(currentPage);
		}
	}, [designSystemMeta]);

	const bannerData = {
		heading: designSystemMeta?.design_system_name,
		description:
			"Foundations inform the basis of any great user interface, from accessibility standards to essential patterns for layout and interaction.",
		bannerImage: bannerImage,
	};

	let sideTabs = pages?.map((page) => {
		return { title: page.name, link: `/${id}/componentlist/${page.id}` };
	});

	const tabs = ["componentlist"];
	return (
		<DesignSystemLayout
			designSystemName={designSystemMeta?.design_system_name}
			bannerData={bannerData}
			tabs={tabs}
			selectedTab={selectedTab}
			setSelectedTab={setSelectedTab}
			setBannerImage={setBannerImage}
			sideTabs={sideTabs}
			title="componentlist"
		>
			<div className="tab-content-container">
				{/* component-Button */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24  dis-mt-48">Buttons</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Buttons Default</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<PrimaryButton text={"Button text"} size="lg" variant="primary" disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="md" variant="primary" disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="sm" variant="primary" disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="md" variant="primary" disabled={true} icon={false} />
					</div>

					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Button Fluid </h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<PrimaryButton
							text={"Button text"}
							size="lg"
							variant="primary"
							fluid={true}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton text={"Button text"} size="md" variant="primary" fluid={true} disabled={true} icon={false} />
						{/* <PrimaryButton text="Button text" className="dss-btn dss-btn-primary dss-btn-lg dss-btn-fluid" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-primary dss-btn-fluid disabled" /> */}
					</div>

					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Button Secondary </h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<PrimaryButton
							text={"Button text"}
							size="lg"
							variant="secondary"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton
							text={"Button text"}
							size="md"
							variant="secondary"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton
							text={"Button text"}
							size="sm"
							variant="secondary"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton
							text={"Button text"}
							size="md"
							variant="secondary"
							fluid={false}
							disabled={true}
							icon={false}
						/>
						{/* <PrimaryButton text="Button text" className="dss-btn dss-btn-secondary dss-btn-lg" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-secondary dss-btn-md" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-secondary dss-btn-sm" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-secondary disabled" /> */}
					</div>

					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Button Outline </h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<PrimaryButton
							text={"Button text"}
							size="lg"
							variant="outlined"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton
							text={"Button text"}
							size="md"
							variant="outlined"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton
							text={"Button text"}
							size="sm"
							variant="outlined"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton
							text={"Button text"}
							size="md"
							variant="outlined"
							fluid={false}
							disabled={true}
							icon={false}
						/>
						{/* <PrimaryButton text="Button text" className="dss-btn dss-btn-outlined dss-btn-lg" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-outlined dss-btn-md" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-outlined dss-btn-sm" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-outlined disabled" /> */}
					</div>

					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Button Danger </h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<PrimaryButton
							text={"Button text"}
							size="lg"
							variant="danger"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton
							text={"Button text"}
							size="md"
							variant="danger"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton
							text={"Button text"}
							size="sm"
							variant="danger"
							fluid={false}
							disabled={false}
							icon={false}
						/>
						<PrimaryButton text={"Button text"} size="md" variant="danger" fluid={false} disabled={true} icon={false} />
						{/* <PrimaryButton text="Button text" className="dss-btn dss-btn-danger dss-btn-lg" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-danger dss-btn-md" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-danger dss-btn-sm" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-danger disabled" /> */}
					</div>

					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Button Ghost </h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<PrimaryButton text={"Button text"} size="lg" variant="ghost" fluid={false} disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="md" variant="ghost" fluid={false} disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="sm" variant="ghost" fluid={false} disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="md" variant="ghost" fluid={false} disabled={true} icon={false} />
						{/* <PrimaryButton text="Button text" className="dss-btn dss-btn-ghost dss-btn-lg" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-ghost dss-btn-md" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-ghost dss-btn-sm" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-ghost disabled" /> */}
					</div>

					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Button Link </h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<PrimaryButton text={"Button text"} size="lg" variant="link" fluid={false} disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="md" variant="link" fluid={false} disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="sm" variant="link" fluid={false} disabled={false} icon={false} />
						<PrimaryButton text={"Button text"} size="md" variant="link" fluid={false} disabled={true} icon={false} />
						{/* <PrimaryButton text="Button text" className="dss-btn dss-btn-link dss-btn-lg" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-link dss-btn-md" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-link dss-btn-sm" />
						<PrimaryButton text="Button text" className="dss-btn dss-btn-link disabled" /> */}
					</div>

					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Button with icon</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<PrimaryButton
							text={"Button text"}
							size="md"
							variant="primary"
							fluid={false}
							disabled={false}
							icon={true}
							prefixIcon={<ButtonIcon />}
							suffixIcon={<ButtonIcon />}
						/>
						<PrimaryButton
							text={"Button text"}
							size="lg"
							variant="secondary"
							fluid={false}
							disabled={false}
							icon={true}
							prefixIcon={<ButtonIcon />}
							suffixIcon={<ButtonIcon />}
						/>
						<PrimaryButton
							text={"Button text"}
							size="sm"
							variant="outlined"
							fluid={false}
							disabled={false}
							icon={true}
							prefixIcon={<ButtonIcon />}
							suffixIcon={<ButtonIcon />}
						/>
						<PrimaryButton
							text={"Button text"}
							size="md"
							variant="danger"
							fluid={false}
							disabled={false}
							icon={true}
							prefixIcon={<ButtonIcon />}
							suffixIcon={<ButtonIcon />}
						/>
						<PrimaryButton
							text={"Button text"}
							size="md"
							variant="ghost"
							fluid={false}
							disabled={false}
							icon={true}
							prefixIcon={<ButtonIcon />}
							suffixIcon={<ButtonIcon />}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Disabled button</h3>
					<div className="d-flex flex-wrap dis-mb-32" style={{ gap: 20 }}>
						<div className="disabled-btn">
							<h5 className="plt-heading-sm plt-font-color-primary dis-mb-16">Primary</h5>
							<PrimaryButton
								text={"Button text"}
								size="lg"
								variant="primary"
								fluid={false}
								disabled={true}
								icon={true}
								prefixIcon={<ButtonIcon />}
								suffixIcon={<ButtonIcon />}
							/>
						</div>
						<div className="disabled-btn">
							<h5 className="plt-heading-sm plt-font-color-primary dis-mb-16">Secondary</h5>
							<PrimaryButton
								text={"Button text"}
								size="lg"
								variant="secondary"
								fluid={false}
								disabled={true}
								icon={true}
								prefixIcon={<ButtonIcon />}
								suffixIcon={<ButtonIcon />}
							/>
						</div>
						<div className="disabled-btn">
							<h5 className="plt-heading-sm plt-font-color-primary dis-mb-16">Outlined</h5>
							<PrimaryButton
								text={"Button text"}
								size="lg"
								variant="outlined"
								fluid={false}
								disabled={true}
								icon={true}
								prefixIcon={<ButtonIcon />}
								suffixIcon={<ButtonIcon />}
							/>
						</div>
						<div className="disabled-btn">
							<h5 className="plt-heading-sm plt-font-color-primary dis-mb-16">Danger</h5>
							<PrimaryButton
								text={"Button text"}
								size="lg"
								variant="danger"
								fluid={false}
								disabled={true}
								icon={true}
								prefixIcon={<ButtonIcon />}
								suffixIcon={<ButtonIcon />}
							/>
						</div>
						<div className="disabled-btn">
							<h5 className="plt-heading-sm plt-font-color-primary dis-mb-16">Ghost</h5>
							<PrimaryButton
								text={"Button text"}
								size="lg"
								variant="ghost"
								fluid={false}
								disabled={true}
								icon={true}
								prefixIcon={<ButtonIcon />}
								suffixIcon={<ButtonIcon />}
							/>
						</div>
						<div className="disabled-btn">
							<h5 className="plt-heading-sm plt-font-color-primary dis-mb-16">Link</h5>
							<PrimaryButton text={"Button text"} size="lg" variant="link" fluid={false} disabled={true} />
						</div>

						{/* <PrimaryButton
							className={`dss-btn dss-btn-secondary dss-btn-icon dss-btn-lg`}
							text="Button text"
							prefixIcon={<ButtonIcon />}
							suffixIcon={<ButtonIcon />}
						/>

						<PrimaryButton
							className={`dss-btn dss-btn-secondary dss-btn-icon disabled`}
							text="Button text"
							prefixIcon={<ButtonIcon />}
							suffixIcon={<ButtonIcon />}
						/> */}
					</div>
				</div>

				<hr></hr>

				{/* component-Avatar */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Avatar</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Avatar Default</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<AvatarComponent size="xl" varient="default" />
						<AvatarComponent size="xl" state="status" varient="default" />
						<AvatarComponent size="xl" state="presence" varient="default" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Avatar Silhouette</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<AvatarComponent size="xl" content="icon" varient="silhouette" />
						<AvatarComponent size="xl" content="icon" state="status" varient="silhouette" />
						<AvatarComponent size="xl" content="icon" state="presence" varient="silhouette" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Avatar Text</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<AvatarComponent size="xl" content="text" text="AA" varient="textonly" />
						<AvatarComponent size="xl" content="text" text="AA" state="status" varient="textonly" />
						<AvatarComponent size="xl" content="text" text="AA" state="presence" varient="textonly" />
					</div>
				</div>

				<hr></hr>

				{/* component-Avatar Group */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Avatar Group</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Avatar Group Default</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<AvatarComponent
							size="lg"
							avatarData={[
								{ key: 1, type: "image" },
								{ key: 2, type: "image" },
								{ key: 3, type: "image" },
								{ key: 4, type: "image" },
								{ key: 5, type: "image" },
								{ key: 6, type: "image" },
								{ key: 7, type: "image" },
							]}
							avatarGroup
						/>
						<AvatarComponent
							size="md"
							avatarData={[
								{ key: 8, type: "image" },
								{ key: 9, type: "image" },
								{ key: 10, type: "image" },
								{ key: 11, type: "image" },
								{ key: 12, type: "image" },
								{ key: 13, type: "image" },
								{ key: 14, type: "image" },
							]}
							avatarGroup
						/>
						<AvatarComponent
							size="sm"
							avatarData={[
								{ key: 15, type: "image" },
								{ key: 16, type: "image" },
								{ key: 17, type: "image" },
								{ key: 18, type: "image" },
								{ key: 19, type: "image" },
								{ key: 20, type: "image" },
								{ key: 21, type: "image" },
							]}
							avatarGroup
						/>
					</div>
				</div>

				<hr></hr>

				{/* component-Check box */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Check box</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Check box Default</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<CheckboxComponent label="Check box label" size="normal" variant="preview" value={true} />
						<CheckboxComponent label="Check box label" size="small" variant="preview" value={true} />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Check box Outline</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<CheckboxComponent label="Check box label" size="normal" variant="outline" value={true} />
						<CheckboxComponent label="Check box label" size="small" variant="outline" value={true} />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Check box Disabled</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<CheckboxComponent label="Check box label" size="normal" variant="disable" value={true} />
						<CheckboxComponent label="Check box label" size="small" variant="disable" value={true} />
					</div>
				</div>

				<hr></hr>

				{/* component-Inputs */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Text field/Inputs</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">
						Normal - Prefix And Suffix With Leading And Trailing Icon
					</h3>
					<div className="d-flex">
						<Inputs
							// inputId={index}
							// dataItem={inputConfig.dataItem}
							leadingIcon={true}
							tailingIcon={true}
							prefix={true}
							suffix={true}
							tag={false}
							readOnlyState={false}
							disabled={false}
							label="Label"
							// labelClassname={inputConfig.className}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">
						Normal - Prefix And Suffix With Leading And Trailing Icon and Tags
					</h3>
					<div className="d-flex">
						<Inputs
							// inputId={index}
							// dataItem={inputConfig.dataItem}
							leadingIcon={true}
							tailingIcon={true}
							prefix={true}
							suffix={true}
							tag={true}
							readOnlyState={false}
							disabled={false}
							label="Label"
							// labelClassname={inputConfig.className}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Normal - Disabled</h3>
					<div className="d-flex">
						<Inputs
							// inputId={index}
							// dataItem={inputConfig.dataItem}
							leadingIcon={true}
							tailingIcon={true}
							prefix={true}
							suffix={true}
							tag={true}
							readOnlyState={false}
							disabled={true}
							label="Label"
							// labelClassname={inputConfig.className}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Normal - Readonly</h3>
					<div className="d-flex">
						<Inputs
							// inputId={index}
							// dataItem={inputConfig.dataItem}
							leadingIcon={true}
							tailingIcon={true}
							prefix={true}
							suffix={true}
							tag={true}
							readOnlyState={true}
							disabled={false}
							label="Label"
							// labelClassname={inputConfig.className}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Fluid</h3>
					<div className="d-flex">
						<Inputs
							// inputId={index}
							// dataItem={inputConfig.dataItem}
							leadingIcon={true}
							tailingIcon={true}
							prefix={true}
							suffix={true}
							tag={true}
							readOnlyState={true}
							disabled={false}
							fluid={true}
							label="Label"
							// labelClassname={inputConfig.className}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Text field - Dense</h3>
					<div className="d-flex">
						<Inputs
							// inputId={index}
							// dataItem={inputConfig.dataItem}
							leadingIcon={true}
							tailingIcon={true}
							prefix={true}
							suffix={true}
							tag={false}
							readOnlyState={false}
							disabled={false}
							fluid={false}
							label="Label"
							className="dss-input-sm"
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Text field - Dense - Fluid</h3>
					<div className="d-flex dis-mb-32">
						<Inputs
							// inputId={index}
							// dataItem={inputConfig.dataItem}
							leadingIcon={true}
							tailingIcon={true}
							prefix={true}
							suffix={true}
							tag={false}
							readOnlyState={false}
							disabled={false}
							fluid={true}
							label="Label"
							className="dss-input-sm"
						/>
					</div>
				</div>

				<hr></hr>

				{/* component-Textarea */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Textarea</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Normal Default</h3>
					<div className="d-flex">
						<Textarea validation={false} tag={false} disabled={false} readOnlyState={false} label="Label" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Normal Text area - Chips</h3>
					<div className="d-flex">
						<Textarea validation={false} tag={true} disabled={false} readOnlyState={false} label="Label" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Normal Error</h3>
					<div className="d-flex">
						<Textarea validation={true} tag={false} disabled={false} readOnlyState={false} label="Label" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Disabled</h3>
					<div className="d-flex">
						<Textarea validation={false} tag={false} disabled={true} readOnlyState={false} label="Label" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Readonly</h3>
					<div className="d-flex">
						<Textarea validation={false} tag={false} disabled={false} readOnlyState={true} label="Label" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Fluid</h3>
					<div className="d-flex">
						<Textarea
							validation={false}
							tag={false}
							disabled={false}
							readOnlyState={false}
							fluid={true}
							label="Label"
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Fluid Normal Text area - Chips</h3>
					<div className="d-flex">
						<Textarea validation={false} tag={true} disabled={false} readOnlyState={false} fluid={true} label="Label" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Dense - Normal Default</h3>
					<div className="d-flex">
						<Textarea
							validation={false}
							tag={false}
							disabled={false}
							readOnlyState={false}
							fluid={false}
							className="dss-textarea-sm"
							label="Label"
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">
						Text field - Dense - Fluid - Normal Default
					</h3>
					<div className="d-flex dis-mb-32">
						<Textarea
							validation={false}
							tag={false}
							disabled={false}
							readOnlyState={false}
							fluid={true}
							className="dss-textarea-sm"
							label="Label"
						/>
					</div>
				</div>

				<hr></hr>

				{/* component-Toggle */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Toggle</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Default</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<ToggleComponent size="large" hasLabel={false} token="large" disabled={false} />
						<ToggleComponent size="normal" hasLabel={false} token="normal" disabled={false} />
						<ToggleComponent size="normal" hasLabel={false} token="normal" disabled={true} />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Toggle button with label</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<ToggleComponent size="large" hasLabel={true} toggletext="Toggle Text" token="large" disabled={false} />
						<ToggleComponent size="normal" hasLabel={true} toggletext="Toggle Text" token="normal" disabled={false} />
						<ToggleComponent size="normal" hasLabel={true} toggletext="Toggle Text" token="normal" disabled={true} />
					</div>
				</div>

				{/* component-Toggle */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Toggle</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Default</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<ToggleComponent size="large" hasLabel={false} token="large" disabled={false} />
						<ToggleComponent size="normal" hasLabel={false} token="normal" disabled={false} />
						<ToggleComponent size="normal" hasLabel={false} token="normal" disabled={true} />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Toggle button with label</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<ToggleComponent size="large" hasLabel={true} toggletext="Toggle Text" token="large" disabled={false} />
						<ToggleComponent size="normal" hasLabel={true} toggletext="Toggle Text" token="normal" disabled={false} />
						<ToggleComponent size="normal" hasLabel={true} toggletext="Toggle Text" token="normal" disabled={true} />
					</div>
				</div>

				{/* component-Tags/Chip */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Tags/Chip</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Large</h3>
					<div className="d-flex flex-wrap dis-mb-32" style={{ gap: 20 }}>
						<Tag size="large" prefixIcon={false} statusIcon={false} mode="" disable={false} varient="label" />
						<Tag size="large" prefixIcon={false} statusIcon={false} mode="counter" disable={false} varient="label" />
						<Tag size="large" prefixIcon={true} statusIcon={false} mode="" disable={false} varient="label" />
						<Tag size="large" prefixIcon={true} statusIcon={false} mode="counter" disable={false} varient="label" />
						<Tag size="large" prefixIcon={false} statusIcon={true} mode="" disable={false} varient="labelStatusicon" />
						<Tag
							size="large"
							prefixIcon={false}
							statusIcon={true}
							mode="counter"
							disable={false}
							varient="labelStatusicon"
						/>
						<Tag
							size="large"
							prefixIcon={false}
							statusIcon={false}
							mode="closable"
							disable={false}
							varient="labelCloseicon"
						/>
						<Tag
							size="large"
							prefixIcon={true}
							statusIcon={false}
							mode="closable"
							disable={false}
							varient="labelCloseicon"
						/>
						<Tag
							size="large"
							prefixIcon={true}
							statusIcon={false}
							mode="closable"
							disable={true}
							varient="labelCloseicon"
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Normal</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<Tag size="normal" prefixIcon={true} statusIcon={false} mode="counter" disable={false} varient="label" />
						<Tag
							size="normal"
							prefixIcon={false}
							statusIcon={true}
							mode="counter"
							disable={false}
							varient="labelStatusicon"
						/>
						<Tag
							size="normal"
							prefixIcon={true}
							statusIcon={false}
							mode="closable"
							disable={false}
							varient="labelCloseicon"
						/>
						<Tag
							size="normal"
							prefixIcon={true}
							statusIcon={false}
							mode="closable"
							disable={true}
							varient="labelCloseicon"
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Small</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<Tag size="small" prefixIcon={true} statusIcon={false} mode="counter" disable={false} varient="label" />
						<Tag
							size="small"
							prefixIcon={false}
							statusIcon={true}
							mode="counter"
							disable={false}
							varient="labelStatusicon"
						/>
						<Tag
							size="small"
							prefixIcon={true}
							statusIcon={false}
							mode="closable"
							disable={false}
							varient="labelCloseicon"
						/>
						<Tag
							size="small"
							prefixIcon={true}
							statusIcon={false}
							mode="closable"
							disable={true}
							varient="labelCloseicon"
						/>
					</div>
				</div>

				{/* component-Tooltip */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Tooltip</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Short - No Arrow</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<Tooltip position="top" arrow="arrow-none" text="This is tooltip" label="Label">
							Hover this
						</Tooltip>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Short - center arrow</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<Tooltip position="top" arrow="top-center" text="This is tooltip" label="Label">
							Hover this
						</Tooltip>
						<Tooltip position="bottom" text="This is tooltip" label="Label">
							Hover this
						</Tooltip>
						<Tooltip position="left" arrow="lft-center" text="This is tooltip" label="Label">
							Hover this
						</Tooltip>
						<Tooltip position="right" arrow="rgt-center" text="This is tooltip" label="Label">
							Hover this
						</Tooltip>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Truncate</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<Tooltip
							position="top"
							arrow="top-center"
							text="In which case you can set them to truncate, which can still get quite lon"
							label="Label"
						>
							Hover this
						</Tooltip>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Wrap</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<Tooltip
							position="top"
							arrow="top-center"
							text="Tooltips shouldn’t really exceed one line, but sometimes it’s unavoidable"
							label="Label"
							wrap
						>
							Hover this
						</Tooltip>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">small</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<Tooltip position="top" arrow="top-center" size="small" text="This is tooltip" label="Label">
							Hover this
						</Tooltip>
					</div>
				</div>

				<hr></hr>

				{/* component-Radio */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Radio Button</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Normal radio button</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<RadioComponent
							size="md"
							hasLabel={false}
							options={[
								{ value: "option1", label: "Option 1" },
								{ value: "option2", label: "Option 2" },
							]}
							checkedValue="option1"
							variant="default"
							disabled={false}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Normal radio button with label</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<RadioComponent
							size="md"
							hasLabel={true}
							options={[
								{ value: "option1", label: "Option 1" },
								{ value: "option2", label: "Option 2" },
							]}
							checkedValue="option1"
							variant="default"
							disabled={false}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Disabled - Normal radio button with label</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<RadioComponent
							size="md"
							hasLabel={true}
							options={[
								{ value: "option1", label: "Option 1" },
								{ value: "option2", label: "Option 2" },
							]}
							checkedValue="option1"
							variant="default"
							disabled={true}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Small radio button</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<RadioComponent
							size="xs"
							hasLabel={false}
							options={[
								{ value: "option1", label: "Option 1" },
								{ value: "option2", label: "Option 2" },
							]}
							// name={`radioGroup-${index}`}
							checkedValue="option1"
							variant="default"
							disabled={false}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Small radio button with label</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<RadioComponent
							size="xs"
							hasLabel={true}
							options={[
								{ value: "option1", label: "Option 1" },
								{ value: "option2", label: "Option 2" },
							]}
							// name={`radioGroup-${index}`}
							checkedValue="option1"
							variant="default"
							disabled={false}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Outline - radio button with label</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<RadioComponent
							size="md"
							hasLabel={true}
							options={[
								{ value: "option1", label: "Option 1" },
								{ value: "option2", label: "Option 2" },
							]}
							checkedValue="option1"
							variant="outlined"
							disabled={false}
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Outline - small radio button with label</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<RadioComponent
							size="xs"
							hasLabel={true}
							options={[
								{ value: "option1", label: "Option 1" },
								{ value: "option2", label: "Option 2" },
							]}
							checkedValue="option1"
							variant="outlined"
							disabled={false}
						/>
					</div>
				</div>

				{/* component-Toaster */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Toaster</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">
						Normal with actions, leading and closable icon
					</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<ToasterDisplay prefix={true} closable={true} action={true} actionFooter={false} />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">
						Normal with leading icon, wrapped actions and closable icon
					</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<ToasterDisplay prefix={true} closable={true} action={true} actionFooter={true} />
					</div>
				</div>

				<hr></hr>

				{/* component-Alert */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Alert</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Informative</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<AlertDisplay
							prefix={true}
							closable={true}
							action={true}
							actionFooter={true}
							alerttype="alert-informative"
						/>
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Caution</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<AlertDisplay prefix={true} closable={true} action={true} actionFooter={true} alerttype="alert-caution" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Success</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<AlertDisplay prefix={true} closable={true} action={true} actionFooter={true} alerttype="alert-success" />
					</div>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Danger</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<AlertDisplay prefix={true} closable={true} action={true} actionFooter={true} alerttype="alert-danger" />
					</div>
				</div>

				<hr></hr>

				{/* component-Tabs */}
				<div className="component-set">
					<h3 className="plt-heading-xl plt-font-color-primary dis-mb-24 dis-mt-48">Tab</h3>
					<h3 className="plt-heading-md plt-font-color-primary dis-mb-24">Informative</h3>
					<div className="d-flex dis-mb-32" style={{ gap: 20 }}>
						<Tabs
							dataItem={[
								{ key: 1, label: "Tab title 1", content: <div>content 1</div> },
								{ key: 2, label: "Tab title 2", content: <div>content 2</div> },
								{ key: 3, label: "Tab title 3", content: <div>content 3</div> },
							]}
							variant="someVariant"
						/>
					</div>
				</div>
			</div>
		</DesignSystemLayout>
	);
};

export default ComponentlistPage;
