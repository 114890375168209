import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import InputDetail from "../../../../assets/images/png/InputDetail.png";
import InputFluidDetail from "../../../../assets/images/png/InputFluidDetail.png";
import inputDefault from "../../../../assets/images/png/inputDefault.png";
import inputFocused from "../../../../assets/images/png/inputFocused.png";
import inputFilled from "../../../../assets/images/png/inputFilled.png";
import inputDisabled from "../../../../assets/images/png/inputDisabled.png";
import inputReadonly from "../../../../assets/images/png/input-readonly.png";
import inputPlacement from "../../../../assets/images/png/inputPlacement.png";
import inputSpacing from "../../../../assets/images/png/inputSpacing.png";
import GuidelineCardInput from "../../../Common/Guidelines/GuidelineCardInput";
import { InputComponentUsagePrimaryDatas } from "../../../../assets/data/data";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
	{ number: 1, title: "Group Label (optional)", description: "Indicates the purpose or prompt for the text field." },
	{ number: 2, title: "Leading Icon", description: "Icon placed before the input to provide context or guidance." },
	{ number: 3, title: "Prefix", description: "Non-editable text appearing before the user's input." },
	{ number: 4, title: "Value", description: "The text entered or edited by the user." },
	{ number: 5, title: "Chips", description: "Small interactive elements representing tags or selected items." },
	{ number: 6, title: "Suffix", description: "Non-editable text displayed after the user's input." },
	{
		number: 7,
		title: "Trailing Icon",
		description: "Icon placed after the input for actions like clearing or additional options.",
	},
];

const UsageInputComponent = () => {
	const { isLoading } = useGlobalContext();
	const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];
	// const [selectedTab, setSelectedTab] = useState(0);

	const [activeSideTab, setActiveSideTab] = useState(0);

	const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[0]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Anatomy
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							The text input component includes a label and an input field, available in two types: Default, with a
							fixed width for consistency, and Fluid, which expands to fit available space for adaptability across
							layouts.
						</p>
					</div>
					<h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-24">Default</h4>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={InputDetail} alt="userimage" />
					</div>
					<h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mt-32 dis-mb-24">Fluid</h4>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={InputFluidDetail} alt="userimage" />
					</div>

					<ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
						{details.map((detail) => (
							<DetailItem
								key={detail.number}
								number={detail.number}
								title={detail.title}
								description={detail.description}
							/>
						))}
					</ul>

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Types of text field states
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							Text fields are crucial components in user interfaces, allowing users to input and manage information
							seamlessly.
						</p>
					</div>

					{InputComponentUsagePrimaryDatas.map((data, index) => (
						<div key={index} className="dis-mb-40">
							<div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_1}
									</h4>
									<div className="plt-component-card">
										<img src={inputDefault} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_2}
									</h4>
									<div className="plt-component-card">
										<img src={inputFocused} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_3}
									</h4>
									<div className="plt-component-card">
										<img src={inputFilled} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_4}
									</h4>
									<div className="plt-component-card">
										<img src={inputDisabled} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_5}
									</h4>
									<div className="plt-component-card">
										<img src={inputReadonly} alt="userimage" />
									</div>
								</div>
							</div>

							<p className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}>{data.detail}</p>
							{data.detail_list}
						</div>
					))}

					<div className="dis-mt-40 dis-mb-40">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Placement</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								Ensure the text field is left-aligned and properly indented. This alignment promotes consistency and
								enhances readability across the user interface.
							</p>
						</div>

						<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
							<img src={inputPlacement} alt="userimage" />
						</div>

						<div className="plt-content-wrapper dis-mb-24 dis-mt-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Spacing</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								Maintain consistent spacing around the text field to ensure it is visually distinct and easily
								accessible. Adequate spacing improves readability and prevents a cluttered interface.
							</p>
						</div>

						<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
							<img src={inputSpacing} alt="userimage" />
						</div>
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Guidelines</h6>
						<ul className="plt-detail-list dis-mt-12">
							<li className="plt-body-md plt-font-color-primary">
								Position labels to the left of text input fields, aligning them with the top of the fields.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Maintain uniform spacing between labels and input fields to ensure clarity and balance.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Align labels with the top edge of the text input fields for a clean and organized appearance.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Ensure labels are clearly associated with their corresponding input fields for improved usability.
							</li>
						</ul>
					</div>

					<GuidelineCardInput />
				</div>
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default UsageInputComponent;
