import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";
import TokenDropdown from "components/TokenDropdown";

function PaddingTokenModal({
  setPaddingTokenModalOpen,
  isPaddingTokenModalOpen,
  handleApplyPaddingChange,
  buttonState,
  selectedClassName,
  spaceVValue,
  spaceHValue,
  spaces,
  handleHorizontalChange,
  handleVerticalChange,
}) {
  return (
    <CommonModal
      isOpen={isPaddingTokenModalOpen}
      onRequestClose={() => setPaddingTokenModalOpen(false)}
      title='Change token'
      onApply={() => handleApplyPaddingChange()}
      onCancelText='Cancel'
      onApplyText='Apply'
      // variant="modal-tertiary"
    >
      <div className='plt-modal-body-content'>
        <div
          className='plt-preview dis-mb-32'
          style={{ fontFamily: buttonState }}
        >
          <PrimaryButton
            // onClick={() => {}}
            text={"Button text"}
            className={` ${buttonState} ${selectedClassName} d-flex m-auto`}
            disabled={buttonState === "disabled"}
            style={{
              padding: `${spaceVValue.value} ${spaceHValue.value}`,
            }}
          />
        </div>

        <div className='plt-padding-wrapper d-flex  dis-mb-32'>
          <div className='plt-select-dropdown-wrapper d-flex align-items-center'>
            <h6 className='plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular'>
              Horizontal
            </h6>
            <div className='plt-dropdown-wrapper plt-dropdown-wrapper-padding'>
              <TokenDropdown
                name='spaces'
                options={spaces.map((space) => ({
                  value: space.value,
                  label: `${space.value}`,
                }))}
                selectedOption={{
                  value: spaceHValue.value,
                  label: spaceHValue.value,
                }}
                onSelect={(name, option) =>
                  handleHorizontalChange(
                    spaces.find((space) => space?.value === option?.value)
                  )
                }
              />

              {/* <CustomSelect
              options={spaces.map((space) => space.value)}
              selectedOption={
                spaceHValue.label ? spaceHValue.value : spaceHValue
              }
              onSelect={(label) =>
                handleHorizontalChange(
                  spaces.find((option) => option.value === label)
                )
              }
            /> */}
            </div>
          </div>
          <div className='plt-select-dropdown-wrapper d-flex align-items-center'>
            <h6 className='plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular'>
              Vertical
            </h6>
            <div className='plt-dropdown-wrapper plt-dropdown-wrapper-padding'>
              <TokenDropdown
                name='spaces'
                options={spaces.map((space) => ({
                  value: space.value,
                  label: `${space.value}`,
                }))}
                selectedOption={{
                  value: spaceVValue.value,
                  label: spaceVValue.value,
                }}
                onSelect={(event, option) =>
                  handleVerticalChange(
                    spaces.find((space) => space?.value === option?.value)
                  )
                }
              />

              {/* <CustomSelect
              options={spaces.map((space) => space.value)}
              selectedOption={
                spaceVValue.label ? spaceVValue.value : spaceVValue
              }
              onSelect={(label) =>
                handleVerticalChange(
                  spaces.find((option) => option.value === label)
                )
              }
            /> */}
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
}

export default PaddingTokenModal;
