import React, { useState, useMemo } from "react";

import TokenTable from "./TokenTable";
import SelectInput from "components/SelectInput";

const TokenTableComponent = ({ tokenTableData }) => {
  const useComponentData = (data) => {
    const getSizeData = (type, state, size) => {
      return useMemo(() => {
        // Find the type object
        const typeObj = data.find((item) => item.type === type);
        if (!typeObj) return null;

        // Find the state object within type's data
        const stateObj = typeObj.data.find((item) => item.state === state);
        if (!stateObj) return null;

        // Find the size object within state's data
        if (Array.isArray(stateObj.data)) {
          const sizeObj = stateObj.data.find((item) => item.size === size);
          if (sizeObj) return sizeObj.data;
        }

        return null;
      }, [data, type, state, size]);
    };

    return { getSizeData };
  };
  const { getSizeData } = useComponentData(tokenTableData);

  // type
  const types = tokenTableData.map((item) => item.type);
  // const selectedType = { label: types[0], value: types[0] };
  const [currentType, setCurrentType] = useState({
    label: types[0],
    value: types[0],
  });
  const typeToggle = (value) => {
    const selectedValue = value;
    setCurrentType(selectedValue);
  };
  const typeOptions = types.map((value) => ({ label: value, value: value }));

  // state
  const states = tokenTableData[0].data.map((item) => item.state);
  // const selectedState = { label: states[0], value: states[0] };
  const [currentState, setCurrentState] = useState({
    label: states[0],
    value: states[0],
  });
  const stateToggle = (value) => {
    const selectedValue = value;
    setCurrentState(selectedValue);
  };
  const statesOptions = states.map((value) => ({ label: value, value: value }));

  // size
  const sizes = tokenTableData[0].data[0].data.map((item) => item.size);
  // const selectedSize = { label: sizes[0], value: sizes[0] };
  const [currentSize, setCurrentSize] = useState({
    label: sizes[0],
    value: sizes[0],
  });
  const sizeToggle = (value) => {
    const selectedValue = value;
    setCurrentSize(selectedValue);
  };
  const sizeOptions = sizes.map((value) => ({ label: value, value: value }));

  const defaultNormalData = getSizeData(
    currentType.value,
    currentState.value,
    currentSize.value
  );

  return (
    <div className='tab-content-wrapper'>
      <div className='tab-content-container'>
        <div className='tokens-tab-filter-header dis-column-gap-16 dis-mb-24'>
          {types && types.length > 1 && (
            <div className='tokens-tab-filter-item'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-medium dis-mb-4'>
                Select type
              </h6>
              <SelectInput
                className='custom-dropdown'
                options={typeOptions}
                defaultValue={currentType}
                onSelect={typeToggle}
              />
            </div>
          )}

          {states && states.length > 1 && (
            <div className='tokens-tab-filter-item'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-medium dis-mb-4'>
                Select states
              </h6>
              <SelectInput
                className='custom-dropdown'
                options={statesOptions}
                defaultValue={currentState}
                onSelect={stateToggle}
              />
            </div>
          )}

          {sizes && sizes.length > 1 && (
            <div className='tokens-tab-filter-item'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-medium dis-mb-4'>
                Select size
              </h6>
              <SelectInput
                className='custom-dropdown'
                options={sizeOptions}
                defaultValue={currentSize}
                onSelect={sizeToggle}
              />
            </div>
          )}
        </div>

        <div className='dis-mb-40'>
          <div className='plt-content-wrapper dis-mb-16'>
            <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
              <h6 className='plt-contents-head plt-heading-md plt-font-color-primary font-weight-700'>
                {currentType.label}
              </h6>
            </div>
          </div>
          <TokenTable tokenTableData={defaultNormalData} />
        </div>
      </div>
    </div>
  );
};

export default TokenTableComponent;
