import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import TabDetail from "../../../../assets/images/png/TabDetail.png";
import TabFillDetail from "../../../../assets/images/png/TabFillDetail.png";
import tabDefault from "../../../../assets/images/png/tabDefault.png";
import TabHovered from "../../../../assets/images/png/TabHovered.png";
import TabDisabled from "../../../../assets/images/png/TabDisabled.png";
import TabPillDefault from "../../../../assets/images/png/TabPillDefault.png";
import TabPillHovered from "../../../../assets/images/png/TabPillHovered.png";
import TabPillDisabled from "../../../../assets/images/png/TabPillDisabled.png";
import tabNormal from "../../../../assets/images/png/tabNormal.png";
import tabNormalLeadingIcon from "../../../../assets/images/png/tabNormalLeadingIcon.png";
import tabNormalTailingIcon from "../../../../assets/images/png/tabNormalTailingIcon.png";
import tabNormalBadge from "../../../../assets/images/png/tabNormalBadge.png";
import tabNormalLeadingIconBadge from "../../../../assets/images/png/tabNormalLeadingIconBadge.png";
import tabPill from "../../../../assets/images/png/tabPill.png";
import tabPillLeadingIcon from "../../../../assets/images/png/tabPillLeadingIcon.png";
import tabPillTailingIcon from "../../../../assets/images/png/tabPillTailingIcon.png";
import tabPillBadge from "../../../../assets/images/png/tabPillBadge.png";
import tabPillLeadingIconBadge from "../../../../assets/images/png/tabPillLeadingIconBadge.png";
import tabPlacement from "../../../../assets/images/png/tabPlacement.png";
import tabSpacing from "../../../../assets/images/png/tabSpacing.png";
import tabPillSpacing from "../../../../assets/images/png/tabPillSpacing.png";
import GuidelineCardTab from "../../../Common/Guidelines/GuidelineCardTab";
import {
	TabsUsagePillDatas,
	TabsUsagePrimaryDatas,
	TabsUsageSecondaryDatas,
	TabsUsageSecondaryPillDatas,
} from "../../../../assets/data/data";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
	{ number: 1, title: "Icon", description: "Optional visual representation to enhance tab identification." },
	{ number: 2, title: "Label", description: "Clear, concise text that describes the tab’s content." },
	{ number: 3, title: "Count", description: "Displays the number of items or notifications within a tab." },
	{ number: 4, title: "Selection Indicator", description: "A visual cue that highlights the active tab." },
	{ number: 5, title: "Inactive Tabs", description: "Tabs that are not currently selected or in use." },
	{ number: 6, title: "Divider", description: "A visual element separating tabs or content for better structure." },
];

const UsageTabComponent = () => {
	const { isLoading } = useGlobalContext();
	const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];
	// const [selectedTab, setSelectedTab] = useState(0);

	const [activeSideTab, setActiveSideTab] = useState(0);

	const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[0]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Anatomy
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							A tab component lets users switch between related categories without navigating to different pages. It
							organizes content-heavy sections into manageable categories or closely related, frequently accessed pages.
						</p>
					</div>
					<h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-24">Default</h4>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={TabDetail} alt="userimage" />
					</div>
					<h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mt-32 dis-mb-24">Fill</h4>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={TabFillDetail} alt="userimage" />
					</div>

					<ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
						{details.map((detail) => (
							<DetailItem
								key={detail.number}
								number={detail.number}
								title={detail.title}
								description={detail.description}
							/>
						))}
					</ul>

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Tab styles
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							The tooltip container can be aligned to start, center, or end to prevent it from bleeding off the page or
							covering important information. Tooltip content can be styled to truncate, wrap, or fit within a short
							container, depending on the design needs.
						</p>
					</div>

					{TabsUsagePrimaryDatas.map((data, index) => (
						<div key={index} className="dis-mb-40">
							<div className="plt-content-wrapper dis-mb-24">
								<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									{data.description}
								</p>
							</div>
							<div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_1}
									</h4>
									<div className="plt-component-card">
										<img src={tabNormal} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_2}
									</h4>
									<div className="plt-component-card">
										<img src={tabNormalLeadingIcon} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_3}
									</h4>
									<div className="plt-component-card">
										<img src={tabNormalTailingIcon} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_4}
									</h4>
									<div className="plt-component-card">
										<img src={tabNormalBadge} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_5}
									</h4>
									<div className="plt-component-card">
										<img src={tabNormalLeadingIconBadge} alt="userimage" />
									</div>
								</div>
							</div>
						</div>
					))}

					{TabsUsagePillDatas.map((data, index) => (
						<div key={index} className="dis-mb-40">
							<div className="plt-content-wrapper dis-mb-24">
								<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									{data.description}
								</p>
							</div>
							<div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_1}
									</h4>
									<div className="plt-component-card">
										<img src={tabPill} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_2}
									</h4>
									<div className="plt-component-card">
										<img src={tabPillLeadingIcon} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_3}
									</h4>
									<div className="plt-component-card">
										<img src={tabPillTailingIcon} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_4}
									</h4>
									<div className="plt-component-card">
										<img src={tabPillBadge} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_5}
									</h4>
									<div className="plt-component-card">
										<img src={tabPillLeadingIconBadge} alt="userimage" />
									</div>
								</div>
							</div>
						</div>
					))}

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Types of tab states
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							Tabs are essential UI components, enabling users to navigate seamlessly.
						</p>
					</div>

					{TabsUsageSecondaryDatas.map((data, index) => (
						<div key={index} className="dis-mb-40">
							<div className="plt-content-wrapper dis-mb-24">
								<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									{data.description}
								</p>
							</div>
							<div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_1}
									</h4>
									<div className="plt-component-card">
										<img src={tabDefault} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_2}
									</h4>
									<div className="plt-component-card">
										<img src={TabHovered} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_3}
									</h4>
									<div className="plt-component-card">
										<img src={TabDisabled} alt="userimage" />
									</div>
								</div>
							</div>
						</div>
					))}

					{TabsUsageSecondaryPillDatas.map((data, index) => (
						<div key={index} className="dis-mb-40">
							<div className="plt-content-wrapper dis-mb-24">
								<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
								<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
									{data.description}
								</p>
							</div>
							<div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_1}
									</h4>
									<div className="plt-component-card">
										<img src={TabPillDefault} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_2}
									</h4>
									<div className="plt-component-card">
										<img src={TabPillHovered} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_3}
									</h4>
									<div className="plt-component-card">
										<img src={TabPillDisabled} alt="userimage" />
									</div>
								</div>
							</div>

							{data.detail_list}
						</div>
					))}

					<div className="dis-mt-40 dis-mb-40">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Placement</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								Tabs group related information into categories, reducing cognitive load. They organize content like
								forms, settings, and dashboards, keeping users within their workflow.
							</p>
						</div>

						<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
							<img src={tabPlacement} alt="userimage" />
						</div>

						<div className="plt-content-wrapper dis-mb-24 dis-mt-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Spacing</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								Maintain consistent spacing around the text field to ensure it is visually distinct and easily
								accessible. Adequate spacing improves readability and prevents a cluttered interface.
							</p>
						</div>

						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Default</h6>
						<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
							<img src={tabSpacing} alt="userimage" />
						</div>

						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8 dis-mt-24">Pill</h6>
						<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
							<img src={tabPillSpacing} alt="userimage" />
						</div>
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Guidelines</h6>
						<ul className="plt-detail-list dis-mt-12">
							<li className="plt-body-md plt-font-color-primary">
								Keep the number of tabs manageable (3-7) for clarity.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Ensure tab labels are concise and descriptive for easy navigation.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Visually emphasize the selected tab to maintain user orientation.
							</li>
						</ul>
					</div>

					<GuidelineCardTab />
				</div>
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default UsageTabComponent;
