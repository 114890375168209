import React from "react";

import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";
import Dropdown from "components/Dropdown";
import { EditIcon } from "components/Icons";

const ButtonEditModal = ({
  isModalVisible,
  setModalVisibility,
  setButtonState,
  buttonStates,
  buttonState,
  handleStateChange,
  selectedToken,
  componentInfo,
  selectedTokenData,
  setTokenType,
  setTypoTokenModalOpen,
  setRadiusTokenModalOpen,
  setColorTokenModalOpen,
  setPaddingTokenModalOpen,
  selectedShadow,
  setShadowTokenModalOpen,
  setTextTokenModalOpen,
  selectedClassName,
  buttonSize,
  spaceHValue,
  spaceVValue,
}) => {
  return (
    <CommonModal
      isOpen={isModalVisible}
      onRequestClose={() => {
        setModalVisibility(false);
        setButtonState(buttonStates[0]);
      }}
      title='Tokens'
      onApply={() => setModalVisibility(false)}
      onCancelText='Cancel'
      onApplyText='Apply'
      variant='modal-tertiary'
    >
      <div className='plt-modal-body-content'>
        <div className='plt-dropdown-secondary'>
          <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
            <div className='plt-dropdown-wrapper plt-dropdown-wrapper-common'>
              <Dropdown
                name='buttonState'
                options={buttonStates}
                selectedOption={buttonState}
                onSelect={handleStateChange}
              />
            </div>
          </div>
        </div>
        <div className='plt-preview dis-mb-32'>
          <PrimaryButton
            style={{
              fontFamily: selectedToken?.data?.fontFamily?.value,
              fontSize: selectedToken?.data?.fontSize?.value,
              lineHeight: selectedToken?.data?.lineHeight?.value,
              fontWeight: selectedToken?.data?.fontWeight?.value,
            }}
            className={`${buttonState?.value.toLocaleLowerCase()} ${selectedClassName} d-flex m-auto `}
            disabled={
              buttonState?.value.toLowerCase() === "disabled" ||
              componentInfo?.disabled
            }
            size={componentInfo?.size}
            // prefixIcon={data.prefixIcon}
            // suffixIcon={data.suffixIcon}
            variant={componentInfo?.variant}
            // icon={data.icon}
            // iconOnly={data.iconOnly}
            // text={data.text}
          />
        </div>
        {selectedTokenData &&
          selectedTokenData[buttonState?.value.toLocaleLowerCase()] && (
            <>
              {selectedTokenData[buttonState?.value.toLowerCase()].bg
                ?.tokenName && (
                <div className='plt-token-details dis-mb-32'>
                  <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                    Background color
                  </h6>
                  <div className='plt-token-value-item d-flex'>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                      {selectedTokenData[buttonState?.value.toLowerCase()].bg
                        .tokenName || "N/A"}
                    </p>
                    <span
                      className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                      onClick={() => {
                        setTokenType("bg");
                        setColorTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              )}
              {selectedTokenData[buttonState?.value.toLowerCase()]?.border
                ?.tokenName && (
                <div className='plt-token-details dis-mb-32'>
                  <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                    Border color
                  </h6>
                  <div className='plt-token-value-item d-flex'>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                      {selectedTokenData[buttonState?.value.toLowerCase()]
                        ?.border.tokenName || "N/A"}
                    </p>
                    <span
                      className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                      onClick={() => {
                        setTokenType("border");
                        setColorTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              )}
              {selectedTokenData[buttonState?.value.toLowerCase()]
                ?.typography?.[buttonSize]?.tokenName && (
                <div className='plt-token-details dis-mb-32'>
                  <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                    Typography
                  </h6>
                  <div className='plt-token-value-item d-flex'>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                      {selectedTokenData[buttonState?.value.toLowerCase()]
                        ?.typography?.[buttonSize]?.tokenName || "N/A"}
                    </p>
                    <span
                      className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                      onClick={() => {
                        setTokenType("text");
                        setTypoTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              )}
              {selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[
                buttonSize
              ]?.className && (
                <div className='plt-token-details dis-mb-32'>
                  <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                    Radius
                  </h6>
                  <div className='plt-token-value-item d-flex'>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                      {selectedTokenData[buttonState?.value.toLowerCase()]
                        ?.radius?.[buttonSize]?.className || "N/A"}
                    </p>
                    <span
                      className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                      onClick={() => setRadiusTokenModalOpen(true)}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              )}
              {selectedTokenData[buttonState?.value.toLowerCase()]?.text
                ?.tokenName && (
                <div className='plt-token-details dis-mb-32'>
                  <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                    Text color
                  </h6>
                  <div className='plt-token-value-item d-flex'>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                      {selectedTokenData[buttonState?.value.toLowerCase()]?.text
                        .tokenName || "N/A"}
                    </p>
                    <span
                      className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                      onClick={() => {
                        setTokenType("text");
                        setColorTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              )}
              {selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
                buttonSize
              ]?.tokenName && (
                <div className='plt-token-details dis-mb-24'>
                  <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                    Padding
                  </h6>
                  <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mb-4'>
                    {selectedTokenData[buttonState?.value.toLowerCase()]
                      ?.spacing?.[buttonSize]?.tokenName || "N/A"}
                  </p>
                  <div className='plt-token-value-item d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                      <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                        H:
                      </span>
                      <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                        {spaceHValue?.label || "N/A"}
                      </p>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                        V:
                      </span>
                      <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                        {spaceVValue?.label || "N/A"}
                      </p>
                    </div>
                    <span
                      className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                      onClick={() => {
                        setTokenType("text");
                        setPaddingTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              )}
              {selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[
                buttonSize
              ]?.tokenName &&
                selectedShadow && (
                  <div className='plt-token-details dis-mb-24'>
                    <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                      Box shadow
                    </h6>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mb-4'>
                      {selectedTokenData[buttonState?.value.toLowerCase()]
                        ?.elevation?.[buttonSize]?.tokenName || "N/A"}
                    </p>
                    <div className='plt-token-value-item d-flex align-items-center'>
                      <div className='d-flex align-items-center'>
                        <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                          X:
                        </span>
                        <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                          {selectedShadow?.value?.x}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                          Y:
                        </span>
                        <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                          {selectedShadow?.value?.y}
                        </p>
                      </div>
                    </div>
                    <div className='plt-token-value-item d-flex align-items-center dis-mt-12'>
                      <div className='d-flex align-items-center'>
                        <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                          Blur:
                        </span>
                        <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                          {selectedShadow?.value?.blur}
                        </p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <span className='plt-body-compact-sm plt-font-color-primary dis-mr-4'>
                          Spread:
                        </span>
                        <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                          {selectedShadow?.value?.spread}
                        </p>
                      </div>
                      <span
                        className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                        onClick={() => {
                          setTokenType("text");
                          setShadowTokenModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </span>
                    </div>
                  </div>
                )}
              {selectedTokenData["default"]?.underline?.[buttonSize]
                ?.tokenName && (
                <div className='plt-token-details dis-mb-24'>
                  <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
                    Text decoration
                  </h6>
                  <div className='plt-token-value-item d-flex'>
                    <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                      textdecoration-underline
                    </p>
                    <span
                      className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'
                      onClick={() => {
                        setTokenType("text");
                        setTextTokenModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
      </div>
    </CommonModal>
  );
};

export default ButtonEditModal;
