import FontCommonModal from "components/Common/CommonModal/FontCommonModal";
import Dropdown from "components/Dropdown";
import { useGlobalContext } from "context/GlobalContext";
import { loadGoogleFont } from "helpers/font";
import { useEffect, useState } from "react";
import "./EditHeadingModal.scss";

const EditHeadingModal = ({
  isOpen,
  onRequestClose,
  onUpload,
  onApply,
  initialFont,
  title,
  fonts,
}) => {
  const { designSystemMeta } = useGlobalContext();
  const [selectedFont, setSelectedFont] = useState({
    value: initialFont,
    label: initialFont,
  });

  useEffect(() => {
    setSelectedFont({ value: initialFont, label: initialFont });
  }, [initialFont]);

  const [fontDropdown, setFontDropdown] = useState([]);

  const apiKey = process.env.REACT_APP_GOOGLE_FONT_API_KEY;

  useEffect(() => {
    //Manual fonts
    const manualFontsArray = fonts
      ?.filter((font) => font?.type === "manual")
      .map((font) => font?.family);
    const manualFonts = [...new Set(manualFontsArray)];
    const manualDropdown = manualFonts?.map((font) => {
      return { value: font, label: font };
    });
    setFontDropdown(manualDropdown);
    // Google fonts
    fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`)
      .then((response) => response.json())
      .then((data) => {
        setFontDropdown((fontDropdown) => [
          ...fontDropdown,
          ...data.items.map((font) => {
            return { value: font.family, label: font.family };
          }),
        ]);
      })
      .catch((error) => console.error("Error fetching Google Fonts:", error));
  }, [fonts]);

  const handleFontChange = (name, selectedFontName) => {
    setSelectedFont(selectedFontName);
    loadGoogleFont(selectedFontName?.value);
  };

  const handleApply = () => {
    onApply(selectedFont?.value);
    onRequestClose();
  };

  return (
    <FontCommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      onApply={handleApply}
      onUpload={onUpload}
      onCancelText='Cancel'
      onApplyText='Apply'
      // bodyClassName="overflow-unset"
    >
      <div className='plt-modal-body-content'>
        <div className='plt-dropdown-secondary'>
          <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
            <div className='plt-dropdown-wrapper plt-dropdown-wrapper-common plt-dropdown-wrapper-font'>
              {fontDropdown && selectedFont && (
                <Dropdown
                  name='font'
                  options={fontDropdown}
                  // options={data?.fontDropdown}
                  // selectedOption={selectedFont}
                  selectedOption={{
                    value: initialFont,
                    label: initialFont,
                  }}
                  // defaultValue={selectedFont}
                  onSelect={handleFontChange}
                />
              )}
            </div>
          </div>
        </div>
        <div className='plt-preview'>
          <div
            className='plt-font-preview plt-heading-xl'
            style={{ fontFamily: selectedFont?.value }}
          >
            Your Font will look like this!
          </div>
        </div>
      </div>
    </FontCommonModal>
  );
};

export default EditHeadingModal;
