import React, { useEffect, useState } from "react";
import EditButton from "components/Buttons/EditButton";
import DraftEditor from "components/Common/DraftEditor/DraftEditor";
import PrimaryButton from "components/Common/PrimaryButton";
import Tooltip from "components/Tooltip";
import { useGlobalContext } from "context/GlobalContext";
import { createPage, updatePage } from "api";
import { useParams } from "react-router-dom";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";

const UsageEditor = ({ pageType }) => {
	const [page, setPage] = useState({});

	const { id } = useParams();
	const [isEditing, setIsEditing] = useState(false);

	const [editorState, setEditorState] = useState(page?.content || "");
	const { designSystemMeta, setDesignSystemMeta } = useGlobalContext();

	useEffect(() => {
		setPage(designSystemMeta?.pages?.find((page) => page?.type === pageType));
	}, []);

	useEffect(() => {
		if (page?.content) {
			setEditorState(page.content);
		}
	}, [page, location, pageType]);

	const saveChanges = async () => {
		let pageExist = designSystemMeta?.pages?.find((page) => page?.type === pageType);

		const contentHtml = editorState;

		const dataToSubmit = {
			id: page?.id,
			name: "Tab Usage",
			content: contentHtml,
			type: pageType,
			design_system_id: id,
		};

		if (!pageExist) {
			//Create page
			try {
				const data = await createPage(dataToSubmit);
				setIsEditing(false);
				setPage(data?.data);
				setDesignSystemMeta((prevState) => ({
					...prevState,
					pages: [...prevState.pages, data?.data],
				}));
			} catch (error) {
				console.error("Failed to create page:", error.message);
			}
		} else {
			//Update page
			try {
				await updatePage(dataToSubmit, page?.id);
				setIsEditing(false);
				setPage({ ...page, content: contentHtml });
				updateState(contentHtml);
			} catch (error) {
				console.error("Failed to update page:", error.message);
			}
		}
	};

	const updateState = (contentHtml) => {
		const newDesignSystemMeta = { ...designSystemMeta };
		const newPages = newDesignSystemMeta?.pages?.map((p) => {
			if (p.id !== page?.id) {
				return p;
			} else {
				return {
					...p,
					content: contentHtml,
				};
			}
		});
		newDesignSystemMeta.pages = newPages;
		setDesignSystemMeta(newDesignSystemMeta);
	};

	return (
		// <div className="d-flex justify-content-between w-full">
		//   <div className="tab-content-container">
		<div className="tab-content-body w-full">
			<div className="plt-contents-container">
				{isEditing ? (
					<div>
						<DraftEditor editorState={editorState} setEditorState={setEditorState} />
						<div className="dis-mt-20 dis-mb-40">
							<PrimaryButton
								onClick={() => setIsEditing(false)}
								text="Cancel"
								// className="dss-btn dss-btn-secondary dss-btn-lg dis-mr-20"
								className="dis-mr-20"
								variant="secondary"
								size="lg"
							/>
							<PrimaryButton
								onClick={saveChanges}
								text="Save"
								// className="dss-btn dss-btn-primary dss-btn-lg"
								variant="primary"
								size="lg"
							/>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-between">
						<div
							className="content-display"
							dangerouslySetInnerHTML={{
								__html: editorState,
							}}
						/>
						<AuthAction>
							<Permission designSystem={designSystemMeta}>
								<span className="dis-ml-24" onClick={() => setIsEditing(true)}>
									<Tooltip position="top" text="Edit content">
										<EditButton />
									</Tooltip>
								</span>
							</Permission>
						</AuthAction>
					</div>
				)}
			</div>
		</div>
		//  </div>
	);
};

export default UsageEditor;
