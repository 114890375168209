import { useState } from "react";
import "./RadioComponent.scss";

const RadioComponent = ({
  options,
  name,
  checkedValue,
  size,
  hasLabel = false,
  variant,
  disabled,
  className
}) => {
  const [checkedItem, setCheckedItem] = useState(checkedValue);

  const handleCheckboxChange = (value) => {
    setCheckedItem(value);
  };
  return (
    <div className={`dss-custom-radio-group dss-custom-radio-group-${size} ${className ? className : ''}`}>
      {options.map((option, index) => (
        <label
          key={index}
          className={`dss-radio-wrapper ${
            checkedItem === option.value ? "checked" : ""
          } ${hasLabel ? "with-label" : ""} dss-radio-${variant} ${
            disabled ? "disabled" : ""
          }`}
        >
          <input
            className={`dss-radio-input`}
            type="radio"
            name={name}
            value={option.value}
            checked={checkedItem === option.value}
            onChange={() => {
              handleCheckboxChange(option.value);
            }}
            disabled={disabled}
          />
          {hasLabel && (
            <span
              className={`dss-radio-label dss-radio-label-${variant}`}
            >
              {option.label}
            </span>
          )}
        </label>
      ))}
    </div>
  );
};

export default RadioComponent;
