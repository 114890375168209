import React, { useState } from "react";
import "./Table.scss";


const TableRow = ({ rowData }) => {
  return (
    <tr>
      <td className="dss-table-cell">{rowData.value}</td>
      <td className="dss-table-cell">
        <p className="">
          {rowData.tokenName}
        </p>
      </td>
      <td className="dss-table-cell">{rowData.sizeValue}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="dss-scale-table-container">
      <table className="dss-scale-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="dss-scale-table-header"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <TableRow key={index} rowData={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default Table;
