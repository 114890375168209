import React from "react";
import "./AlertComponent.scss";
import { ButtonIcon } from "components/Icons";
import { CloseIcon } from "components/Icons";
import PrimaryButton from "../PrimaryButton";

const AlertDisplay = ({
	prefix,
	closable,
	// action,
	actionFooter,
	onClose,
	alerttype,
	title,
	description = "This is an info alert. It also has longer text to see what these alerts can look like when broken into multiple lines. This one will definitely break into multiple lines in most standard screen resolutions.",
}) => {
	return (
	<div className={`dss-alert dss-${alerttype} w-100`}>
		<div className="dss-alert-contents-wrapper">
				<div className="dss-alert-contents-info">
					<div className="dss-alert-icon">{prefix && <ButtonIcon color="#171717" />}</div>
					<div className={`dss-alert-msg-container ${actionFooter ? "dss-alert-action-footer" : ""}`}>
						<h6 className="dss-alert-msg">{title}</h6>
						<p className="dss-alert-desc">{description}</p>
					</div>
				</div>
				<div className="d-flex dss-alert-btn-group">
					{/* <button className="dss-btn dss-btn-primary dss-btn-sm " type="button">
						Button
					</button> */}
							<PrimaryButton
									text='button'
									variant= "primary"
									size= "sm"
								/>
					{/* <button className="dss-btn dss-btn-outlined dss-btn-sm " type="button">
						Button
					</button> */}
						<PrimaryButton
									text='button'
									variant= "outlined"
									size= "sm"
								/>
				</div>
			</div>
		{closable && (
			<span onClick={onClose} className="dss-alert-close-icon">
				<CloseIcon />
			</span>
		)}
	</div>
);
};


export default AlertDisplay;
