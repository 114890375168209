import React, { useState } from "react";
import "./PaymentMethod.scss";
import CommonModal from "components/Common/CommonModal";
import { toast } from "react-toastify";
import PrimaryButton from "components/Common/PrimaryButton";

const PaymentMethodModal = ({ isOpen, onRequestClose, title }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod((prevMethod) => (prevMethod === method ? '' : method));
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      variant="payment-method-modal"
      bodyClassName='payment-modal'
    >
      <div className='plt-select-dropdown-wrapper dis-ml-24 dis-mr-24 dis-pb-16'>
        <div className='payment-content'>
          {/* Card Payment */}
          <div className='payment-block payment-card-content'>
            <div
              className='plt-custom-radio-group plt-custom-radio-group-vertical'
              onClick={() => handlePaymentMethodChange('card')}
            >
              <div className='plt-radio-wrapper plt-custom-radio-group-md'>
                <input
                  className='plt-radio'
                  type='radio'
                  name='paymentOption'
                  value='card'
                  checked={selectedPaymentMethod === 'card'}
                  readOnly
                />
                <label className='plt-radio-label font-weight-600'>
                  Card
                </label>
              </div>
            </div>
            {selectedPaymentMethod === 'card' && (
              <div className='radio-dropdown dis-pl-24 dis-pr-24 active'>
                <div className='plt-select-dropdown-wrapper dis-mb-12'>
                  <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4'>
                    Card number
                  </h6>
                  <div className='modal-value-block w-100'>
                    <input
                      className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                      type='text'
                      placeholder='XXXX XXXX 0000'
                    />
                  </div>
                </div>
                <div className="card-detail d-flex align-items-center justify-content-between dis-mb-12">
                  <div className="plt-select-dropdown-wrapper">
                    <h6 className="plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4">Valid till</h6>
                    <div className="modal-value-block w-100">
                      <input
                        className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                        type='text'
                        placeholder='MM/YYYY'
                      />
                    </div>
                  </div>
                  <div className="plt-select-dropdown-wrapper">
                    <h6 className="plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4">CVV</h6>
                    <div className="modal-value-block w-100">
                      <input
                        className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                        type='text'
                        placeholder='***'
                      />
                    </div>
                  </div>
                </div>
                <div className='plt-select-dropdown-wrapper dis-mb-12'>
                  <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4'>
                    Name on card
                  </h6>
                  <div className='modal-value-block w-100'>
                    <input
                      className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                      type='text'
                      placeholder='Christopher Nolan'
                    />
                  </div>
                </div>
                <div className="plt-modal-footer dis-p-0 dis-mb-16">
                  <PrimaryButton
                    // className={`plt-btn plt-btn-secondary plt-ui-text-md-medium `}
                    text="Cancel"
                    onClick={onRequestClose}
                    variant= "secondary"
									  size= "md"	
                  />
                  <PrimaryButton
                    // className={`plt-btn plt-btn-primary plt-ui-text-md-medium `}
                    text="Proceed"
                    variant= "primary"
									  size= "md"	
                  />
                </div>
              </div>
            )}
          </div>
          {/* UPI/VPA Payment */}
          <div className='payment-block payment-card-content'>
            <div
              className='plt-custom-radio-group plt-custom-radio-group-vertical'
              onClick={() => handlePaymentMethodChange('upi')}
            >
              <div className='plt-radio-wrapper plt-custom-radio-group-md'>
                <input
                  className='plt-radio'
                  type='radio'
                  name='paymentOption'
                  value='upi'
                  checked={selectedPaymentMethod === 'upi'}
                  readOnly
                />
                <label className='plt-radio-label font-weight-600'>
                  UPI/VPA
                </label>
              </div>
            </div>
            {selectedPaymentMethod === 'upi' && (
              <div className='radio-dropdown dis-pl-24 dis-pr-24 active'>
                <div className='plt-select-dropdown-wrapper dis-mb-12'>
                  <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4'>
                    UPI ID
                  </h6>
                  <div className='modal-value-block w-100'>
                    <input
                      className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                      type='text'
                      placeholder='christopher-7@oksbi'
                    />
                  </div>
                </div>
                <div className="plt-modal-footer dis-p-0 dis-mb-16">
                  <PrimaryButton
                    // className={`plt-btn plt-btn-secondary plt-ui-text-md-medium `}
                    text="Cancel"
                    onClick={onRequestClose}
                    variant= "secondary"
									  size= "md"	
                  />
                  <PrimaryButton
                    // className={`plt-btn plt-btn-primary plt-ui-text-md-medium `}
                    text="Proceed"
                    variant= "primary"
									  size= "md"	
                  />
                </div>
              </div>
            )}
          </div>
          {/* Net Banking Payment */}
          <div className='payment-block payment-card-content'>
            <div
              className='plt-custom-radio-group plt-custom-radio-group-vertical'
              onClick={() => handlePaymentMethodChange('netbanking')}
            >
              <div className='plt-radio-wrapper plt-custom-radio-group-md'>
                <input
                  className='plt-radio'
                  type='radio'
                  name='paymentOption'
                  value='netbanking'
                  checked={selectedPaymentMethod === 'netbanking'}
                  readOnly
                />
                <label className='plt-radio-label font-weight-600'>
                  Net banking
                </label>
              </div>
            </div>
            {selectedPaymentMethod === 'netbanking' && (
              <div className='radio-dropdown dis-pl-24 dis-pr-24 active'>
                <div className='plt-select-dropdown-wrapper dis-mb-12'>
                  <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4'>
                    Netbanking
                  </h6>
                  <div className='modal-value-block w-100'>
                    <input
                      className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                      type='text'
                      placeholder='Search'
                    />
                  </div>
                </div>
                <div className="plt-modal-footer dis-p-0 dis-mb-16">
                  <PrimaryButton
                    // className={`plt-btn plt-btn-secondary plt-ui-text-md-medium `}
                    text="Cancel"
                    onClick={onRequestClose}
                    variant= "secondary"
									  size= "md"	
                  />
                  <PrimaryButton
                    // className={`plt-btn plt-btn-primary plt-ui-text-md-medium `}
                    text="Proceed"
                    variant= "primary"
									size= "md"	
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default PaymentMethodModal;
