import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchVariables } from "../api";
import { useGlobalContext } from "../context/GlobalContext";
import { parseCssVariables } from "../helpers";
import { getDomainAdminId } from "./storageUtils";

const useApplyDesignSystemStylesheet = () => {
  const { setLoading, setResetTokens, resetTokens, pusherIsLoading, theme } =
    useGlobalContext();
  const params = useParams();
  const location = useLocation();
  const [cssVariables, setCssVariables] = useState({});
  const [stylesheetUrls, setStylesheetUrls] = useState([]);
  // const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  // Ref to store the current stylesheets
  const appendedStylesheets = useRef([]);

  useLayoutEffect(() => {
    setLoading(true);
    const adminId = getDomainAdminId();
    const designSystemId = params.id;

    const getVariables = async () => {
      try {
        const data = await fetchVariables(adminId, designSystemId, theme);
        const variables = parseCssVariables(data.variables);
        setCssVariables(variables);

        const stylesheets = data.data.map(
          (path) => `${process.env.REACT_APP_SHEET_URL}${path}`
        );
        setStylesheetUrls(stylesheets);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      } finally {
        setLoading(false);
        setResetTokens(false);
      }
    };

    if (!pusherIsLoading) {
      getVariables();
    }
  }, [location.pathname, resetTokens, pusherIsLoading, theme]);

  useLayoutEffect(() => {
    const root = document.documentElement;
    root.setAttribute("data-theme", theme);
    for (const [name, value] of Object.entries(cssVariables)) {
      root.style.setProperty(`--${name}`, value);
    }
  }, [cssVariables, theme]);

  useEffect(() => {
    const currentStylesheets = appendedStylesheets.current;
    const links = document.head.querySelectorAll('link[rel="stylesheet"]');

    // Remove any stale stylesheets
    links.forEach((link) => {
      if (
        !stylesheetUrls.includes(link.href) &&
        currentStylesheets.includes(link.href)
      ) {
        document.head.removeChild(link);
        currentStylesheets.splice(currentStylesheets.indexOf(link.href), 1);
      }
    });

    // Add new stylesheets
    stylesheetUrls.forEach((url) => {
      if (!currentStylesheets.includes(url)) {
        const link = document.createElement("link");
        link.href = url;
        link.type = "text/css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
        currentStylesheets.push(url);
      }
    });

    appendedStylesheets.current = currentStylesheets;
  }, [stylesheetUrls]);

  // useEffect(() => {
  //   localStorage.setItem("theme", theme);
  // }, [theme]);

  return [theme];
};

export default useApplyDesignSystemStylesheet;
