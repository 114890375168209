import { CardSettingsIcon } from "components/Icons";
import { useGlobalContext } from "context/GlobalContext";
import React, { useEffect, useRef, useState } from "react";

const PlanTableRow = ({ row, slno, setPlans }) => {
  const dropdownRef = useRef(null);
  const { setLoading } = useGlobalContext();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <tr>
      <td>
        <div className='plt-sl-number d-flex align-items-center'>
          <p className='plt-body-md plt-font-color-secondary'>
            {slno}
          </p>
          <div className='plt-table-hover-icon dis-ml-8'></div>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center plt-body-md'>
          {/* <img
            src={userAvatar}
            alt='profile'
            className='user-image dis-mr-12'
          /> */}
          {row?.name}
        </div>
      </td>
      <td>
        <p className='plt-role-description plt-body-md plt-font-color-secondary'>
          ${row?.price}
        </p>
      </td>
      <td>
        <div className='d-flex '>
          {/* {JSON.stringify(data)} */}
          <div className='d-flex flex-column'>
            {row?.features?.map((feature, index) => (
              <div key={index} className='plt-body-md plt-font-color-secondary'>
                {feature.name.replace(/_/g, " ").toUpperCase()}-{feature.value}
              </div>
            ))}
          </div>
        </div>
      </td>
      <td>
        <div className='menu cursor-pointer'>
          <div
            className='plt-select-dropdown-wrapper plt-menu-dropdown'
            ref={dropdownRef}
          >
            <span onClick={() => setIsDropdownOpen((prev) => !prev)}>
              <CardSettingsIcon />
            </span>
            {/* {isDropdownOpen && (
              <div className='user-dropdown'>
                <ul>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => {
                      // handleRenewPlan(row.id);
                    }}
                  >
                    Renew
                  </li>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    // onClick={() => handleUpgradePlan("premium")}
                  >
                    Upgrade subscription
                  </li>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    // onClick={() => handleCancelPlan(row.id)}
                  >
                    cancel subscription
                  </li>
                </ul>
              </div>
            )} */}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PlanTableRow;
