import React, { useState } from "react";
import "assets/scss/pages/_DesignSystemComponents.scss";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import { useParams } from "react-router-dom";
import { getComponentsSideData } from "assets/data/sideTabData";
import { useGlobalContext } from "context/GlobalContext";
import PreviewTagComponent from "components/DesignSystemPage/TagComponent/PreviewTagComponent";
import UsageTagComponent from "components/DesignSystemPage/TagComponent/UsageTagComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";
import { chipTokenTabData } from "assets/data/tokenTabData";


const TagComponents = () => {
  const { id } = useParams();
  const { designSystemMeta, designSystem } = useGlobalContext();
         
        const componentName = "chip";
        const componentKey = `Component/${componentName}`;
        const componentData = designSystem[componentKey];
        const tokenTableData = chipTokenTabData(componentData, componentName);

  const tabData = [
    "Primary colors",
    "Secondary colors",
    "Supporting colors",
    "Neutral colors",
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const bannerData = {
    heading: "Tags",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PreviewTagComponent
            tabData={tabData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setSelectedTab={setSelectedTab}
          />
        );
      // return <div>content1</div>;
      case 1:
        return (
          <div>
            <UsageTagComponent/>
          </div>
        );
      case 2:
        return <TokenTableComponent tokenTableData={tokenTableData} />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title='Components'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default TagComponents;
