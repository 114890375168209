import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import TooltipDetail from "../../../../assets/images/png/TooltipDetail.png";
import TooltipDetailCaret from "../../../../assets/images/png/TooltipDetailCaret.png";
import TooltipShort from "../../../../assets/images/png/TooltipShort.png";
import TooltipTruncate from "../../../../assets/images/png/TooltipTruncate.png";
import TooltipWrap from "../../../../assets/images/png/TooltipWrap.png";
import TooltipPlacement from "../../../../assets/images/png/TooltipPlacement.png";
import TooltipSpacing from "../../../../assets/images/png/TooltipSpacing.png";
import GuidelineCardTooltip from "../../../Common/Guidelines/GuidelineCardTooltip";
import { TooltipComponentUsagePrimaryDatas } from "../../../../assets/data/data";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
	{ number: 1, title: "Caret Tip", description: "An indicator linking the popover to its trigger point visually." },
	{
		number: 2,
		title: "Label (Optional)",
		description: "Text identifying a UI element or input field, ensuring user clarity.",
	},
	{
		number: 3,
		title: "Content Area",
		description: "Adjusts based on the use case, adhering to disclosure guidelines.",
	},
	{
		number: 4,
		title: "Popover Container",
		description: "The space housing text and interactive elements within the popover.",
	},
];

const UsageTooltipComponent = () => {
	const { isLoading } = useGlobalContext();
	const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];
	// const [selectedTab, setSelectedTab] = useState(0);

	const [activeSideTab, setActiveSideTab] = useState(0);

	const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[0]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Anatomy
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							A tooltip displays additional information when hovering over a UI element, offering concise details. It
							should be used sparingly to provide supplemental context.
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={TooltipDetail} alt="userimage" />
					</div>

					<ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
						{details.map((detail) => (
							<DetailItem
								key={detail.number}
								number={detail.number}
								title={detail.title}
								description={detail.description}
							/>
						))}
					</ul>

					<div className="plt-content-wrapper dis-mb-32">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Tooltip styles{" "}
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							The tooltip container can be aligned to start, center, or end to prevent it from bleeding off the page or
							covering important information. Tooltip content can be styled to truncate, wrap, or fit within a short
							container, depending on the design needs.{" "}
						</p>
					</div>
					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
						>
							Caret Tip{" "}
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							The checkbox component is often used in forms. Forms can be placed on a full page, in a modal or in a side
							panel. A checkbox can also be used for agreeing to terms and conditions or to filter information.{" "}
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center dis-mb-40">
						<img src={TooltipDetailCaret} alt="userimage" />
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8"
						>
							Container{" "}
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							The checkbox component is often used in forms. Forms can be placed on a full page, in a modal or in a side
							panel. A checkbox can also be used for agreeing to terms and conditions or to filter information.{" "}
						</p>
					</div>
					{TooltipComponentUsagePrimaryDatas.map((data, index) => (
						<div key={index} className="dis-mb-40">
							<div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_1}
									</h4>
									<div className="plt-component-card">
										<img src={TooltipShort} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_2}
									</h4>
									<div className="plt-component-card">
										<img src={TooltipTruncate} alt="userimage" />
									</div>
								</div>
								<div className="plt-btn-box">
									<h4 className="plt-character-title plt-font-color-tertiary plt-heading-xxs dis-mb-8">
										{data.sub_title_3}
									</h4>
									<div className="plt-component-card">
										<img src={TooltipWrap} alt="userimage" />
									</div>
								</div>
							</div>

							<p className={`plt-body-md plt-font-color-primary dis-mt-12 ${data.classname}`}>{data.detail}</p>
							{data.detail_list}
						</div>
					))}

					<div className="dis-mt-40 dis-mb-40">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Placement</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								Ensure the tooltip is properly aligned with the trigger and styled consistently. This alignment and
								styling improve clarity and usability across the interface.
							</p>
						</div>

						<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
							<img src={TooltipPlacement} alt="userimage" />
						</div>

						<div className="plt-content-wrapper dis-mb-24 dis-mt-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Spacing</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								Maintain consistent spacing around the text field to ensure it is visually distinct and easily
								accessible. Adequate spacing improves readability and prevents a cluttered interface.
							</p>
						</div>

						<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
							<img src={TooltipSpacing} alt="userimage" />
						</div>
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Guidelines</h6>
						<ul className="plt-detail-list dis-mt-12">
							<li className="plt-body-md plt-font-color-primary">
								Ensure tooltips are clearly associated with their corresponding triggers for better usability.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Maintain uniform spacing between tooltips and triggers for clarity and balance.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Avoid wrapping text to more than one line for a clean appearance.
							</li>
						</ul>
					</div>

					<GuidelineCardTooltip />
				</div>
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default UsageTooltipComponent;
