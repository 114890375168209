import React from "react";
import { getUser } from "hooks/storageUtils";
import { ROLES } from "constants";

const DomainTable = () => {
  const user = getUser();
  return (
      <div className='plt-content-wrapper dis-mb-32'>
        <div className='plt-content-block-top d-flex align-items-center dis-mb-12'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary font-weight-600'>
            Domains
          </h6>
        </div>
        <div className='plt-settings-table'>
          <div className='plt-table-wrapper'>
            <div className='plt-table-container'>
              <table className='plt-table'>
                <thead>
                  <tr>
                    <th className='plt-table-head'>Domain name</th>
                    <th className='plt-table-head'>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {user.domains.map((domain) => {
                    return (
                      <tr key={domain.id}>
                        <td>
                          <div className='plt-domain-name d-flex align-items-center'>
                            <p className='plt-body-sm plt-font-color-primary'>
                              {domain.name}
                            </p>
                            {/* <div className='plt-table-hover-icon dis-ml-8'></div> */}
                          </div>
                        </td>
                        <td>
                          <div className='plt-role-name plt-body-md'>
                            <p className="plt-badge plt-badge-neutral plt-ui-text-xs-medium plt-font-color-primary">
                            {
                              ROLES.find(
                                (role) => role.value === domain?.pivot?.role_id
                              )?.label
                            }
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
};

export default DomainTable;
