import React from "react";

import "./TokenTable.scss";
import CopyButton from "components/Buttons/CopyButton";

const TokenTable = ({ tokenTableData }) => {
  return (
    <div className='plt-table-wrapper'>
      <div className='plt-table-container'>
        {tokenTableData && tokenTableData.length > 0 && (
          <table className='plt-table'>
            <thead>
              <tr>
                <th className='plt-heading-xs font-weight-400 plt-font-color-tertiary'>
                  Element
                </th>
                <th className='plt-heading-xs font-weight-400 plt-font-color-tertiary'>
                  Token name
                </th>
                <th className='plt-heading-xs font-weight-400 plt-font-color-tertiary'>
                  Property
                </th>
              </tr>
            </thead>
            <tbody>
              {tokenTableData.map((row, index) => (
                <tr key={index}>
                  <td className='plt-body-compact-sm'>{row.elements}</td>
                  <td className='plt-badge-icon-wrapper  d-flex align-items-center'>
                    <p className='plt-badge plt-badge-surface plt-body-compact-sm'>
                      {row.tokenName}
                    </p>
                    {row.tokenName && (
                      <div className='plt-table-hover-icon dis-ml-8'>
                        <div className='copy-icon'>
                          <CopyButton text={row.tokenName} />
                        </div>
                      </div>
                    )}
                  </td>
                  <td className='button-property plt-body-compact-sm'>
                    {row.property}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TokenTable;
