import React, { useState } from "react";
import "./TokenTable.scss";
import EditButton from "components/Buttons/EditButton";
import CopyButton from "components/Buttons/CopyButton";
import CommonModal from "components/Common/CommonModal";
import {
  primaryColorShade,
  secondaryColorShade,
  supportingColorShade1,
  supportingColorShade2,
  supportingColorShade3,
  supportingColorShade4,
  neutralColorShade,
} from "assets/data/tokensDefaultData";
import ColorPickerComponent from "components/DesignSystemPage/ColorPickerComponent";

import ColorColumn from "./ColorColumn";
import ElevationColumn from "./ElevationColumn";
import TypographyColumn from "./TypographyColumn";
import ValueColumn from "./ValueColumn";
import EditDescriptionModal from "components/modal/EditDescriptionModal";
import { updateDescription } from "api";
import { useGlobalContext } from "context/GlobalContext";
import { toast } from "react-toastify";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";
import { getValueByPath } from "helpers/token";
import { replaceCharfromKey } from "helpers/token";

const ColorSectionComponent = ({ title, description, colorData }) => (
  <div className='plt-content-wrapper dis-mb-32'>
    {title && (
      <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary font-weight-600 dis-mb-12'>
        {title}
      </h6>
    )}
    {description && (
      <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-24'>
        {description}
      </p>
    )}
    {colorData.map((color, index) => (
      <ColorPickerComponent
        key={index}
        baseColor={color.baseColor}
        shades={color.shades}
        tokenType={color.tokenType}
      />
    ))}
  </div>
);

const TableRow = ({ rowData, openModal, index, type }) => {
  // const [isHovered, setIsHovered] = useState(false);
  // const [hoveredColumnIndex, setHoveredRowIndex] = useState(null);
  const { setLoading, designSystemMeta, designSystem } = useGlobalContext();

  const [isTypeScaleModalOpen, setColorTokenModalOpen] = useState(false);

  // const [isModalOpen, setModalOpen] = useState(false);

  // const openModal = () => {
  //   setModalOpen(true);
  // };

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  const colorSections = [
    {
      title: "Primary colors",

      colorData: [
        {
          baseColor: "#473262",
          shades: primaryColorShade,
          tokenType: "primary",
        },
      ],
    },
    {
      title: "Secondary colors",
      colorData: [
        {
          baseColor: "#FF6D3E",
          shades: secondaryColorShade,
          tokenType: "secondary",
        },
      ],
    },
    {
      title: "Supporting colors",
      colorData: [
        {
          baseColor: "#03ADFC",
          shades: supportingColorShade1,
          tokenType: "supporting-color1",
        },
        {
          baseColor: "#FCBA03",
          shades: supportingColorShade2,
          tokenType: "supporting-color2",
        },
      ],
    },

    {
      title: "Neutral colors",

      colorData: [
        {
          baseColor: "#706D69",
          shades: neutralColorShade,
          tokenType: "core.neutral",
        },
      ],
    },
  ];

  const [colortoken, setColortoken] = useState({
    name: "heading-3xl",
    description: "",
    weight: "Regular",
    size: "16",
    lineHeight: "130%",
    letterSpacing: "0px",
    paragraphSpacing: "15px",
  });

  const [charCount, setCharCount] = useState(0);
  const [isEditDescriptionModalOpen, setEditDescriptionModalOpen] =
    useState(false);

  const maxChar = 20;

  const handleInputChange = (field, value) => {
    if (field === "description" && value.length > maxChar) {
      return;
    }
    setColortoken({ ...colortoken, [field]: value });
    if (field === "description") {
      setCharCount(value.length);
    }
  };

  //Add font modal
  const openEditDescriptionModal = () => {
    setEditDescriptionModalOpen(true);
  };

  const closeEditDescriptionModal = () => {
    setEditDescriptionModalOpen(false);
  };

  const handleEditDescriptionApply = async (selectedRow, description) => {
    try {
      setLoading(true);
      const data = await updateDescription(selectedRow.id, description);
      rowData.description = description;
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to update description", error);
    } finally {
      setLoading(false);
    }
  };

  const themeColorToken = getValueByPath(
    designSystem,
    "core." + replaceCharfromKey(rowData.originalValue)
  );
  return (
    <tr>
      <td key={index}>
        <div className='plt-color-token-name d-flex align-items-center'>
          <p className='plt-badge plt-body-compact-sm'>{rowData.className}</p>
          <div className='plt-table-hover-icon dis-ml-8'>
            <div className='copy-icon'>
              <CopyButton text={rowData.className} />
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className='plt-color-token-desc d-flex align-items-center'>
          <p className='plt-color-desc plt-body-sm plt-font-color-secondary'>
            {rowData.description}
          </p>
          {rowData.description && (
            <div
              className='plt-table-hover-icon dis-ml-8'
              onClick={openEditDescriptionModal}
            >
              <AuthAction>
                <Permission designSystem={designSystemMeta}>
                  <div className='copy-icon'>
                    <EditButton />
                  </div>
                </Permission>
              </AuthAction>
            </div>
          )}
        </div>
      </td>
      <td>
        <div className='plt-color-column d-flex'>
          {type === "color" && (
            <div
              className={`plt-color-box`}
              style={{
                backgroundColor: `var(${themeColorToken?.varName})`,
              }}
            ></div>
          )}
          <div className='plt-color-detail dis-ml-16'>
            {((rowData) => {
              switch (type) {
                case "color":
                  return <ColorColumn rowData={rowData} />;
                case "typography":
                  return <TypographyColumn rowData={rowData} />;
                case "boxShadow":
                  return <ElevationColumn rowData={rowData} />;
                default:
                  return <ValueColumn rowData={rowData} />;
              }
            })(rowData)}
          </div>
          {/* <div className='plt-table-hover-icon dis-ml-8'>
            <div
              className='edit-text'
              onClick={() => setColorTokenModalOpen(true)}
            >
              <EditButton />
            </div>
          </div> */}
          <CommonModal
            isOpen={isTypeScaleModalOpen}
            onRequestClose={() => setColorTokenModalOpen(false)}
            title='Change token'
            onApply={() => setColorTokenModalOpen(false)}
            onCancelText='Cancel'
            onApplyText='Apply'
            variant='change-token-modal'
          >
            <div className='plt-modal-body-content'>
              <div className='dis-pl-24 dis-pr-24'>
                {colorSections.map((section, index) => (
                  <div key={section.title}>
                    <ColorSectionComponent {...section} />
                  </div>
                ))}{" "}
              </div>
              <div className='modal-textarea-wrapper dis-mt-32 dis-mb-24'>
                <h6 className='modal-textarea-title plt-ui-text-sm-regular plt-font-color-secondary'>
                  Add description
                </h6>
                <textarea
                  className='modal-textarea w-100'
                  placeholder='Text'
                  value={colortoken.description}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                ></textarea>
                <div className='modal-textarea-info d-flex align-items-center justify-content-between dis-mt-4'>
                  <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                    Helper text
                  </span>
                  <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                    {charCount}/{maxChar}
                  </span>
                </div>
              </div>
            </div>
          </CommonModal>
          <EditDescriptionModal
            isOpen={isEditDescriptionModalOpen}
            onRequestClose={closeEditDescriptionModal}
            onApply={handleEditDescriptionApply}
            title='Edit description'
            data={rowData}
          />
        </div>
      </td>
    </tr>
  );
};

const TokenTable = ({ headers, data, type }) => {
  return (
    <div className='plt-table-wrapper'>
      <div className='plt-table-container plt-color-token-table'>
        <table className='plt-table'>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className='plt-table-head'>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              Object.entries(data).map((row, index) => (
                <TableRow key={index} rowData={row[1]} type={type} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TokenTable;
