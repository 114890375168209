import React from "react";
import SubscriptionTableRow from "./SubscriptionTableRow";

const SubscriptionTable = ({ data, setSubscriptions }) => {
  return (
    <div className='plt-settings-table'>
      <div className='plt-table-wrapper'>
        {/* Team */}
        <div className='plt-table-container'>
          <table className='plt-table plt-table-team'>
            <thead>
              <tr>
                <th className='plt-table-head'>#</th>
                <th className='plt-table-head'>Email</th>
                <th className='plt-table-head'>Plan</th>
                <th className='plt-table-head'>Start Date</th>
                <th className='plt-table-head'>End Date</th>
                <th className='plt-table-head'>Status</th>
                <th className='plt-table-head'></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((subscription, index) => (
                <SubscriptionTableRow
                  key={subscription.id}
                  row={subscription}
                  slno={index + 1}
                  setSubscriptions={setSubscriptions}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTable;
