import { html as htmlLang } from "@codemirror/lang-html";
import CodeMirror from "@uiw/react-codemirror";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";
import { useGlobalContext } from "context/GlobalContext";
import React, { useCallback, useState } from "react";
import CopyButton from "../../Buttons/CopyButton";
import EditButton from "../../Buttons/EditButton";
import "./ComponentCard.scss";
import { vscodeDark, vscodeLight } from "@uiw/codemirror-theme-vscode";

const ComponentCard = ({
	children,
	code,
	htmlContent = `<button class="${code}" type="button">Button text</button>`,
	editClick,
	showCode = false,
	edit = false,
	cssContent = `
  .${code} {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }
`,
}) => {
	const { designSystemMeta } = useGlobalContext();
	const [htmlCode, setHtmlCode] = useState(htmlContent);
	const [cssCode, setCssCode] = useState(cssContent);
	const [activeTab, setActiveTab] = useState("html");

	const handleHtmlChange = useCallback((value) => {
		setHtmlCode(value);
	}, []);

	// Function to extract className from the htmlCode
	const extractClassName = (html) => {
		const classNameMatch = html.match(/class="([^"]+)"/);
		return classNameMatch ? classNameMatch[1] : "";
	};

	// Update the children with the new className extracted from the updated HTML
	const updatedChildren = React.cloneElement(children, {
		className: extractClassName(htmlCode),
	});

	const setActiveTabHandler = (tab) => {
		setActiveTab(tab);
	};

	return (
		<div className="component-card">
			<div className="component-area-ui d-flex justify-content-center dis-column-gap-12 dis-row-gap-12">
				{updatedChildren} {/* Render updated children here */}
				{edit && (
					<AuthAction>
						<Permission designSystem={designSystemMeta}>
							<div onClick={editClick} className="component-edit-btn">
								<EditButton />
							</div>
						</Permission>
					</AuthAction>
				)}
			</div>
			{showCode && (
				<div className="component-area-code">
					<div className="tabs">
						<h3 className="component-code-title plt-heading-xs font-color-primary dis-mb-8">HTML</h3>
					</div>
					<div className="component-area-code-wrapper d-flex">
						{activeTab === "html" && (
							<CodeMirror
								style={{ width: "100%", height: "auto" }}
								value={htmlCode}
								height="75px"
								width="100"
								extensions={[htmlLang()]}
								onChange={handleHtmlChange}
								theme={vscodeLight}
							/>
						)}
						<div className="component-copy-btn">
							<CopyButton text={activeTab === "html" ? htmlCode : cssCode} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ComponentCard;
