import React, { useEffect, useState } from "react";

import PrimaryButton from "components/Common/PrimaryButton";
import SettingsLayout from "components/layouts/SettingsLayout";
import { useGlobalContext } from "context/GlobalContext";
import { getUser } from "hooks/storageUtils";
import "./General.scss";
import FormValidationError from "components/FormValidationError";
import { updateGeneralSettings } from "api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const General = () => {
	const { setLoading } = useGlobalContext();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		companyname: "",
		domain: "",
	});
	const [errors, setErrors] = useState({});

	const fields = [
		{ name: "companyname", label: "Organization name" },
		{ name: "domain", label: "Domain" },
	];

	const user = getUser();
	const domain = user.domains.find((domain) => domain.pivot.role_id == 1);

	useEffect(() => {
		setFormData({
			companyname: user.companyname,
			domain: domain?.name,
		});
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSave = async () => {
		const newErrors = {};
		fields.forEach((field) => {
			if (!formData[field.name]) {
				newErrors[field.name] = `${field.label} is required`;
			}
		});

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		try {
			setLoading(true);
			const user = getUser();
			const data = await updateGeneralSettings(formData);
			localStorage.setItem("user", JSON.stringify(data.data));
			setErrors({});
		} catch (error) {
			const errorMessage =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message || "An unexpected error occurred";
			toast.error(errorMessage);
			setErrors({});
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		navigate("/");
	};

	return (
		<SettingsLayout>
			<div className="tab-content-wrapper tab-content-setting">
				<div className="tab-content-container">
					{/* <div className="plt-content-block dis-mb-0">
						<div className="plt-content-wrapper dis-mb-32">
							<div className="plt-content-block-top d-flex justify-content-between align-items-center">
								<h6 className="plt-contents-head plt-heading-xl plt-font-color-primary">General</h6>
							</div>
						</div>
					</div> */}

					<div className="general-container">
						<div className="plt-select-dropdown-wrapper dis-mb-24">
							<h6 className="plt-select-dropdown-title  plt-ui-text-md-medium  dis-mb-4">Organization name</h6>
							<div className="modal-value-block w-100">
								<input
									className="input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary"
									type="text"
									name="companyname"
									value={formData["companyname"]}
									onChange={handleChange}
								/>
							</div>
							{errors?.companyname && <FormValidationError error={errors.companyname} />}
						</div>

						<div className="plt-select-dropdown-wrapper dis-mb-32">
							<h6 className="plt-select-dropdown-title  plt-ui-text-md-medium  dis-mb-4">Domain</h6>
							<div className="modal-value-block w-100 d-flex">
								<input
									className="input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary"
									type="text"
									name="domain"
									value={formData["domain"]}
									onChange={handleChange}
								/>
								<input
									className="design-input input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary dis-ml-16"
									type="text"
									placeholder=".dessign.systems"
									readonly
									value=".dessign.systems"
								/>
							</div>
							{errors?.domain && <FormValidationError error={errors.domain} />}
						</div>
					</div>

					<div className="plt-modal-footer dis-p-0">
						<button className="plt-btn plt-btn-secondary" onClick={handleCancel}>
							Cancel
						</button>
						<button className="plt-btn plt-btn-primary" onClick={handleSave}>
							Save changes
						</button>
					</div>
				</div>
			</div>
		</SettingsLayout>
	);
};

export default General;
