import data from "components/DesignSystemPage/InputComponent/data";

const getPropertyData = (componentData, componentName, property, childProperty) => {
	const data =
		property && property === "color"
			? componentData[property][childProperty][componentName]
			: componentData[property][componentName];
	return data;
};

export const modalTabData = ["Header main component", "Body main component", "Footer"];

export const modalTokenTabData = (componentData, componentName) => [
	{
		elements: "Text/Label",
		tokenName: getPropertyData(componentData, componentName, "color", "text")?.textonly?.primary.tokenName,
		property: "Text color",
	},
	{
		elements: "Typography",
		tokenName: getPropertyData(componentData, componentName, "font")?.textonly?.normal?.tokenName,
		property: "Normal",
	},
	{
		elements: "Border radius",
		tokenName: getPropertyData(componentData, componentName, "borderRadius")?.default?.full?.tokenName,
		property: "Border radius",
	},
	{
		elements: "sizing",
		tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.normal?.tokenName,
		property: "Size of the container",
	},
	{
		elements: "Fill",
		tokenName: getPropertyData(componentData, componentName, "color", "background")?.textonly?.silhouette?.default
			?.tokenName,
		property: "Button/container color",
	},
];

// export const emptyStateTabData = ["Default"];
export const emptystateTokenTabData = (componentData, componentName) => [
	{
		type: "Normal",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.dense?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.large?.tokenName,
								property: "Spacing between the items",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.stack?.iconText?.tokenName,
								property: "Spacing between the icon and text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.stack?.textCtc?.tokenName,
								property: "Spacing between the text and button",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.stack?.textSubtext?.tokenName,
								property: "Spacing between the text and subtext",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.text?.large?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.text?.normal?.tokenName,
								property: "Font-subtext",
							},
							{
								elements: "Fill",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.surface?.tokenName,
								property: "Background color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.primary?.tokenName,
								property: "Text color",
							},
							{
								elements: "Text",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.secondary?.tokenName,
								property: "Text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
		
		],
	},
];

// Pagination token tab data
export const paginationTokenTabData = (componentData, componentName) => [
	{
		type: "Normal",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.tokenName,
								property: "Icon color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.tokenName,
								property: "Text color",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.text?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.normal?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.betweenCounts?.tokenName,
								property: "Spacing between the items",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.tokenName,
								property: "Border radius",
							},
						],
					},
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Fill",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.pressed?.tokenName,
								property: "Background color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.pressed?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.pressed?.tokenName,
								property: "Text color",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.disabled?.tokenName,
								property: "Icon color",
							},
						],
					},
				],
			},
		],
	},
];

// radio button token tab data
export const radioButtonTokenTabData = (componentData, componentName) => [
	{
		type: "Normal",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.inactive
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.active?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.inactive
									?.tokenName,
								property: "Border color",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.default?.uitext?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.normal?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.inline?.normal
									?.choiceLabel?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.default?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.default?.full?.tokenName,
								property: "Border radius",
							},
						],
					},
					{
						size: "dense",
						data: [
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.default?.uitext?.dense?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.dense?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.inline?.dense?.choiceLabel
									?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.default?.dense?.tokenName,
								property: "Border width size",
							},
						],
					},
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.hovered
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.hovered?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.hovered
									?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.pressed
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.pressed?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.pressed
									?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.disabled
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.disabled?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.disabled
									?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
		],
	},
	{
		type: "Outline",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.outline?.inactive
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.outline?.active?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.outline?.inactive
									?.tokenName,
								property: "Border color",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.outline?.uitext?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.outline?.normal?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.outline?.inline?.normal
									?.choiceLabel?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.outline?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.outline?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.outline?.normal?.default
									?.tokenName,
								property: "Border width size",
							},
						],
					},
					{
						size: "dense",
						data: [
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.outline?.uitext?.dense?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.outline?.dense?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.outline?.inline?.dense?.choiceLabel
									?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.outline?.dense?.default
									?.tokenName,
								property: "Border width size",
							},
						],
					},
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.outline?.hovered
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.outline?.hovered?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.outline?.hovered
									?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.outline?.pressed
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.outline?.pressed?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.outline?.pressed
									?.tokenName,
								property: "Border color",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.outline?.normal?.pressed
									?.tokenName,
								property: "Border width size",
							},
						],
					},
					{
						size: "dense",
						data: [
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.outline?.dense?.pressed
									?.tokenName,
								property: "Border width size",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.outline?.disabled
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.outline?.disabled?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.outline?.disabled
									?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
		],
	},
];

// export const toasterTabData = [
// 	"Normal",
// 	"Normal with leading icon",
// 	"Normal with closable icon",
// 	"Normal with leading and closable icon",
// 	"Normal with actions",
// 	"Normal with actions and leading icon",
// 	"Normal with actions and closable icon",
// 	"Normal with actions, leading and closable icon",
// 	"Normal with wrapped actions",
// 	"Normal with wrapped actions and closable icon",
// 	"Normal with leading icon, wrapped actions and closable icon",
// ];

// toaster button token tab data
export const toasterTokenTabData = (componentData, componentName) => [
	{
		type: "Normal",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.large?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.normal?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.leadingiconMessagetext?.tokenName,
								property: "Spacing between leadingicon and messagetext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.messagetextCloseicon?.tokenName,
								property: "Spacing between messagetext and closeicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.messagetextActionlink?.tokenName,
								property: "Spacing between messagetext and actionlink",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.stack?.messagetextActionlink?.tokenName,
								property: "Horizondal Spacing between messagetext and actionlink",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.messageText?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.tokenName,
								property: "Background color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.primary?.tokenName,
								property: "Text/Message color",
							},
						],
					},
				
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.hovered?.tokenName,
								property: "Background color",
							},
						],
					},
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.pressed?.tokenName,
								property: "Background color",
							},
						],
					},
				],
			},
			
		],
	},
];


// checkbox button token tab data
export const checkboxButtonTokenTabData = (componentData, componentName) => [
	{
		type: "Normal",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.inactive
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.inactive
									?.tokenName,
								property: "Border color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.primary
									?.tokenName,
								property: "Icon color",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.default?.uitext?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.normal?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.inline?.normal
									?.choiceLabel?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.default?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.default?.normal?.tokenName,
								property: "Border radius",
							},
						],
					},
					{
						size: "dense",
						data: [
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.default?.uitext?.dense?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.dense?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.inline?.dense?.choiceLabel
									?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.default?.dense?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.default?.dense?.tokenName,
								property: "Border radius",
							},
						],
					},
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.hovered
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.hovered?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.hovered
									?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.pressed
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.pressed?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.pressed
									?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.disabled
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.disabled?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.disabled
									?.tokenName,
								property: "Border color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.primary
									?.tokenName,
								property: "Icon color",
							},
						],
					},
				],
			},
		],
	},
	{
		type: "Outline",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.outline?.inactive
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.outline?.inactive
									?.tokenName,
								property: "Border color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.outline?.primary
									?.tokenName,
								property: "Icon color",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.outline?.uitext?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.outline?.normal?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.outline?.inline?.normal
									?.choiceLabel?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.outline?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.outline?.normal?.tokenName,
								property: "Border radius",
							},
						],
					},
					{
						size: "dense",
						data: [
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.outline?.uitext?.dense?.tokenName,
								property: "Font",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.outline?.dense?.tokenName,
								property: "Size of the container",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.outline?.inline?.dense?.choiceLabel
									?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.outline?.dense?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.outline?.dense?.tokenName,
								property: "Border radius",
							},
						],
					},
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.outline?.hovered
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.outline?.hovered
									?.tokenName,
								property: "Border color",
							},
						],
					},
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.outline?.pressed
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.outline?.pressed
									?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "dense",
						data: [
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.outline?.dense?.pressed
									?.tokenName,
								property: "Border width size",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.outline?.disabled
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.outline?.disabled
									?.tokenName,
								property: "Border color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.outline?.disabled
									?.tokenName,
								property: "Icon color",
							},
						],
					},
				],
			},
		],
	},
];

// alert button token tab data
export const alertTokenTabData = (componentData, componentName) => [
	{
		type: "Informative",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.informative?.tokenName,
								property: "Background color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.informative?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.informative?.tokenName,
								property: "Icon color",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.text?.heading?.large?.tokenName,
								property: "Font - heading text",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.text?.body?.normal?.tokenName,
								property: "Font - body text",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.normal?.tokenName,
								property: "Size of the Icon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.leadingiconText?.tokenName,
								property: "Spacing between the icon and text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.betweenTextCtc?.tokenName,
								property: "Spacing between the icons",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.TextCtcIcononly?.tokenName,
								property: "Spacing between the icon-content and button-group ",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.stack?.headingBodytext?.tokenName,
								property: "Spacing between headingBodytext ",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.dense?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.dense?.tokenName,
								property: "Border width",
							},
						],
					},
				],
			},
		],
	},
	{
		type: "Caution",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.caution?.tokenName,
								property: "Background color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.caution?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.caution?.tokenName,
								property: "Icon color",
							},
						],
					},
				],
			},
		],
	},
	{
		type: "Success",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.success?.tokenName,
								property: "Background color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.success?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.success?.tokenName,
								property: "Icon color",
							},
						],
					},
				],
			},
		],
	},
	{
		type: "Danger",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.danger?.tokenName,
								property: "Background color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.danger?.tokenName,
								property: "Text color",
							},
							{
								elements: "",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.danger?.tokenName,
								property: "Icon color",
							},
						],
					},
				],
			},
		],
	},
];

// avatar button token tab data
export const avatarTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "Normal",
				data: [
					{
						size: "Extraextralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.extraextralarge?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.default?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.default?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.primary
									?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "Extralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.extralarge?.tokenName,
								property: "Size of the Avatar",
							},
						],
					},
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.large?.tokenName,
								property: "Size of the Avatar",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.normal?.tokenName,
								property: "Size of the Avatar",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.dense?.tokenName,
								property: "Size of the Avatar",
							},
						],
					},
					{
						size: "Denser",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.denser?.tokenName,
								property: "Size of the Avatar",
							},
						],
					},
				],
			},
			{
				state: "With status",
				data: [
					{
						size: "Extraextralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.statusicon?.extraextralarge?.tokenName,
								property: "Size of the statusicon",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.default?.status?.full?.tokenName,
								property: "Border radius of status-icon",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.statusactive
									?.tokenName,
								property: "Border color of status-icon",
							},
						],
					},
					{
						size: "Extralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.statusicon?.extralarge?.tokenName,
								property: "Size of the Icon",
							},
						],
					},
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.statusicon?.large?.tokenName,
								property: "Size of the Icon",
							},
						],
					},
				],
			},
		],

	},
	{
		type: "Silhouette",
		data: [
			{
				state: "Normal",
				data: [
					{
						size: "Extraextralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.extraextralarge?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.icon?.extraextralarge?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.silhouette?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.silhouette?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.silhouette?.default
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.silhouette?.primary
									?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "Extralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.extralarge?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.icon?.extralarge?.tokenName,
								property: "Size of the icon",
							},
						],
					},
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.large?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.icon?.large?.tokenName,
								property: "Size of the icon",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.normal?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.icon?.normal?.tokenName,
								property: "Size of the icon",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.dense?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.icon?.dense?.tokenName,
								property: "Size of the icon",
							},
						],
					},
					{
						size: "Denser",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.denser?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.icon?.denser?.tokenName,
								property: "Size of the icon",
							},
						],
					},
				],
			},
			{
				state: "With status",
				data: [
					{
						size: "Extraextralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.statusicon?.extraextralarge?.tokenName,
								property: "Size of the statusicon",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.silhouette?.status?.full?.tokenName,
								property: "Border radius of status-icon",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.silhouette?.statusactive
									?.tokenName,
								property: "Background color - statusactive",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.silhouette?.statusactive
									?.tokenName,
								property: "Border color of status-icon",
							},
						],
					},
					{
						size: "Extralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.statusicon?.extralarge?.tokenName,
								property: "Size of the statusicon",
							},
						],
					},
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.silhouette?.statusicon?.large?.tokenName,
								property: "Size of the statusicon",
							},
						],
					},
				],
			},
		],

	},
	{
		type: "Text",
		data: [
			{
				state: "Normal",
				data: [
					{
						size: "Extraextralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.extraextralarge?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.textonly?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.textonly?.extraextralarge?.tokenName,
								property: "Font",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.textonly?.silhouette?.default
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.textonly?.primary?.tokenName,
								property: "Text color",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.textonly?.primary
									?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "Extralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.extralarge?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.textonly?.extralarge?.tokenName,
								property: "Font",
							},
						],
					},
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.large?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.textonly?.large?.tokenName,
								property: "Font",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.normal?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.textonly?.normal?.tokenName,
								property: "Font",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.dense?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.textonly?.dense?.tokenName,
								property: "Font",
							},
						],
					},
					{
						size: "Denser",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.denser?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.textonly?.denser?.tokenName,
								property: "Font",
							},
						],
					},
				],
			},
			{
				state: "With status",
				data: [
					{
						size: "Extraextralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.statusicon?.extraextralarge?.tokenName,
								property: "Size of the statusicon",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.textonly?.status?.full?.tokenName,
								property: "Border radius of status-icon",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.textonly?.silhouette?.statusactive
									?.tokenName,
								property: "Background color - statusactive",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.textonly?.statusactive
									?.tokenName,
								property: "Border color of status-icon",
							},
						],
					},
					{
						size: "Extralarge",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.statusicon?.extralarge?.tokenName,
								property: "Size of the statusicon",
							},
						],
					},
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.textonly?.statusicon?.large?.tokenName,
								property: "Size of the statusicon",
							},
						],
					},
				],
			},
		],
		
	},
];

// avatargroup button token tab data
export const avatargroupTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.large?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.large?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.large?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.text?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.large?.tokenName,
								property: "Spacing between the elements",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.primary?.tokenName,
								property: "Background color - count",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.secondary?.tokenName,
								property: "Border color - count",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.primary?.tokenName,
								property: "Border color - avatar",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.primary?.tokenName,
								property: "Text color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.normal?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.text?.dense?.tokenName,
								property: "Font",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.normal?.tokenName,
								property: "Spacing between the elements",
							},
							
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.dense?.tokenName,
								property: "Size of the Avatar",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.dense?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.dense?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.text?.denser?.tokenName,
								property: "Font",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.dense?.tokenName,
								property: "Spacing between the elements",
							},
						],
					},
					
				],
			},
		
		],

	},
];

// tooltip token tab data
export const tooltipTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "No Arrow",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.normal?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.stack?.normal?.labelMessage?.tokenName,
								property: "Spacing between the labels",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.labeltext?.normal?.tokenName,
								property: "Font - label",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.messagetext?.normal?.tokenName,
								property: "Font - message",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default
									?.tokenName,
								property: "Background color",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.primary?.tokenName,
								property: "Text color",
							},
							{
								elements: "Text",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.secondary?.tokenName,
								property: "Text color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.dense?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.stack?.dense?.labelMessage?.tokenName,
								property: "Spacing between the labels",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.dense?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.dense?.tokenName,
								property: "Border width size",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.labeltext?.dense?.tokenName,
								property: "Font - label",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.messagetext?.dense?.tokenName,
								property: "Font - message",
							},
							
						],
					},
					
				],
			},
		
		],
	},
	
];

// toggle token tab data
export const toggleTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.extralarge?.tokenName,
								property: "Size of the track",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.normal?.tokenName,
								property: "Height of the track",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.dense?.tokenName,
								property: "Size of the knob",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.large?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "boxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.dropshadow?.switch?.large?.tokenName,
								property: "boxShadow",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.large?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.tokenName,
								property: "Background color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.switch?.tokenName,
								property: "Background color - knob",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.dense?.tokenName,
								property: "Size of the track",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.denser?.tokenName,
								property: "Height of the track",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.denser?.tokenName,
								property: "Size of the knob",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "boxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.dropshadow?.switch?.normal?.tokenName,
								property: "boxShadow",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.normal?.tokenName,
								property: "Spacing",
							},
						],
					},
				],
			},
			{
				state: "Hover",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.hovered?.tokenName,
								property: "Background color",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.hovered?.tokenName,
								property: "Border color",
							},
						],
					},
				
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.pressed?.tokenName,
								property: "Background color",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.pressed?.tokenName,
								property: "Border color",
							},
						],
					},
				
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.disabled?.tokenName,
								property: "Background color",
							},
							{
								elements: "color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.disabled?.tokenName,
								property: "Border color",
							},
						],
					},
				
				],
			},
		],
	},
	{
		type: "Toggle with text",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.large?.tokenName,
								property: "Spacing betweent toggle and text",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.large?.tokenName,
								property: "Font",
							},
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.tokenName,
								property: "Text color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.normal?.tokenName,
								property: "Spacing betweent toggle and text",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.normal?.tokenName,
								property: "Font",
							},
						],
					},
				],
			},
			{
				state: "Hover",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.hovered?.tokenName,
								property: "Text color",
							},
						],
					},
				
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.pressed?.tokenName,
								property: "Text color",
							},
						],
					},
				
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Text/Label",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.disabled?.tokenName,
								property: "Text color",
							},
						],
					},
				
				],
			},
		],
	},
	
];

// textfield button token tab data
export const textfieldTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "Inactive",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.normal?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.inset?.normal?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.stack?.fieldHelpertext?.tokenName,
								property: "Spacing between input and error-text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.stack?.labelField?.tokenName,
								property: "Spacing between input and label",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.inline?.leadingiconPrefixValue?.tokenName,
								property: "Spacing between leadingicon and prefix-value",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.inline?.validatioiconHelpertext?.tokenName,
								property: "Spacing between validatioicon and helpertext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.inline?.suffixTraillingicon?.tokenName,
								property: "Spacing between suffix and traillingicon",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.valuetext?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.label?.normal?.tokenName,
								property: "Font - label",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.validation?.normal?.tokenName,
								property: "Font - validation",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.inactive?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.inactive?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.primary?.tokenName,
								property: "Text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.secondary?.tokenName,
								property: "Label color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.tertiary?.tokenName,
								property: "Prefix color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.placeholder?.tokenName,
								property: "Placeholderr color",
							},
							
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.dense?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.inset?.dense?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.stack?.fieldHelpertext?.tokenName,
								property: "Spacing between input and error-text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.stack?.labelField?.tokenName,
								property: "Spacing between input and label",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.inline?.leadingiconPrefixValue?.tokenName,
								property: "Spacing between leadingicon and prefix-value",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.inline?.validatioiconHelpertext?.tokenName,
								property: "Spacing between validatioicon and helpertext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.inline?.suffixTraillingicon?.tokenName,
								property: "Spacing between suffix and traillingicon",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.dense?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.dense?.tokenName,
								property: "Border width",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.valuetext?.dense?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.label?.dense?.tokenName,
								property: "Font - label",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.validation?.dense?.tokenName,
								property: "Font - validation",
							},
						],
					},
				],
			},
			{
				state: "Focused",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.focused?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.focused?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Filled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.filled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.filled?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Readonly",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.readonly?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.readonly?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.disabled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.disabled?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.disabled?.tokenName,
								property: "Text color",
							},

						],
					},
					
				],
			},
			{
				state: "Negative",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.negative?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.negative?.tokenName,
								property: "Text color",
							},
							
						],
					},
					
				],
			},
		],
	},
	{
		type: "Fluid",
		data: [
			{
				state: "Inactive",
				data: [
					{
						size: "Normal",
						data: [
							
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.inset?.normal?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.stack?.fieldHelpertext?.tokenName,
								property: "Spacing between input and error-text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.inline?.validatioiconHelpertext?.tokenName,
								property: "Spacing between validatioicon and helpertext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.inline?.sufixTraillingicon?.tokenName,
								property: "Spacing between suffix and traillingicon",
							},
						],
					},
					{
						size: "Dense",
						data: [
							
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.inset?.dense?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.stack?.fieldHelpertext?.tokenName,
								property: "Spacing between input and error-text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.stack?.labelField?.tokenName,
								property: "Spacing between input and label",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.inline?.leadingiconPrefixValue?.tokenName,
								property: "Spacing between leadingicon and prefix-value",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.inline?.validatioiconHelpertext?.tokenName,
								property: "Spacing between validatioicon and helpertext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.inline?.suffixTraillingicon?.tokenName,
								property: "Spacing between suffix and traillingicon",
							},
						],
					},
				],
			},
			
		],
	},
	
];

// textarea button token tab data
export const textareaTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "Inactive",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.normal?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.inset?.normal?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.stack?.fieldHelpertext?.tokenName,
								property: "Spacing between input and error-text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.stack?.labelField?.tokenName,
								property: "Spacing between input and label",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.inline?.validatioiconHelpertext?.tokenName,
								property: "Spacing between validatioicon and helpertext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.normal?.inline?.helpertextCount?.tokenName,
								property: "Spacing between helpertext and count",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.valuetext?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.label?.normal?.tokenName,
								property: "Font - label",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.validation?.normal?.tokenName,
								property: "Font - validation",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.inactive?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.inactive?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.primary?.tokenName,
								property: "Text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.secondary?.tokenName,
								property: "Label color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.tertiary?.tokenName,
								property: "Count color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.placeholder?.tokenName,
								property: "Placeholderr color",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.helpertext?.normal?.tokenName,
								property: "Font - helpertext",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.dense?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.inset?.dense?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.stack?.fieldHelpertext?.tokenName,
								property: "Spacing between input and error-text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.stack?.labelField?.tokenName,
								property: "Spacing between input and label",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.inline?.validatioiconHelpertext?.tokenName,
								property: "Spacing between validatioicon and helpertext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.dense?.inline?.helpertextCount?.tokenName,
								property: "Spacing between helpertext and count",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.dense?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.dense?.tokenName,
								property: "Border width",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.valuetext?.dense?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.label?.dense?.tokenName,
								property: "Font - label",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.validation?.dense?.tokenName,
								property: "Font - validation",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.helpertext?.dense?.tokenName,
								property: "Font - helpertext",
							},
						],
					},
				],
			},
			{
				state: "Focused",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.focused?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.focused?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Filled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.filled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.filled?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Readonly",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.readonly?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.readonly?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.disabled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.disabled?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.disabled?.tokenName,
								property: "Text color",
							},

						],
					},
					
				],
			},
			{
				state: "Negative",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.negative?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.negative?.tokenName,
								property: "Text color",
							},
							
						],
					},
					
				],
			},
		],
	},
	{
		type: "Fluid",
		data: [
			{
				state: "Inactive",
				data: [
					{
						size: "Normal",
						data: [
							
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.inset?.normal?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.stack?.fieldHelpertext?.tokenName,
								property: "Spacing between input and error-text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.inline?.validatioiconHelpertext?.tokenName,
								property: "Spacing between validatioicon and helpertext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.stack?.labelValueChip?.tokenName,
								property: "Spacing between label and chip",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.inline?.helpertextCount?.tokenName,
								property: "Spacing between helpertext and count",
							},
						],
					},
					{
						size: "Dense",
						data: [
							
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.inset?.dense?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.stack?.fieldHelpertext?.tokenName,
								property: "Spacing between input and error-text",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.inline?.validatioiconHelpertext?.tokenName,
								property: "Spacing between validatioicon and helpertext",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.stack?.labelValueChip?.tokenName,
								property: "Spacing between label and chip",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.inline?.helpertextCount?.tokenName,
								property: "Spacing between helpertext and count",
							},
						],
					},
				],
			},
			
		],
	},
	
];


// tabbutton token tab data
export const tabTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "Inactive",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.icon?.normal?.tokenName,
								property: "Size of the prefix icon",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.default?.icon?.dense?.tokenName,
								property: "Size of the suffix icon",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.default?.large?.tokenName,
								property: "Border width Active bottom line",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.default?.normal?.tokenName,
								property: "Border width bottom line",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.tablist?.inset?.normal?.tokenName,
								property: "Spacing tab-item",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.tablist?.inline?.betweenTabs?.tokenName,
								property: "Spacing between Tabs",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.default?.tablist?.inline?.leadingiconTabtext?.tokenName,
								property: "Spacing tbetween leadingicon and tabtext",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.default?.uitext?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.stroke?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.unseclect?.tokenName,
								property: "Text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.selected?.tokenName,
								property: "Text color Active",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.unselected?.tokenName,
								property: "Icon color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.primary?.tokenName,
								property: "Icon color active",
							},
						],
					},
					
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.hovered?.tokenName,
								property: "Border color tab item",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.hovered?.tokenName,
								property: "Text color",
							},
						],
					},
				],
			},	
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.disabled?.tokenName,
								property: "Text color Active",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.disabled?.tokenName,
								property: "Icon color",
							},
						],
					},
				],
			},			
		],
	},
	{
		type: "Pill",
		data: [
			{
				state: "Inactive",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.pill?.icon?.normal?.tokenName,
								property: "Size of the prefix icon",
							},
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.pill?.icon?.dense?.tokenName,
								property: "Size of the suffix icon",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.pill?.normal?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.pill?.tablist?.inset?.normal?.tokenName,
								property: "Spacing tab-item",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.pill?.tablist?.inline?.betweenTabs?.tokenName,
								property: "Spacing between Tabs",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.pill?.tablist?.inline?.leadingiconTabtext?.tokenName,
								property: "Spacing tbetween leadingicon and tabtext",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.pill?.uitext?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.pill?.selected?.tokenName,
								property: "Border color - active tabitem",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.pill?.unselected?.tokenName,
								property: "Background color - tabitem",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.pill?.selected?.tokenName,
								property: "Background color - active tabitem",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.pill?.unseclect?.tokenName,
								property: "Text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.pill?.selected?.tokenName,
								property: "Text color Active",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.pill?.unselected?.tokenName,
								property: "Icon color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.pill?.primary?.tokenName,
								property: "Icon color active",
							},
						],
					},
				
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.pill?.hovered?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.pill?.hover?.tokenName,
								property: "Background color - tabitem",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.pill?.hovered?.tokenName,
								property: "Text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.pill?.secondary?.tokenName,
								property: "Icon color active",
							},
						],
					},
				],
			},	
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
					
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.pill?.disabled?.tokenName,
								property: "Text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.pill?.disabled?.tokenName,
								property: "Icon color active",
							},
						],
					},
				],
			},			
		],
	},
	
];

// chip token tab data
export const chipTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.normal?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.large?.default?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.large?.statusicon?.tokenName,
								property: "Spacing with statusicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.large?.closeicon?.tokenName,
								property: "Spacing with closeicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.large?.textCount?.tokenName,
								property: "Spacing between text and count",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.large?.textClosableicon?.tokenName,
								property: "Spacing between text and closableicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.large?.leadingiconText?.tokenName,
								property: "Spacing between text and leadingicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.large?.statusiconText?.tokenName,
								property: "Spacing between text and statusicon",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.normal?.labelText?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.normal?.countText?.tokenName,
								property: "Font-countText",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.default?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.default?.tokenName,
								property: "Border Color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.default?.tokenName,
								property: "text",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.checkicon?.tokenName,
								property: "Checkicon Color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.primary?.tokenName,
								property: "Icon Color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.surface?.tokenName,
								property: "Close icon background Color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.normal?.default?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.normal?.statusicon?.tokenName,
								property: "Spacing with statusicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.normal?.closeicon?.tokenName,
								property: "Spacing with closeicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.normal?.textCount?.tokenName,
								property: "Spacing between text and count",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.normal?.textClosableicon?.tokenName,
								property: "Spacing between text and closableicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.normal?.leadingiconText?.tokenName,
								property: "Spacing between text and leadingicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.normal?.statusiconText?.tokenName,
								property: "Spacing between text and statusicon",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.normal?.labelText?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.normal?.countText?.tokenName,
								property: "Font-countText",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.dense?.default?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.dense?.statusicon?.tokenName,
								property: "Spacing with statusicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.dense?.closeicon?.tokenName,
								property: "Spacing with closeicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.dense?.textCount?.tokenName,
								property: "Spacing between text and count",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.dense?.textClosableicon?.tokenName,
								property: "Spacing between text and closableicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.dense?.leadingiconText?.tokenName,
								property: "Spacing between text and leadingicon",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.dense?.statusiconText?.tokenName,
								property: "Spacing between text and statusicon",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.dense?.labelText?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.dense?.countText?.tokenName,
								property: "Font-countText",
							},
						],
					},
					
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.hovered?.tokenName,
								property: "Background",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.hovered?.tokenName,
								property: "Border Color",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.hovered?.tokenName,
								property: "text",
							},
							
							
						],
					},
				],
			},	
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.pressed?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.pressed?.tokenName,
								property: "Border Color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.pressed?.tokenName,
								property: "text",
							},
						],
					},
				],
			},	
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.disabled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.disabled?.tokenName,
								property: "Border Color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.disabled?.tokenName,
								property: "text",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.disabled?.tokenName,
								property: "Icon Color",
							},
						],
					},
				],
			},			
		],
	},
	{
		type: "Positive",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.positive?.default?.tokenName,
								property: "Background - positive",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.positive?.default?.tokenName,
								property: "Border Color - positive",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.positive?.default?.tokenName,
								property: "text - positive",
							},
						],
					},
					
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.positive?.hovered?.tokenName,
								property: "Background - positive",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.positive?.hovered?.tokenName,
								property: "Border Color - positive",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.positive?.hovered?.tokenName,
								property: "text - positive",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.positive?.checkicon?.tokenName,
								property: "Checkicon Color - positive",
							},
							
						],
					},
				],
			},	
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.positive?.pressed?.tokenName,
								property: "Background - positive",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.positive?.pressed?.tokenName,
								property: "Border Color - positive",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.positive?.hovered?.tokenName,
								property: "text - positive",
							},
						],
					},
				],
			},	
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.positive?.disabled?.tokenName,
								property: "Background - positive",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.positive?.disabled?.tokenName,
								property: "Border Color - positive",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.positive?.disabled?.tokenName,
								property: "text - positive",
							},
							
						],
					},
				],
			},			
		],
	},
	{
		type: "Negative",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.negative?.default?.tokenName,
								property: "Background - negative",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.negative?.default?.tokenName,
								property: "Border Color - negative",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.negative?.default?.tokenName,
								property: "text - negative",
							},
						
						],
					},
			
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.negative?.hovered?.tokenName,
								property: "Background - negative",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.negative?.hovered?.tokenName,
								property: "Border Color - negative",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.negative?.hovered?.tokenName,
								property: "text - negative",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.negative?.checkicon?.tokenName,
								property: "Checkicon Color - negative",
							},
						],
					},
				],
			},	
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.negative?.pressed?.tokenName,
								property: "Background - negative",
							},
							
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.negative?.pressed?.tokenName,
								property: "Border Color - negative",
							},
						
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.negative?.hovered?.tokenName,
								property: "text - negative",
							},
						],
					},
				],
			},	
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
						
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.negative?.disabled?.tokenName,
								property: "Background - negative",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.negative?.disabled?.tokenName,
								property: "Border Color - negative",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.negative?.disabled?.tokenName,
								property: "text - negative",
							},
							
						],
					},
				],
			},			
		],
	},
];

// table token tab data
export const tableTokenTabData = (componentData, componentName) => [
	{
		type: "Normal",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.headercell?.inset?.normal?.tokenName,
								property: "Spacing headercell",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.bodycell?.inset?.normal?.tokenName,
								property: "Spacing bodycell",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.headerCell?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.bodyCell?.subText?.normal?.tokenName,
								property: "Font",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.stroke?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.headercell?.default?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.bodycell?.default?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.primary?.tokenName,
								property: "text",
							},
						],
					},
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.headercell?.hovered?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.bodycell?.hovered?.tokenName,
								property: "Background",
							},
						],
					},
				],
			},	
		],
	},
];

// button button token tab data
export const buttonTokenTabData = (componentData, componentName) => [
	{
		type: "Default",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.large?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.large?.tokenName,
								property: "Border width",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.large?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.large?.leadingiconButtontextTraillingicon?.tokenName,
								property: "Spacing between icons and button-text",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.large?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.large?.tokenName,
								property: "Font",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.fill?.primary?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.fill?.primary?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.fill?.primary?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.fill?.primary?.tokenName,
								property: "Icon color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.normal?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.normal?.tokenName,
								property: "Border width",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.normal?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.normal?.leadingiconButtontextTraillingicon?.tokenName,
								property: "Spacing between icons and button-text",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.normal?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.normal?.tokenName,
								property: "Font",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icon?.dense?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.dense?.tokenName,
								property: "Border width",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inset?.dense?.tokenName,
								property: "Spacing",
							},
							{
								elements: "Spacing",
								tokenName: getPropertyData(componentData, componentName, "spacing")?.inline?.dense?.leadingiconButtontextTraillingicon?.tokenName,
								property: "Spacing between icons and button-text",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.dense?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Typography",
								tokenName: getPropertyData(componentData, componentName, "font")?.uitext?.dense?.tokenName,
								property: "Font",
							},
						],
					},
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.fill?.hovered?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.fill?.hovered?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.fill?.pressedlarge?.tokenName,
								property: "BoxShadow",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.fill?.pressed?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.fill?.pressed?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.fill?.pressednormal?.tokenName,
								property: "BoxShadow",
							},
							
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.fill?.presseddense?.tokenName,
								property: "BoxShadow",
							},
							
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.fill?.disabled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.fill?.disabled?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.fill?.disabled?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.fill?.disabled?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			
		],
	},
	// IconOnly
	{
		type: "IconOnly",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icononly?.large?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.icononly?.large?.tokenName,
								property: "Border width",
							},
							{
								elements: "Border radius",
								tokenName: getPropertyData(componentData, componentName, "borderRadius")?.icononly?.full?.tokenName,
								property: "Border radius",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.fill?.primary?.tokenName,
								property: "Background - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.danger?.primary?.tokenName,
								property: "Background - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.default?.primary?.tokenName,
								property: "Background - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.ghost?.default?.tokenName,
								property: "Background - ghost",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.stroke?.primary?.tokenName,
								property: "Border color - outline",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.fill?.primary?.tokenName,
								property: "Border color - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.default?.primary?.tokenName,
								property: "Border color - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.danger?.primary?.tokenName,
								property: "Border color - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.fill?.primary?.tokenName,
								property: "Icon color - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.danger?.primary?.tokenName,
								property: "Icon color - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.default?.primary?.tokenName,
								property: "Icon color - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.outline?.primary?.tokenName,
								property: "Icon color - outline",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.ghost?.primary?.tokenName,
								property: "Icon color - ghost",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icononly?.normal?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.icononly?.normal?.tokenName,
								property: "Border width",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "Sizing",
								tokenName: getPropertyData(componentData, componentName, "sizing")?.icononly?.dense?.tokenName,
								property: "Size of the icon",
							},
							{
								elements: "Border width",
								tokenName: getPropertyData(componentData, componentName, "borderWidth")?.icononly?.dense?.tokenName,
								property: "Border width",
							},
						],
					},
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.fill?.hovered?.tokenName,
								property: "Background - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.danger?.hovered?.tokenName,
								property: "Background - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.default?.hovered?.tokenName,
								property: "Background - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.ghost?.hovered?.tokenName,
								property: "Background - ghost",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.stroke?.hovered?.tokenName,
								property: "Background - outline",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.stroke?.hovered?.tokenName,
								property: "Border color - outline",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.fill?.hovered?.tokenName,
								property: "Border color - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.default?.hovered?.tokenName,
								property: "Border color - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.danger?.hovered?.tokenName,
								property: "Border color - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.ghost?.hovered?.tokenName,
								property: "Border color - ghost",
							},
						],
					},
					
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.fill?.pressedlarge?.tokenName,
								property: "BoxShadow - fill button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.default?.pressedlarge?.tokenName,
								property: "BoxShadow - secondary button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.stroke?.pressedlarge?.tokenName,
								property: "BoxShadow - outline button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.danger?.pressedlarge?.tokenName,
								property: "BoxShadow - danger button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.ghost?.pressedlarge?.tokenName,
								property: "BoxShadow - ghost button",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.fill?.pressed?.tokenName,
								property: "Background - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.danger?.pressed?.tokenName,
								property: "Background - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.default?.pressed?.tokenName,
								property: "Background - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.ghost?.pressed?.tokenName,
								property: "Background - ghost",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.stroke?.pressed?.tokenName,
								property: "Background - outline",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.stroke?.pressed?.tokenName,
								property: "Border color - outline",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.fill?.pressed?.tokenName,
								property: "Border color - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.default?.pressed?.tokenName,
								property: "Border color - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.danger?.pressed?.tokenName,
								property: "Border color - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.ghost?.pressed?.tokenName,
								property: "Border color - ghost",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.fill?.pressednormal?.tokenName,
								property: "BoxShadow - fill button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.default?.pressednormal?.tokenName,
								property: "BoxShadow - secondary button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.stroke?.pressednormal?.tokenName,
								property: "BoxShadow - outline button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.danger?.pressednormal?.tokenName,
								property: "BoxShadow - danger button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.ghost?.pressednormal?.tokenName,
								property: "BoxShadow - ghost button",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.fill?.presseddense?.tokenName,
								property: "BoxShadow - fill button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.default?.presseddense?.tokenName,
								property: "BoxShadow - secondary button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.stroke?.presseddense?.tokenName,
								property: "BoxShadow - outline button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.danger?.presseddense?.tokenName,
								property: "BoxShadow - danger button",
							},
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.icononly?.ghost?.presseddense?.tokenName,
								property: "BoxShadow - ghost button",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.fill?.disabled?.tokenName,
								property: "Background - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.danger?.disabled?.tokenName,
								property: "Background - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.default?.disabled?.tokenName,
								property: "Background - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.icononly?.ghost?.disabled?.tokenName,
								property: "Background - ghost",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.stroke?.disabled?.tokenName,
								property: "Border color - outline",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.fill?.disabled?.tokenName,
								property: "Border color - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.default?.disabled?.tokenName,
								property: "Border color - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.icononly?.danger?.disabled?.tokenName,
								property: "Border color - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.fill?.disabled?.tokenName,
								property: "Icon color - fill",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.danger?.disabled?.tokenName,
								property: "Icon color - danger",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.default?.disabled?.tokenName,
								property: "Icon color - secondary",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.outline?.disabled?.tokenName,
								property: "Icon color - outline",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.icononly?.ghost?.disabled?.tokenName,
								property: "Icon color - ghost",
							},
						],
					},
					
				],
			},
			
		],
	},
	// Secondary
	{
		type: "Secondary",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.primary?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.primary?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.primary?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.primary?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.hovered?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.hovered?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.default?.pressedlarge?.tokenName,
								property: "BoxShadow",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.pressed?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.pressed?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.default?.pressednormal?.tokenName,
								property: "BoxShadow",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.default?.presseddense?.tokenName,
								property: "BoxShadow",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.default?.disabled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.default?.disabled?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.default?.disabled?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.default?.disabled?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			
		],
	},
	// Outline
	{
		type: "Outline",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.stroke?.primary?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.stroke?.primary?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.outline?.primary?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.stroke?.hovered?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.stroke?.hovered?.tokenName,
								property: "Border color",
							},

						],
					},
					
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.stroke?.pressedlarge?.tokenName,
								property: "BoxShadow",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.stroke?.pressed?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.stroke?.pressed?.tokenName,
								property: "Border color",
							},

						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.stroke?.pressednormal?.tokenName,
								property: "BoxShadow",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.stroke?.presseddense?.tokenName,
								property: "BoxShadow",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.stroke?.disabled?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.stroke?.disabled?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.outline?.disabled?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			
		],
	},
	// Danger
	{
		type: "Danger",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.danger?.primary?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.danger?.primary?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.danger?.primary?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.danger?.primary?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.danger?.hovered?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.danger?.hovered?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.danger?.pressedlarge?.tokenName,
								property: "BoxShadow",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.danger?.pressed?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.danger?.pressed?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.danger?.pressednormal?.tokenName,
								property: "BoxShadow",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.danger?.presseddense?.tokenName,
								property: "BoxShadow",
							},
						],
					},
				],
			},
			
			{
				state: "Disabled",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.danger?.disabled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.danger?.disabled?.tokenName,
								property: "Border color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.danger?.disabled?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.danger?.disabled?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			
		],
	},
	// Ghost
	{
		type: "Ghost",
		data: [
			{
				state: "Default",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.ghost?.default?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.ghost?.primary?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.ghost?.primary?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			{
				state: "Hovered",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.ghost?.hovered?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.ghost?.hovered?.tokenName,
								property: "Border color",
							},
						],
					},
					
				],
			},
			{
				state: "Pressed",
				data: [
					{
						size: "Large",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.ghost?.pressedlarge?.tokenName,
								property: "BoxShadow",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.ghost?.pressed?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "border")?.ghost?.pressed?.tokenName,
								property: "Border color",
							},
						],
					},
					{
						size: "Normal",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.ghost?.pressednormal?.tokenName,
								property: "BoxShadow",
							},
						],
					},
					{
						size: "Dense",
						data: [
							{
								elements: "BoxShadow",
								tokenName: getPropertyData(componentData, componentName, "boxShadow")?.ghost?.presseddense?.tokenName,
								property: "BoxShadow",
							},
						],
					},
				],
			},
			{
				state: "Disabled",
				data: [
					{
						size: "Normal",
						data: [
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "background")?.ghost?.disabled?.tokenName,
								property: "Background",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "text")?.ghost?.disabled?.tokenName,
								property: "text color",
							},
							{
								elements: "Color",
								tokenName: getPropertyData(componentData, componentName, "color", "icon")?.ghost?.disabled?.tokenName,
								property: "Icon color",
							},
						],
					},
					
				],
			},
			
		],
	},
	// {
	// 	type: "Fluid",
	// 	data: [
	// 		{
	// 			state: "Inactive",
	// 			data: [
	// 				{
	// 					size: "Normal",
	// 					data: [
							
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.inset?.normal?.tokenName,
	// 							property: "Spacing",
	// 						},
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.stack?.fieldHelpertext?.tokenName,
	// 							property: "Spacing between input and error-text",
	// 						},
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.inline?.validatioiconHelpertext?.tokenName,
	// 							property: "Spacing between validatioicon and helpertext",
	// 						},
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.normal?.inline?.sufixTraillingicon?.tokenName,
	// 							property: "Spacing between suffix and traillingicon",
	// 						},
	// 					],
	// 				},
	// 				{
	// 					size: "Dense",
	// 					data: [
							
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.inset?.dense?.tokenName,
	// 							property: "Spacing",
	// 						},
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.stack?.fieldHelpertext?.tokenName,
	// 							property: "Spacing between input and error-text",
	// 						},
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.stack?.labelField?.tokenName,
	// 							property: "Spacing between input and label",
	// 						},
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.inline?.leadingiconPrefixValue?.tokenName,
	// 							property: "Spacing between leadingicon and prefix-value",
	// 						},
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.inline?.validatioiconHelpertext?.tokenName,
	// 							property: "Spacing between validatioicon and helpertext",
	// 						},
	// 						{
	// 							elements: "Spacing",
	// 							tokenName: getPropertyData(componentData, componentName, "spacing")?.fluid?.dense?.inline?.suffixTraillingicon?.tokenName,
	// 							property: "Spacing between suffix and traillingicon",
	// 						},
	// 					],
	// 				},
	// 			],
	// 		},
			
	// 	],
	// },
	
];