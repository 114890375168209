import React, { useEffect, useState } from "react";
import CommonModal from "components/Common/CommonModal";

const ChangeDomainNameModal = ({ isOpen, onRequestClose, onApply, title, data }) => {
	const [name, setName] = useState("");

	useEffect(() => {
		setName(data?.domain?.domain_name);
	}, [data]);

	const handleApply = async () => {
		if (name) {
			onApply(data?.domain?.id, name);
			onRequestClose();
		}
	};

	return (
		<CommonModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={title}
			onApply={handleApply}
			classPrefix="plt"
			onCancelText="Cancel"
			onApplyText="Save"
			// bodyClassName='overflow-unset'
		>
			<div className="plt-modal-body-content">
				<div className="plt-select-dropdown-wrapper dis-pl-24 dis-pr-24">
					<h6 className="plt-select-dropdown-title plt-ui-text-sm-medium dis-mb-4">URL</h6>

					<div className="modal-value-block w-100 d-flex">
						<input
							className="input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary"
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<input
							className="design-input input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary dis-ml-12"
							type="text"
							readonly
							defaultValue=".dessign.systems"
						/>
					</div>
				</div>
			</div>
		</CommonModal>
	);
};

export default ChangeDomainNameModal;
