import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import { getComponentsSideData } from "assets/data/sideTabData";
import "assets/scss/pages/_DesignSystemComponents.scss";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";

import PreviewToggleComponent from "components/DesignSystemPage/ToggleComponent/PreviewToggleComponent";
import UsageToogleComponent from "components/DesignSystemPage/ToggleComponent/UsageToogleComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";
import { toggleTokenTabData } from "assets/data/tokenTabData";

const TogglesComponents = () => {
  const { id } = useParams();
  const { designSystemMeta, designSystem } = useGlobalContext();
  
    const componentName = "toggle";
      const componentKey = `Component/${componentName}`;
      const componentData = designSystem[componentKey];
      const tokenTableData = toggleTokenTabData(componentData, componentName);

  const tabData = [
    "Default",
    "Toggle button with label",

  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const bannerData = {
    heading: "Toggle",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        // return <div>content1</div>;
        return (
          <PreviewToggleComponent
            tabData={tabData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setSelectedTab={setSelectedTab}
          />
        );
      case 1:
        return (
          <div>
            <UsageToogleComponent/>
          </div>
        );
      case 2:
        return <TokenTableComponent tokenTableData={tokenTableData} />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title='Components'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default TogglesComponents;
