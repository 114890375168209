// export const ComponentPrimaryDatas = [
//   // {
//   //   id: 1,
//   //   title: "Extra large",
//   //   description:
//   //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//   //   className: "dss-btn dss-btn-primary dss-btn-xl dss-ui-text-xl-semi-bold",
//   // },
//   {
//     id: 2,
//     title: "Large",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 3,
//     title: "Normal",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // className: "dss-btn dss-btn-primary dss-btn-md",
//   },
//   {
//     id: 4,
//     title: "Dense",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // className: "dss-btn dss-btn-primary dss-btn-sm",
//   },
//   // {
//   //   id: 5,
//   //   title: "Denser",
//   //   description:
//   //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//   //   className: "dss-btn dss-btn-primary dss-btn-xs dss-ui-text-xs-semi-bold",
//   // },
//   {
//     id: 6,
//     title: "Disabled",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // className: "dss-btn dss-btn-primary disabled",
//   },
// ];

export const ComponentPrimaryDatas = [
  // {
  //   id: 1,
  //   title: "Extra large",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-primary dss-btn-xl dss-ui-text-xl-semi-bold",
  // variant: "primary"
  // },
  {
    id: 2,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "primary",
    size: "lg",
  },
  {
    id: 3,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "primary",
    size: "md",
  },
  {
    id: 4,
    title: "Dense",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "primary",
    size: "sm",
  },
  // {
  //   id: 5,
  //   title: "Denser",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-primary dss-btn-xs dss-ui-text-xs-semi-bold",
  // variant: "primary",
  // size: "xs",
  // },
  {
    id: 6,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "primary",
    size: "md",
    disabled: true,
  },
];

export const ComponentFluidDatas = [
  {
    id: 1,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "primary",
    fluid: true,
  },
  {
    id: 2,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "primary",
    disabled: true,
    fluid: true,
  },
];

export const ComponentSecondaryDatas = [
  // {
  //   id: 1,
  //   title: "Extra large",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-secondary dss-btn-xl dss-ui-text-xl-semi-bold",
  // },
  {
    id: 2,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // className: "dss-btn dss-btn-secondary dss-btn-lg",
    variant: "secondary",
    size: "lg",
  },
  {
    id: 3,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "secondary",
    size: "md",
  },
  {
    id: 4,
    title: "Dense",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "secondary",
    size: "sm",
  },
  // {
  //   id: 5,
  //   title: "Denser",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-secondary dss-btn-xs dss-ui-text-xs-semi-bold",
  // },
  {
    id: 6,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "secondary",
    disabled: true,
  },
];

export const ComponentOutlineDatas = [
  // {
  //   id: 1,
  //   title: "Extra large",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-outlined dss-btn-xl dss-ui-text-xl-semi-bold",
  // },
  {
    id: 2,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "outlined",
    size: "lg",
  },
  {
    id: 3,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "outlined",
    size: "md",
  },
  {
    id: 4,
    title: "Dense",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "outlined",
    size: "sm",
  },
  {
    id: 5,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "outlined",
    disabled: true,
  },
];

export const ComponentDangerDatas = [
  // {
  //   id: 1,
  //   title: "Extra large",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-danger dss-btn-xl dss-ui-text-xl-semi-bold",
  // },
  {
    id: 2,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "danger",
    size: "lg",
  },
  {
    id: 3,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "danger",
    size: "md",
  },
  {
    id: 4,
    title: "Dense",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "danger",
    size: "sm",
  },
  // {
  //   id: 5,
  //   title: "Denser",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-danger dss-btn-xs dss-ui-text-xs-semi-bold",
  // },
  {
    id: 6,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "danger",
    disabled: true,
  },
];

export const ComponentGostDatas = [
  // {
  //   id: 1,
  //   title: "Extra large",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-ghost dss-btn-xl dss-ui-text-xl-semi-bold",
  // },
  {
    id: 2,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "ghost",
    size: "lg",
  },
  {
    id: 3,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "ghost",
    size: "md",
  },
  {
    id: 4,
    title: "Dense",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "ghost",
    size: "sm",
  },
  // {
  //   id: 5,
  //   title: "Denser",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-ghost dss-btn-xs dss-ui-text-xs-semi-bold",
  // },
  {
    id: 6,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "ghost",
    disabled: true,
  },
];

export const ComponentLinkDatas = [
  // {
  //   id: 1,
  //   title: "Extra large",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-link dss-btn-xl dss-ui-text-xl-semi-bold",
  // },
  {
    id: 2,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "link",
    size: "lg",
  },
  {
    id: 3,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "link",
    size: "md",
  },
  {
    id: 4,
    title: "Dense",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "link",
    size: "sm",
  },
  // {
  //   id: 5,
  //   title: "Denser",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   className: "dss-btn dss-btn-link dss-btn-xs dss-ui-text-xs-semi-bold",
  // },
  {
    id: 6,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    variant: "link",
    disabled: true,
  },
];

export const ComponentBtnDatas = [
  {
    id: 1,
    title: "Icon before",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: false,
    variant: "secondary",
    size: "lg",
    icon: true,
  },
  {
    id: 2,
    title: "Icon after",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: false,
    suffixIcon: true,
    variant: "secondary",
    size: "lg",
    icon: true,
  },
  {
    id: 3,
    title: "Icon before-after",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: true,
    variant: "secondary",
    size: "lg",
    icon: true,
  },
  {
    id: 4,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: true,
    variant: "secondary",
    disabled: true,
    icon: true,
  },
];

export const FillBtnDatas = [
  {
    id: 1,
    title: "large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: false,
    variant: "primary",
    size: "lg",
    icon: true,
  },
  {
    id: 2,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: true,
    variant: "primary",
    size: "md",
    icon: true,
  },
  {
    id: 3,
    title: "Dense",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: false,
    suffixIcon: true,
    variant: "primary",
    size: "sm",
    icon: true,
  },
  {
    id: 4,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: true,
    variant: "primary",
    disabled: true,
    icon: true,
  },
];

export const FillIconBtnDatas = (variant) => [
  {
    id: 1,
    title: "large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: false,
    variant: variant,
    size: "lg",
    iconOnly: true,
    text: "",
    icon: true,
  },
  {
    id: 2,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: false,
    variant: variant,
    size: "md",
    iconOnly: true,
    text: "",
    icon: true,
  },
  {
    id: 3,
    title: "Dense",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: false,
    variant: variant,
    size: "sm",
    iconOnly: true,
    text: "",
    icon: true,
  },
  {
    id: 4,
    title: "Disabled",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    suffixIcon: false,
    variant: variant,
    disabled: true,
    iconOnly: true,
    text: "",
    icon: true,
  },
];

export const ComponentUsagePrimaryDatas = [
  {
    id: 1,
    title: "Primary",
    description: "Most prominent and visually emphasized button.",
    sub_title_1: "Default",
    sub_title_2: "Hover",
    sub_title_3: "Pressed",
    sub_title_4: "Disabled",
    classname: "d-none",
    detail: "",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Primary actions, such as submitting a form, completing a purchase, or
          initiating a critical user journey.
        </li>
        <li className="plt-body-md plt-font-color-primary">
          Limited to one or a few primary buttons on a page to maintain focus.
        </li>
      </ul>
    ),
  },
  {
    id: 2,
    title: "Secondary",
    description: "Most prominent and visually emphasized button.",
    sub_title_1: "Default",
    sub_title_2: "Hover",
    sub_title_3: "Pressed",
    sub_title_4: "Disabled",
    classname: "d-block",
    detail:
      "Less visually dominant than the primary button. It is used to represent actions that are important but not as critical.",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Secondary actions, like canceling an operation, navigating to less
          critical sections, or providing alternative options.
        </li>
      </ul>
    ),
  },
  {
    id: 3,
    title: "Outline",
    description: "Most prominent and visually emphasized button.",
    sub_title_1: "Default",
    sub_title_2: "Hover",
    sub_title_3: "Pressed",
    sub_title_4: "Disabled",
    classname: "d-block",
    detail:
      "Less visually dominant than the primary button. It is used to represent actions that are important but not as critical.",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Secondary actions, like canceling an operation, navigating to less
          critical sections, or providing alternative options.
        </li>
      </ul>
    ),
  },
  {
    id: 4,
    title: "Danger",
    description: "Most prominent and visually emphasized button.",
    sub_title_1: "Default",
    sub_title_2: "Hover",
    sub_title_3: "Pressed",
    sub_title_4: "Disabled",
    classname: "d-block",
    detail:
      "Less visually dominant than the primary button. It is used to represent actions that are important but not as critical.",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Secondary actions, like canceling an operation, navigating to less
          critical sections, or providing alternative options.
        </li>
      </ul>
    ),
  },
  {
    id: 5,
    title: "Ghost",
    description: "Most prominent and visually emphasized button.",
    sub_title_1: "Default",
    sub_title_2: "Hover",
    sub_title_3: "Pressed",
    sub_title_4: "Disabled",
    classname: "d-block",
    detail:
      "Less visually dominant than the primary button. It is used to represent actions that are important but not as critical.",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Secondary actions, like canceling an operation, navigating to less
          critical sections, or providing alternative options.
        </li>
      </ul>
    ),
  },
  {
    id: 6,
    title: "Link",
    description: "Most prominent and visually emphasized button.",
    sub_title_1: "Default",
    sub_title_2: "Hover",
    sub_title_3: "Pressed",
    sub_title_4: "Disabled",
    classname: "d-block",
    detail:
      "Less visually dominant than the primary button. It is used to represent actions that are important but not as critical.",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Secondary actions, like canceling an operation, navigating to less
          critical sections, or providing alternative options.
        </li>
      </ul>
    ),
  },
];

export const InputComponentUsagePrimaryDatas = [
  {
    id: 1,
    sub_title_1: "Default",
    sub_title_2: "Focused",
    sub_title_3: "Filled",
    sub_title_4: "Disabled",
    sub_title_5: "Read only",
    classname: "d-none",
    detail: "",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Primary actions like entering credentials, searching content, or
          providing key information.
        </li>
        <li className="plt-body-md plt-font-color-primary">
          Typically limited to a few fields per form to ensure clarity and ease
          of use.
        </li>
      </ul>
    ),
  },
];

export const TextareaComponentUsagePrimaryDatas = [
  {
    id: 1,
    sub_title_1: "Default",
    sub_title_2: "Focused",
    sub_title_3: "Filled",
    sub_title_4: "Disabled",
    sub_title_5: "Read only",
    classname: "d-none",
    detail: "",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Primary actions like inputting credentials, searching, or providing
          key information.
        </li>
        <li className="plt-body-md plt-font-color-primary">
          Usually, only a few fields per form are included for clarity and
          simplicity.
        </li>
      </ul>
    ),
  },
];

export const TooltipComponentUsagePrimaryDatas = [
  {
    id: 1,
    sub_title_1: "Short",
    sub_title_2: "Truncate",
    sub_title_3: "Wrap",
    classname: "d-none",
    detail: "",
  },
];

export const AlertUsagePrimaryDatas = [
  {
    id: 1,
    sub_title_1: "Informative",
    sub_title_2: "Caution",
    sub_title_3: "Success",
    sub_title_4: "Danger",
    classname: "d-none",
    detail: "",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Primary actions like entering credentials, searching content, or
          providing key information.
        </li>
        <li className="plt-body-md plt-font-color-primary">
          Typically limited to a few fields per form to ensure clarity and ease
          of use.
        </li>
      </ul>
    ),
  },
];

export const AvatarUsagePrimaryDatas = [
  {
    id: 1,
    sub_title_1: "Default",
    sub_title_2: "Silhouette",
    sub_title_3: "Text",
    classname: "d-none",
    detail: "",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Primary actions like entering credentials, searching content, or
          providing key information.
        </li>
        <li className="plt-body-md plt-font-color-primary">
          Typically limited to a few fields per form to ensure clarity and ease
          of use.
        </li>
      </ul>
    ),
  },
];

export const TabsUsagePrimaryDatas = [
  {
    id: 1,
    title: "Default",
    description:
      "A standalone tab that can also be nested within components. It is commonly used within components or for content using the entire page for layout, not connected to any other components.",
    sub_title_1: "Normal",
    sub_title_2: "Normal with leading icon",
    sub_title_3: "Normal with tailing icon",
    sub_title_4: "Normal with badge",
    sub_title_5: "Normal with leading icon and badge",
  },
];

export const TabsUsagePillDatas = [
  {
    id: 1,
    title: "Pill",
    description:
      "An emphasized tab that is commonly used for defined content areas. ",
    sub_title_1: "Normal",
    sub_title_2: "Normal with leading icon",
    sub_title_3: "Normal with tailing icon",
    sub_title_4: "Normal with badge",
    sub_title_5: "Normal with leading icon and badge",
  },
];

export const TabsUsageSecondaryDatas = [
  {
    id: 1,
    title: "Default",
    description:
      "A standalone tab that can also be nested within components. It is commonly used within components or for content using the entire page for layout, not connected to any other components.",
    sub_title_1: "Default",
    sub_title_2: "Hovered",
    sub_title_3: "Disabled",
  },
];

export const TabsUsageSecondaryPillDatas = [
  {
    id: 1,
    title: "Pill",
    description:
      "An emphasized tab that is commonly used for defined content areas. ",
    sub_title_1: "Default",
    sub_title_2: "Hovered",
    sub_title_3: "Disabled",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          The tab component includes selected and unselected states, with one
          tab always selected by default.
        </li>
        <li className="plt-body-md plt-font-color-primary">
          Non-dismissible groups require at least two tabs, while dismissible
          groups need at least one. Icons are optional.
        </li>
      </ul>
    ),
  },
];

export const TagUsagePrimaryDatas = [
  {
    id: 1,
    sub_title_1: "Default",
    sub_title_2: "Hovered",
    sub_title_3: "Pressed",
    sub_title_4: "Disabled",
    classname: "d-none",
    detail: "",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Primary actions like entering credentials, searching content, or
          providing key information.
        </li>
        <li className="plt-body-md plt-font-color-primary">
          Typically limited to a few fields per form to ensure clarity and ease
          of use.
        </li>
      </ul>
    ),
  },
];

export const ToogleComponentUsagePrimaryDatas = [
  {
    id: 1,
    sub_title_1: "Default",
    sub_title_2: "Focused",
    sub_title_3: "Pressed",
    sub_title_4: "Disabled",
    classname: "d-none",
    detail: "",
    detail_list: (
      <ul className="plt-detail-list dis-mt-12">
        <li className="plt-body-md plt-font-color-primary">
          Primary actions like entering credentials, searching content, or
          providing key information.
        </li>
        <li className="plt-body-md plt-font-color-primary">
          Typically limited to a few fields per form to ensure clarity and ease
          of use.
        </li>
      </ul>
    ),
  },
];

// export const TokenButtonDatas = [
//   {
//     id: 1,
//     title: "Primary button",
//   },
//   {
//     id: 2,
//     title: "Secondary button",
//   },
//   {
//     id: 3,
//     title: "Outline button",
//   },
//   {
//     id: 4,
//     title: "Danger button",
//   },
//   {
//     id: 5,
//     title: "Ghost button",
//   },
//   {
//     id: 6,
//     title: "Fluid width button",
//   },
// ];

// export const buttonTableData = [
//   {
//     elements: "Text/Label",
//     tokenName: "text-button-fill-primary",
//     property: "Text color",
//   },
//   {
//     elements: "",
//     tokenName: "font-family-secondary",
//     property: "Font family",
//   },
//   {
//     elements: "",
//     tokenName: "font-weight-500",
//     property: "Font weight",
//   },
//   {
//     elements: "",
//     tokenName: "font-size",
//     property: "Font-size",
//   },
//   {
//     elements: "",
//     tokenName: "line-height-120",
//     property: "Line height",
//   },
//   {
//     elements: "",
//     tokenName: "font-letterspacing-normal",
//     property: "Letter spacing",
//   },
//   {
//     elements: "",
//     tokenName: "text-case-none",
//     property: "Text case",
//   },
//   {
//     elements: "Border radius",
//     tokenName: "border-radius-200",
//     property: "Border radius",
//   },
//   {
//     elements: "Spacing",
//     tokenName: "space-buttonlarge",
//     property: "Spacing between the container",
//   },
//   {
//     elements: "Item spacing",
//     tokenName: "space-200",
//     property: "Spacing between the icon",
//   },
//   {
//     elements: "Fill",
//     tokenName: "background-button-fill-hovered",
//     property: "Button/ container color",
//   },
// ];

// export const RadioComponentPrimaryDatas = [
//   {
//     id: 1,
//     title: "Normal radio button",
//     desc: "Radio button that typically appears in in the design, in its unselected state. They allow the user to select only one option at a time.",
//   },
//   {
//     id: 2,
//     title: "Normal radio button with label",
//     desc: "Radio button that typically appears in in the design, in its unselected state. They allow the user to select only one option at a time.",
//   },
//   {
//     id: 3,
//     title: "Small radio button",
//     desc: "Radio button that typically appears in in the design, in its unselected state. They allow the user to select only one option at a time.",
//   },
//   {
//     id: 4,
//     title: "Small radio button with label",
//     desc: "Radio button that typically appears in in the design, in its unselected state. They allow the user to select only one option at a time.",
//   },
// ];

export const CheckboxComponentPrimaryDatas = [
  // {
  //   id: 1,
  //   title: "Large",
  //   description:
  //     "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
  //   size: "large",
  //    typo: "body-compact-lg"
  // },
  {
    id: 1,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    size: "normal",
    checked: true,
  },
  {
    id: 2,
    title: "Small",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    size: "small",
    checked: true,
  },
];

export const ToggleComponentPrimaryDatas = [
  {
    id: 1,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    size: "large",
    token: "large",
    disabled: false,
    // variant: false,
  },
  {
    id: 2,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    size: "normal",
    token: "normal",
    disabled: false,
    // variant: false,
  },
  {
    id: 3,
    title: "Disable",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    size: "normal",
    token: "normal",
    disabled: true,
    // variant: false,
  },
];

export const TooltipComponentPrimaryDatas = [
  {
    id: 1,
    title: "No Arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "top",
    arrow: "arrow-none",
  },
  {
    id: 2,
    title: "Bottom left arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "bottom",
    arrow: "btm-lft",
  },
  {
    id: 3,
    title: "Bottom center arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "bottom",
    arrow: "btm-center",
  },
  {
    id: 4,
    title: "Bottom right arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "bottom",
    arrow: "btm-rgt",
  },
  {
    id: 5,
    title: "Top left arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "top",
    arrow: "top-lft",
  },
  {
    id: 6,
    title: "Top center arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "top",
    arrow: "top-center",
  },
  {
    id: 7,
    title: "Top right arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "top",
    arrow: "top-rgt",
  },
  {
    id: 8,
    title: "Left top arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "left",
    arrow: "lft-top",
  },
  {
    id: 9,
    title: "Left center arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "left",
    arrow: "lft-center",
  },
  {
    id: 10,
    title: "Left bottom arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "left",
    arrow: "lft-btm",
  },
  {
    id: 11,
    title: "Right top arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "right",
    arrow: "rgt-top",
  },
  {
    id: 12,
    title: "Right center arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "right",
    arrow: "rgt-center",
  },
  {
    id: 13,
    title: "Right bottom arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "right",
    arrow: "rgt-btm",
  },
];

export const TooltipComponenLargeDatas = [
  {
    id: 1,
    title: "No Arrow",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    position: "bottom",
    arrow: "arrow-none",
  },
];

// export const ModalComponentPrimaryDatas = [
//   {
//     id: 1,
//     title: "Header main component",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     size: "large",
//     variant: "default",
//   },
//   {
//     id: 2,
//     title: "Body main component",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     size: "normal",
//     variant: "default",
//   },
//   {
//     id: 3,
//     title: "Footer",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     size: "disable",
//     variant: "disable",
//   },
// ];

export const TableComponentPrimaryDatas = [
  {
    id: 1,
    title: "Large",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    size: "large",
  },
];

export const AvatarDatas = [
  {
    id: 1,
    title: "Normal",
    description: "People are indicated with an image in a circle.",
    state: undefined,
  },
  {
    id: 2,
    title: "Normal with status",
    description:
      "When the image source is unavailable (for example, when there's a problem displaying the image due to an error), or the source is unspecified, the avatar component will display a default image.",
    state: "status",
  },
  {
    id: 3,
    title: "Normal with Presence",
    description:
      "When the image source is unavailable (for example, when there's a problem displaying the image due to an error), or the source is unspecified, the avatar component will display relevant text.",
    state: "presence",
  },
];

export const AvatarGroupDatas = [
  {
    id: 1,
    title: "Large",
    description: "People are indicated with an image in a circle.",
    data: [
      { key: 1, type: "image" },
      { key: 2, type: "image" },
      { key: 3, type: "image" },
      { key: 4, type: "image" },
      { key: 5, type: "image" },
      { key: 6, type: "image" },
      { key: 7, type: "image" },
    ],
    size: "lg",
  },
  {
    id: 2,
    title: "Normal",
    description:
      "When the image source is unavailable (for example, when there's a problem displaying the image due to an error), or the source is unspecified, the avatar component will display a default image.",
    data: [
      { key: 1, type: "image" },
      { key: 2, type: "image" },
      { key: 3, type: "image" },
      { key: 4, type: "image" },
      { key: 5, type: "image" },
      { key: 6, type: "image" },
      { key: 7, type: "image" },
    ],
    size: "md",
  },
  {
    id: 3,
    title: "Small",
    description:
      "When the image source is unavailable (for example, when there's a problem displaying the image due to an error), or the source is unspecified, the avatar component will display a default image.",
    data: [
      { key: 1, type: "image" },
      { key: 2, type: "image" },
      { key: 3, type: "image" },
      { key: 4, type: "image" },
      { key: 5, type: "image" },
      { key: 6, type: "image" },
      { key: 7, type: "image" },
    ],
    size: "sm",
  },
  // {
  //   id: 3,
  //   title: "Normal With Presence",
  //   description:
  //     "When the image source is unavailable (for example, when there's a problem displaying the image due to an error), or the source is unspecified, the avatar component will display relevant text.",
  //   data: [
  //     { key: 1, type: "image" },
  //     { key: 2, type: "image" },
  //     { key: 3, type: "image" },
  //     { key: 4, type: "image" },
  //     { key: 5, type: "image" },
  //     { key: 6, type: "image" },
  //     { key: 7, type: "image" },
  //   ],
  // },
];

export const RadiusCharacterDatas = [
  {
    id: 1,
    // classStatus: "active",
    radiusType: "radius-small",
    name: "Small",
    content: "The elevation of a component in its resting/default state.",
    state: "radius-sm",
    rem: "0.125rem",
    value: "6px",
    key: "core.borderRadius.150",
  },
  {
    id: 2,
    key: "core.borderRadius.400",

    // classStatus: "",
    radiusType: "radius-medium",
    name: "Medium",
    content: "The elevation of a component in its resting/default state.",
    state: "radius-md",
    rem: "0.125rem",
    value: "16px",
  },
  {
    id: 3,
    key: "core.borderRadius.700",

    // classStatus: "",
    radiusType: "radius-large",
    name: "Large",
    content: "The elevation of a component in its resting/default state.",
    state: "radius-lg",
    rem: "0.125rem",
    value: "28px",
  },
  {
    key: "core.borderRadius.full",

    id: 4,
    // classStatus: "",
    radiusType: "radius-full",
    name: "Full",
    content: "The elevation of a component in its resting/default state.",
    state: "radius-full",
    rem: "0.125rem",
    value: "9999px",
  },
];

export const TabsData = [
  {
    id: 1,
    title: "Normal",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
    // className: "dss-btn dss-btn-primary dss-btn-xl",
  },
  {
    id: 2,
    title: "Normal With Leading Icon",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
  },
  {
    id: 3,
    title: "Normal With Tailing Icon",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
  },
  {
    id: 4,
    title: "Normal With Badge",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
  },
  {
    id: 5,
    title: "Normal With Leading Icon And Badge",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
  },
  {
    id: 6,
    title: "Normal With Badge And Tailing Icon",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
  },
  {
    id: 7,
    title: "Normal With Leading Icon, Badge And Tailing Icon",
    description:
      "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
  },
];

export const TypoCharacterDatas = [
  {
    id: 1,
    status: "Do",
    statusClass: "badgePrimary",
    content1:
      "Discover best practices for button design, including sizing, spacing, typography, and states like hover and active.",
    content2:
      "These guidelines help you maintain a consistent and delightful button experience throughout.",
  },
  {
    id: 2,
    status: "Don’t",
    statusClass: "badgeNegative",
    content1:
      "Typography, and states like hover and active. These guidelines help you maintain",
    content2: "a consistent and delightful button experience throughout.",
  },
  {
    id: 3,
    status: "Do",
    statusClass: "badgePrimary",
    content1:
      "Discover best practices for button design, including sizing, spacing, typography, and states like hover and active.",
    content2:
      "These guidelines help you maintain a consistent and delightful button experience throughout.",
  },
  {
    id: 4,
    status: "Don’t",
    statusClass: "badgeNegative",
    content1:
      "Typography, and states like hover and active. These guidelines help you maintain",
    content2: "a consistent and delightful button experience throughout.",
  },
];

export const ElevationUsageDatas = [
  {
    id: 1,
    status: "Do",
    statusClass: "badgePrimary",
    content:
      "Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate user actions and engagement.",
  },
  {
    id: 2,
    status: "Don’t",
    statusClass: "badgeNegative",
    content:
      "Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate user actions and engagement.",
  },
];

export const RadiusUsageDatas = [
  {
    id: 1,
    status: "Do",
    statusClass: "badgePrimary",
    content:
      "Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate user actions and engagement.",
  },
  {
    id: 2,
    status: "Don’t",
    statusClass: "badgeNegative",
    content:
      "Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate user actions and engagement.",
  },
];

// export const ElevationCharacterDatas = [
//   {
//     id: 1,
//     shadowType: "shadow-0",
//     name: "Default",
//     content: "The elevation of a component in its resting/default state.",
//     state: "elevation-default",
//     xValue: 0,
//     yValue: 4,
//     blurValue: 16,
//   },
//   {
//     id: 2,
//     shadowType: "shadow-100",
//     name: "Raised",
//     content: "The elevation of a component in its resting/default state.",
//     state: "elevation-default",
//     xValue: 0,
//     yValue: 4,
//     blurValue: 16,
//   },
//   {
//     id: 3,
//     shadowType: "shadow-300",
//     name: "Hover/ Focus",
//     content: "The elevation of a component in its resting/default state.",
//     state: "elevation-default",
//     xValue: 0,
//     yValue: 4,
//     blurValue: 16,
//   },
//   {
//     id: 4,
//     shadowType: "shadow-600",
//     name: "Overlays",
//     content: "The elevation of a component in its resting/default state.",
//     state: "elevation-default",
//     xValue: 0,
//     yValue: 4,
//     blurValue: 16,
//   },
//   {
//     id: 5,
//     shadowType: "shadow-fill",
//     name: "Pressed",
//     content: "The elevation of a component in its resting/default state.",
//     state: "elevation-default",
//     xValue: 0,
//     yValue: 4,
//     blurValue: 16,
//   },
// ];

// export const ToasterPrimaryDatas = [
//   {
//     id: 1,
//     title: "Normal",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 2,
//     title: "Normal With Leading Icon",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 3,
//     title: "Normal With Closable Icon",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 4,
//     title: "Normal With Leading And Closable Icon",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 5,
//     title: "Normal With Actions",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 6,
//     title: "Normal With Actions And Leading Icon",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 7,
//     title: "Normal With Actions And Closable Icon",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 8,
//     title: "Normal With Actions, Leading And Closable Icon",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 9,
//     title: "Normal With Wrapped Actions",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 10,
//     title: "Normal With Leading Icon And Wrapped Actions",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 11,
//     title: "Normal With Wrapped Actions And Closable Icon",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
//   {
//     id: 12,
//     title: "Normal With Leading Icon, Wrapped Actions And Closable Icon",
//     description:
//       "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
//     // code: <button class="dds__button dds__button--primary" type="button">Primary Button</button>,
//     className: "dss-btn dss-btn-primary dss-btn-lg",
//   },
// ];

export const TagComponentPrimaryDatas = [
  {
    id: 1,
    title: "Label Only",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    // size: "large",
    // prefixIcon: true,
    // counter: false,
    // mode: "counter",
    prefixIcon: false,
    statusIcon: false,
    disable: false,
    mode: "",
    varient: "label",
  },
  {
    id: 2,
    title: "Label With Counter",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: false,
    statusIcon: false,
    disable: false,
    mode: "counter",
    varient: "label",
  },
  {
    id: 3,
    title: "Label With Leading Icon",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    statusIcon: false,
    disable: false,
    mode: "",
    varient: "label",
  },
  {
    id: 3,
    title: "Label With Counter And Leading Icon",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    statusIcon: false,
    disable: false,
    mode: "counter",
    varient: "label",
  },
  {
    id: 4,
    title: "Label With Staus Icon",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: false,
    statusIcon: true,
    disable: false,
    mode: "",
    varient: "labelStatusicon",
  },
  {
    id: 5,
    title: "Label With Counter And Staus Icon",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: false,
    statusIcon: true,
    disable: false,
    mode: "counter",
    varient: "labelStatusicon",
  },
  {
    id: 6,
    title: "Removable Tag",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: false,
    disable: false,
    mode: "closable",
    varient: "labelCloseicon",
  },
  {
    id: 4,
    title: "Removable Tag With Leading Icon",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    statusIcon: false,
    disable: false,
    mode: "closable",
    varient: "labelCloseicon",
  },
  {
    id: 5,
    title: "Disabled",
    desc: "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
    prefixIcon: true,
    statusIcon: false,
    disable: true,
    mode: "closable",
    varient: "labelCloseicon",
  },
];
