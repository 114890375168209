import React from "react";
import "./AvatarComponent.scss";
import AvatarImage from "../../../assets/images/png/avatar.png";
import { TickIconWhiteSmall, UserIcon } from "../../Icons";

const AvatarComponent = ({
  content,
  image,
  text = "AA",
  state,
  size,
  varient="dafault",
  avatarGroup,
  avatarData,
}) => {
  const renderAvatar = (type, text, image) => {
    switch (type) {
      case "text":
        return <div className="avatar-text">{text}</div>;
      case "icon":
        return <UserIcon />;
      default:
        return (
          <img
            src={image || AvatarImage}
            alt="avatar"
            className="avatar-image"
          />
        );
    }
  };

  const avatarClass = `avatar avatar-circle ${size ? "avatar-" + size : ""} ${varient ? varient : ""}`;

  return (
    <div className={avatarGroup ? "avatar-group" : "avatar-badge"}>
      {avatarGroup ? (
        <>
          {avatarData.length > 4 && (
            <div className={`${avatarClass} avatar-group-count`}>
              {avatarData.length - 4}+
            </div>
          )}
          {avatarData.slice(0, 4).map(({ key, type, text, image }) => (
            <div key={key} className={avatarClass}>
              {renderAvatar(type, text, image)}
            </div>
          ))}
        </>
      ) : (
        <div className={avatarClass}>{renderAvatar(content, text, image)}</div>
      )}
      {state === "status" && !avatarGroup && (
        <span className="avatar-status"><TickIconWhiteSmall/></span>
      )}
      {state === "presence" && !avatarGroup && (
        <span className="avatar-active"></span>
      )}
    </div>
  );
};

export default AvatarComponent;
