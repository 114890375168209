import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DesignSystemLayout from 'components/DesignSystemPage/DesignSystemLayout';
import { getStylesSideTabData } from 'assets/data/sideTabData';
import ElevationStyles from 'components/DesignSystemPage/Elevation/ElevationStyles';
import ElevationUsage from 'components/DesignSystemPage/Elevation/ElevationUsage';
import { useGlobalContext } from 'context/GlobalContext';
import { getValueByPath } from 'helpers/token';

const DesignSystemElevation = () => {
	const { id } = useParams();

	const [selectedTab, setSelectedTab] = useState(0);
	const [elevationDatas, setElevationDatas] = useState([]);

	const { designSystem, pusherIsLoading, designSystemMeta } = useGlobalContext();

	const elevationTypes = [
		{ key: 'core.boxShadow.0', name: 'Default' },
		{ key: 'core.boxShadow.100', name: 'Raised' },
		{ key: 'core.boxShadow.300', name: 'Hover/Focus' },
		{ key: 'core.boxShadow.600', name: 'Overlays' },
		{ key: 'core.boxShadow.fill.pressedNormal', name: 'Pressed' },
	];

	const getColorValue = (colorKey) => {
		const key = colorKey?.replace(/[{}]/g, '');
		const color = getValueByPath(designSystem, 'core.' + key);
		return color?.value;
	};

	useEffect(() => {
		const computedElevationDatas = elevationTypes.map((elev) => {
			let data = getValueByPath(designSystem, elev.key);
			data = {
				...data,
				name: elev?.name,
			};
			let colorValue = getColorValue(data?.value?.color);
			data = { ...data, colorValue };

			return data;
		});

		setElevationDatas(computedElevationDatas);
	}, [designSystem, pusherIsLoading]);

	const handleHeaderTabClick = (id) => {
		setSelectedTab(id);
	};

	const bannerData = {
		heading: 'Elevation',
		description: 'Add, replace, generate colors which makes your design looks awesome!',
		bannerImage: designSystemMeta.banner_url,
	};

	const tabs = ['Style', 'Usage'];
	return (
		<DesignSystemLayout
			selectedTab={selectedTab}
			setSelectedTab={handleHeaderTabClick}
			bannerData={bannerData}
			tabs={tabs}
			sideTabs={getStylesSideTabData(id)}
			title="Foundation"
		>
			<div className="tab-content-wrapper d-flex justify-content-between">
				{selectedTab === 0 && <ElevationStyles key={JSON.stringify(elevationDatas)} datas={elevationDatas} />}
				{selectedTab === 1 && <ElevationUsage />}
			</div>
		</DesignSystemLayout>
	);
};

export default DesignSystemElevation;
