import React, { useEffect, useState } from "react";

import { useGlobalContext } from "context/GlobalContext";
import { replaceTokenValue } from "helpers/token";

const ValueColumn = ({ rowData }) => {
  const { designSystem } = useGlobalContext();
  const [value, setValue] = useState();
  useEffect(() => {
    setValue(replaceTokenValue(designSystem, rowData.value));
  }, [rowData.value]);

  return <span className='plt-color-text plt-body-compact-sm'>{value}</span>;
};
export default ValueColumn;
