import React, { useState } from "react";
import "./TypoUsage.scss";
import { TypoCharacterDatas } from "../../../../assets/data/data";
import EditButton from "../../../Buttons/EditButton";
import UsageCard from "../../../Common/UsageCard";
import DeleteButton from "../../../Buttons/DeleteButton";
import AddButton from "../../../Buttons/AddButton";
import useScrollManagement from "../../../../hooks/useScrollManagement";
import TabNavigation from "../../TabNavigation/index";

const TypoUsage = () => {
	const tabData = ["General guidelines", "Heading", "Body"];

	const TypographyUsageDatas = [
		{
			id: "general-guidelines",
			title: "General guidelines",
			description:
				"Discover best practices for button design, including sizing, spacing, typography, and states like hover and active. These guidelines help you maintain a consistent and delightful button experience throughout.",
			buttons: [{ component: AddButton }, { component: DeleteButton }, { component: EditButton }],
			usageData: TypoCharacterDatas,
		},
		{
			id: "heading-section",
			title: "Heading",
			description:
				"Discover best practices for button design, including sizing, spacing, typography, and states like hover and active. These guidelines help you maintain a consistent and delightful button experience throughout.",
			buttons: [{ component: AddButton }, { component: DeleteButton }, { component: EditButton }],
			usageData: TypoCharacterDatas,
		},
		{
			id: "body-section",
			title: "Body",
			description:
				"Discover best practices for button design, including sizing, spacing, typography, and states like hover and active. These guidelines help you maintain a consistent and delightful button experience throughout.",
			buttons: [{ component: AddButton }, { component: DeleteButton }, { component: EditButton }],
			usageData: TypoCharacterDatas,
		},
	];

	const Section = ({ title, ref, description, key, buttons, usageData }) => (
		<div ref={ref} key={key} className="plt-content-block dis-mb-48">
			<div className="plt-content-wrapper dis-mb-24">
				<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">{title}</h6>
					{/* <div className="plt-icon-wrapper d-flex justify-content-between align-items-center">
            {buttons.map(({ component: ButtonComponent }, index) => (
              <span key={index}>
                <ButtonComponent />
              </span>
            ))}
          </div> */}
				</div>
				<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">{description}</p>
			</div>
			<div className="plt-usage-card-wrapper d-flex justify-content-between">
				{usageData.map((data) => (
					<UsageCard UsageBoxData={data} key={data.id} />
				))}
			</div>
			<p className="plt-body-md dis-mt-12">
				Discover best practices for button design, including sizing, spacing, typography, and states like hover and
				active. These guidelines help you maintain a consistent and delightful button experience throughout.
			</p>
			<p className="plt-body-md dis-mt-16">
				Discover best practices for button design, including sizing, spacing, typography, and states like hover and
				active. These guidelines help you maintain a consistent and delightful button experience throughout.
			</p>
		</div>
	);

	const [activeSideTab, setActiveSideTab] = useState(0);
	const sectionRefs = TypographyUsageDatas?.map(() => React.useRef(null));

	const scrollToSection = useScrollManagement(sectionRefs, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index, sectionRefs);
	};

	return (
		<div className="d-flex justify-content-between w-full">
			<div className="tab-content-container">
				{TypographyUsageDatas.map((section, index) => (
					<div ref={sectionRefs[index]}>
						<Section key={index} {...section} />
					</div>
				))}
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default TypoUsage;
