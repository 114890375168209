import React, { useEffect, useRef, useState } from "react";

import { CardSettingsIcon } from "components/Icons";

const InvoiceTableRow = ({ row }) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <tr>
      <td>
        <div className='plt-color-token-name d-flex align-items-center'>
          <p className='plt-body-md plt-font-color-secondary'>
            {row?.id}
          </p>
          <div className='plt-table-hover-icon dis-ml-8'></div>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center plt-body-md'>
          {row?.admin?.email}
        </div>
      </td>
      <td>
        <p className='plt-role-description plt-body-md plt-font-color-secondary'>
          {row?.invoice_date}
        </p>
      </td>
      <td>
        <div className='plt-asccess-description  plt-body-md plt-font-color-secondary'>
          {" "}
          ${row?.amount}
        </div>
      </td>
      <td>
        <div className='d-flex'>
          <div className='plt-color-detail plt-body-md'>{row?.status} </div>
        </div>
      </td>

      <td>
        <div className='menu cursor-pointer'>
          <div
            className='plt-select-dropdown-wrapper plt-menu-dropdown'
            ref={dropdownRef}
          >
            <span onClick={() => setIsDropdownOpen((prev) => !prev)}>
              <CardSettingsIcon />
            </span>
            {isDropdownOpen && (
              <div className='user-dropdown'>
                <ul>
                  <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                    View
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default InvoiceTableRow;
