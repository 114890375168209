import useScrollManagement from "hooks/useScrollManagement";
import React, { useRef, useState } from "react";
import ComponentCard from "../../../Common/ComponentCard";
import RadioComponent from "../../../Common/RadioComponent";
import TabNavigation from "../../TabNavigation";
import "./PreviewRadioComponent.scss";

const PreviewRadioComponent = ({ setSelectedTab }) => {
  const radioSets = [
    {
      id: 1,
      title: "Normal radio button",
      description: "Normal radio button with label",
      size: "md",
      hasLabel: false,
      options: [
        { value: "option1", label: "Option 1" },
        { value: "option2", label: "Option 2" },
      ],
    },
    {
      id: 2,
      title: "Normal radio button with label",
      description: "Normal radio button with label",
      size: "md",
      hasLabel: true,
      options: [
        { value: "option3", label: "Option 3" },
        { value: "option4", label: "Option 4" },
      ],
    },
    {
      id: 3,
      title: "Disabled - Normal radio button with label",
      description: "Normal radio button with label",
      size: "md",
      hasLabel: true,
      options: [
        { value: "option3", label: "Option 3" },
        { value: "option4", label: "Option 4" },
      ],
      disabled: true,
    },
    {
      id: 4,
      title: "Small radio button",
      description: "Description for outlined radio button set.",
      size: "xs",
      hasLabel: false,
      options: [
        { value: "option5", label: "Option 5" },
        { value: "option6", label: "Option 6" },
      ],
    },
    {
      id: 5,
      title: "Small radio button with label",
      description: "Description for outlined radio button set.",
      size: "xs",
      hasLabel: true,
      options: [
        { value: "option7", label: "Option 7" },
        { value: "option8", label: "Option 8" },
      ],
    },
    {
      id: 6,
      title: "Disabled - Small radio button with label",
      description: "Description for outlined radio button set.",
      size: "xs",
      hasLabel: true,
      options: [
        { value: "option7", label: "Option 7" },
        { value: "option8", label: "Option 8" },
      ],
      disabled: true,
    },
  ];
  const tabData = ["Default", "Outline"];
  // Initialize state with default selected values
  const initialSelectedValues = radioSets.reduce((acc, radioSet, index) => {
    acc[`radioGroup-${index}`] = radioSet.options[0].value;
    return acc;
  }, {});

	const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

	const [activeSideTab, setActiveSideTab] = useState(0);

	const sectionRefs = useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	const handleRadioChange = (groupName) => (event) => {
		setSelectedValues((prevValues) => ({
			...prevValues,
			[groupName]: event.target.value,
		}));
	};

	const [isModalVisible, setModalVisibility] = useState(false);
	const showModal = () => {
		setModalVisibility(true);
	};

	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
			>
				usage
			</span>
		</p>
	);

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-wrapper dis-mb-24">
					<div
						ref={sectionRefs?.current[0]}
						className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8"
					>
						<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Default</h6>
					</div>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>

				{radioSets.map((radioSet, index) => (
					<div key={radioSet.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{radioSet.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{radioSet.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<RadioComponent
								size={radioSet.size}
								hasLabel={radioSet.hasLabel}
								options={radioSet.options}
								name={`radioGroup-${index}`}
								checkedValue={radioSet.options[0].value}
								onChange={handleRadioChange(`radioGroup-${index}`)}
								variant="default"
								disabled={radioSet.disabled}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}

				<div className="plt-content-wrapper dis-mb-24">
					<div
						ref={sectionRefs?.current[1]}
						className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8"
					>
						<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Outline</h6>
					</div>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>

				{radioSets.map((radioSet, index) => (
					<div key={radioSet.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{radioSet.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{radioSet.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<RadioComponent
								size={radioSet.size}
								hasLabel={radioSet.hasLabel}
								options={radioSet.options}
								name={`radioGroup-${index}`}
								checkedValue={selectedValues[`radioGroup-${index}`]}
								onChange={handleRadioChange(`radioGroup-${index}`)}
								variant="outlined"
								disabled={radioSet.disabled}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
			</div>
			{/* <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
                <CustomSelect
                  options={buttonStates}
                  selectedOption={buttonState}
                />
              </div>
            </div>
          </div>

          <div className="plt-preview plt-heading-xl dis-mb-32">
            {radioSets.map((data, index) => (
              <RadioComponent
                size={data.size}
                hasLabel={data.hasLabel}
                options={data.options}
                name={`radioGroup-preview-${index}`}
                checkedValue={selectedValues[`radioGroup-preview-${index}`]}
                onChange={handleRadioChange(`radioGroup-preview-${index}`)}
                variant="default"
              />
            ))}
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Border color default
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Border color active
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Background color active
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                background.button.{buttonState.toLowerCase()}.primary
              </p>
              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Padding
            </h6>
            <div className="plt-token-value-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">H:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">V:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>

              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>
        </div>
      </CommonModal> */}
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewRadioComponent;
