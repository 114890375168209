const parseCssVariables = (cssText) => {
  const regex = /--([^:\s]+):\s*([^;]+)/g;
  let match;
  const variables = {};
  while ((match = regex.exec(cssText)) !== null) {
    const [, name, value] = match;
    variables[name.trim()] = value.trim();
  }
  return variables;
};

const getSpacingValues = (selectedTokenData, buttonState, buttonSize) => {
  const spacingData =
    selectedTokenData[buttonState?.value.toLowerCase()]?.spacing;
  if (!spacingData) {
    return { horizontal: "", vertical: "" };
  }
  const spacingValue = spacingData[buttonSize]?.value || "";

  const values = spacingValue.split(" ").map((val) => val.replace(/[{}]/g, "")); // Remove braces

  // Assign default values to vertical and horizontal
  const [vertical, horizontal = vertical] = values;

  // Function to get CSS variable value
  const getCssVariableValue = (variable) => {
    const element = document.documentElement; // or any specific element
    return getComputedStyle(element).getPropertyValue(variable)?.trim();
  };

  const horizontalVar = `--dss-${horizontal?.replace(".", "-")}`;
  const verticalVar = `--dss-${vertical?.replace(".", "-")}`;

  return {
    horizontal: {
      label: horizontal,
      value: getCssVariableValue(horizontalVar),
    },
    vertical: {
      label: vertical,
      value: getCssVariableValue(verticalVar),
    },
  };
};

export { parseCssVariables, getSpacingValues };
