import React, { useState, useRef, useEffect } from "react";
import SettingsLayout from "components/layouts/SettingsLayout";
import DesignsystemsTable from "./DesignsystemsTable";
import { useGlobalContext } from "context/GlobalContext";
import "./Designsystems.scss";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { fetchSettingsDesignSystems } from "api";
import { getAdminId } from "hooks/storageUtils";
import { checkFeaturePermission } from "api";
import CreateDesignSystemModal from "components/modal/CreateDesignSystemModal";
import UpgradePlanAlertModal from "components/modal/UpgradePlanAlertModal";
import { addDesignSystem } from "api";
import { toast } from "react-toastify";
import { fetchFeatureValueAndUsage } from "api";
import CircularLoader from "components/Common/CircularLoader/CircularLoader";
import { DropdownArrow } from "components/Icons";
import FilterDropdown from "components/FilterDropdown";

const Designsystems = () => {
  const dropdownRef = useRef(null);
  const { setLoading } = useGlobalContext();

  const [designSystems, setDesignSystems] = useState([]);
  const [allDesignSystems, setAllDesignSystems] = useState([]);
  const [valueAndUsage, setValueAndUsage] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [canCreateDesignSystem, setCanCreateDesignSystem] = useState(false);
  const [isCreateDSModalOpen, setCreateDSModalOpen] = useState(false);
  const [isUpgradePlanAlertModalOpen, setUpgradePlanAlertModalOpen] =
    useState(false);

  useEffect(() => {
    const getDesignSystems = async (userId) => {
      try {
        setLoading(true);
        const { data } = await fetchSettingsDesignSystems(userId);
        setDesignSystems(data);
        setAllDesignSystems(data);
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setLoading(false);
      }
    };

    const adminId = getAdminId();
    getDesignSystems(adminId);

    const checkPermission = async (adminId, permission) => {
      try {
        setLoading(true);
        const { data } = await checkFeaturePermission(adminId, permission);
        setCanCreateDesignSystem(data);
      } catch (error) {
        toast.error(error.message);
        console.error("Failed to create design system", error);
      } finally {
        setLoading(false);
      }
    };
    checkPermission(adminId, "design_systems_per_user");

    const getFeatureValueAndUsage = async (adminId, permission) => {
      try {
        setLoading(true);
        const { data } = await fetchFeatureValueAndUsage(adminId, permission);
        setValueAndUsage(data);
      } catch (error) {
        toast.error(error.message);
        console.error("Failed to create design system", error);
      } finally {
        setLoading(false);
      }
    };

    getFeatureValueAndUsage(adminId, "design_systems_per_user");

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeCreateDSModal = () => {
    setCreateDSModalOpen(false);
  };
  const closeUpgradePlanAlertModal = () => {
    setUpgradePlanAlertModalOpen(false);
  };

  const handleCreateDStApply = async (request) => {
    const adminId = getAdminId();
    try {
      setLoading(true);
      const { data } = await addDesignSystem(adminId, request);
      setDesignSystems((prevState) => [...prevState, data]);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to create design system", error);
    } finally {
      setLoading(false);
    }
  };
  const handleUpgradePlantApply = () => {};

  const handleTypeSelect = (value) => {
    let type = value === "Private" ? 1 : 2;
    setDesignSystems(allDesignSystems.filter((all) => all.type === type));
  };
  return (
    <SettingsLayout
      canCreateDesignSystem={canCreateDesignSystem}
      setCanCreateDesignSystem={setCanCreateDesignSystem}
      isCreateDSModalOpen={isCreateDSModalOpen}
      setCreateDSModalOpen={setCreateDSModalOpen}
      setUpgradePlanAlertModalOpen={setUpgradePlanAlertModalOpen}
    >
      <div className='tab-content-wrapper tab-content-setting'>
        <div className='tab-content-container dis-pr-48 dis-pl-48'>
          <div className={`plt-content-block dis-mb-32 w-100`}>
            <div className='plt-content-wrapper dis-mb-24'>
              <div className='plt-content-block-top d-flex align-items-center w-100'>
                <div className='plan-steps-box d-flex align-items-center w-100'>
                  <div className='plan-steps-img dis-mr-8'>
                    <CircularLoader
                      percentage={
                        (valueAndUsage?.usage / valueAndUsage?.value) * 100
                      }
                    />
                  </div>
                  <div className='plan-steps-content'>
                    <p className='plt-contents-desc plt-heading-sm plt-font-color-primary font-weight-700'>
                      {valueAndUsage?.usage || 0} of {valueAndUsage?.value}
                      <span className='d-block plt-body-md'>
                        design system used
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='plt-btn-wrapper d-flex'></div>
            </div>
            <div className='plt-filter-wrapper dis-mb-12 d-flex align-items-center'>
              <FilterDropdown
                name='Type'
                options={["Private", "Public"]}
                setIsDropdownOpen={setIsDropdownOpen}
                isDropdownOpen={isDropdownOpen}
                onSelect={handleTypeSelect}
              />
            </div>
            <DesignsystemsTable
              data={designSystems}
              setDesignSystems={setDesignSystems}
            />
          </div>
        </div>
      </div>
      <CreateDesignSystemModal
        isOpen={isCreateDSModalOpen}
        onRequestClose={closeCreateDSModal}
        onApply={handleCreateDStApply}
        title='Add new design system'
      />
      <UpgradePlanAlertModal
        isOpen={isUpgradePlanAlertModalOpen}
        onRequestClose={closeUpgradePlanAlertModal}
        onApply={handleUpgradePlantApply}
        title='Upgrade'
      />
    </SettingsLayout>
  );
};

export default Designsystems;
