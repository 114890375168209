import React, { createContext, useContext, useState, useEffect } from "react";
import { setLocalStorage, getLocalStorage } from "../utils/localStorageUtils";

const GlobalContext = createContext();
export const useGlobalContext = () => useContext(GlobalContext);

const LOCAL_STORAGE_KEY = "globalContext";

export const GlobalProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [designSystems, setDesignSystems] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sheetIsLoading, setSheetIsLoading] = useState(false);
  const [pusherIsLoading, setPusherSheetIsLoading] = useState(false);
  const [designSystem, setDesignSystem] = useState({});
  const [selectedButtonColor, setButtonSelectedColor] = useState({});
  const [designSystemMeta, setDesignSystemMeta] = useState({});
  const [resetTokens, setResetTokens] = useState(false);
  const [currentDomain, setCurrentDomain] = useState(0);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [theme, setTheme] = useState("light");
  const [themes, setThemes] = useState([]);

  // Load state from localStorage on initial render
  useEffect(() => {
    try {
      const persistedState = getLocalStorage(LOCAL_STORAGE_KEY);
      if (persistedState) {
        const {
          activeTab,
          isLoading,
          designSystems,
          isCollapsed,
          sheetIsLoading,
          pusherIsLoading,
          designSystem,
          selectedButtonColor,
          designSystemMeta,
          resetTokens,
          currentDomain,
          isAuthenticated,
          theme,
          themes,
        } = persistedState;

        setActiveTab(activeTab);
        setLoading(isLoading);
        setDesignSystems(designSystems);
        setIsCollapsed(isCollapsed);
        setSheetIsLoading(sheetIsLoading);
        setPusherSheetIsLoading(pusherIsLoading);
        setDesignSystem(designSystem);
        setButtonSelectedColor(selectedButtonColor);
        setDesignSystemMeta(designSystemMeta);
        setResetTokens(resetTokens);
        setCurrentDomain(currentDomain);
        setAuthenticated(isAuthenticated);
        setTheme(theme);
        setThemes(themes);
      }
    } catch (error) {
      console.error("Failed to load state from localStorage", error);
    }
  }, []);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    try {
      const stateToPersist = {
        activeTab,
        isLoading,
        designSystems,
        isCollapsed,
        sheetIsLoading,
        pusherIsLoading,
        designSystem,
        selectedButtonColor,
        designSystemMeta,
        resetTokens,
        currentDomain,
        isAuthenticated,
        theme,
        themes,
      };
      setLocalStorage(LOCAL_STORAGE_KEY, stateToPersist);
    } catch (error) {
      console.error("Failed to save state to localStorage", error);
    }
  }, [
    activeTab,
    isLoading,
    designSystems,
    isCollapsed,
    sheetIsLoading,
    pusherIsLoading,
    designSystem,
    selectedButtonColor,
    designSystemMeta,
    resetTokens,
    currentDomain,
    isAuthenticated,
    theme,
    themes,
  ]);

  const value = {
    activeTab,
    setActiveTab,
    isLoading,
    setLoading,
    designSystems,
    setDesignSystems,
    sheetIsLoading,
    setSheetIsLoading,
    pusherIsLoading,
    setPusherSheetIsLoading,
    isCollapsed,
    setIsCollapsed,
    designSystem,
    setDesignSystem,
    selectedButtonColor,
    setButtonSelectedColor,
    designSystemMeta,
    setDesignSystemMeta,
    resetTokens,
    setResetTokens,
    currentDomain,
    setCurrentDomain,
    isAuthenticated,
    setAuthenticated,
    theme,
    setTheme,
    themes,
    setThemes,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
