import React, { useState } from "react";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../Common/ComponentCard";
import Textarea from "../../../Textarea";
import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import { EditIcon } from "components/Icons";

const PreviewTextareaComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
	const commonDesc =
		"Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.";

	const textareaData = [
		{
			title: "Normal Default",
			desc: commonDesc,
			validation: false,
			fluid: false,
			tag: false,
		},
		{
			title: "Normal Text area - Chips",
			desc: commonDesc,
			validation: false,
			fluid: false,
			tag: true,
		},
		{
			title: "Normal Error",
			desc: commonDesc,
			validation: true,
			fluid: false,
			tag: false,
		},
		{
			title: "Disabled",
			desc: commonDesc,
			validation: false,
			fluid: false,
			tag: false,
			disabled: true,
			readOnlyState: false,
		},
		{
			title: "Readonly",
			desc: commonDesc,
			validation: false,
			fluid: false,
			tag: false,
			disabled: false,
			readOnlyState: true,
		},
	];

	const textareaDataDense = [
		{
			title: "Dense - Normal Default",
			desc: commonDesc,
			validation: false,
			fluid: false,
			tag: false,
			className: "dss-textarea-sm",
		},
		{
			title: "Dense - Normal Text area - Chips",
			desc: commonDesc,
			validation: false,
			fluid: false,
			tag: true,
			className: "dss-textarea-sm",
		},
		{
			title: "Dense - Normal Error",
			desc: commonDesc,
			validation: true,
			fluid: false,
			tag: false,
			className: "dss-textarea-sm",
		},
		{
			title: "Dense - Disabled",
			desc: commonDesc,
			validation: false,
			fluid: false,
			tag: false,
			disabled: true,
			readOnlyState: false,
			className: "dss-textarea-sm",
		},
		{
			title: "Dense - Readonly",
			desc: commonDesc,
			validation: false,
			fluid: false,
			tag: false,
			disabled: false,
			readOnlyState: true,
			className: "dss-textarea-sm",
		},
	];

	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
			>
				usage
			</span>
		</p>
	);

	const previewTextareaData = [
		{
			title: "Normal Default",
			desc: commonDesc,
			validation: true,
			fluid: false,
			tag: false,
		},
		{
			title: "Normal Text area - Chips",
			desc: commonDesc,
			validation: true,
			fluid: false,
			tag: true,
		},
	];

	const [isModalVisible, setModalVisibility] = useState(false);
	const showModal = () => {
		setModalVisibility(true);
	};

	const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
	const [buttonState, setButtonState] = useState(buttonStates[0]);

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Default</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{textareaData.map((textareaConfig, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
								{textareaConfig.title}
							</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{textareaConfig.desc}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Textarea
								id={index}
								validation={textareaConfig.validation}
								tag={textareaConfig.tag}
								disabled={textareaConfig.disabled}
								readOnlyState={textareaConfig.readOnlyState}
								label="Label"
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Fluid</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{textareaData.map((textareaConfig, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
								{textareaConfig.title}
							</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{textareaConfig.desc}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Textarea
								validation={textareaConfig.validation}
								fluid={true}
								tag={textareaConfig.tag}
								disabled={textareaConfig.disabled}
								readOnlyState={textareaConfig.readOnlyState}
								label="Label"
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}

				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Text area - Dense</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{textareaDataDense.map((textareaConfig, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
								{textareaConfig.title}
							</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{textareaConfig.desc}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Textarea
								id={index}
								validation={textareaConfig.validation}
								tag={textareaConfig.tag}
								disabled={textareaConfig.disabled}
								readOnlyState={textareaConfig.readOnlyState}
								denseClassName={textareaConfig.className}
								label="Label"
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
						Text field - Dense - Fluid
					</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Use a primary button to call attention to an action on a form or to highlight the strongest call to action
						on a page. Primary buttons should only appear once per container (not including the application header or in
						a modal dialog). Not every screen requires a primary button.
					</p>
				</div>
				{textareaDataDense.map((textareaConfig, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
								{textareaConfig.title}
							</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{textareaConfig.desc}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Textarea
								id={index}
								validation={textareaConfig.validation}
								fluid={true}
								tag={textareaConfig.tag}
								disabled={textareaConfig.disabled}
								readOnlyState={textareaConfig.readOnlyState}
								denseClassName={textareaConfig.className}
								label="Label"
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
			</div>
			<CommonModal
				isOpen={isModalVisible}
				onRequestClose={() => setModalVisibility(false)}
				title="Tokens"
				onApply={() => setModalVisibility(false)}
				onCancelText="Cancel"
				onApplyText="Apply"
				variant="modal-tertiary"
			>
				<div className="plt-modal-body-content">
					<div className="plt-dropdown-secondary">
						<div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
								<CustomSelect options={buttonStates} selectedOption={buttonState} />
							</div>
						</div>
					</div>

					<div className="plt-preview plt-heading-xl dis-mb-32">
						{previewTextareaData.map((data, index) => (
							<Textarea key={index} validation={data.validation} label="Label" />
						))}
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Border color active</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">text.button.fill.primary</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Background color active</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
								background.button.{buttonState.toLowerCase()}.primary
							</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("bg");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Text color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">text.button.fill.primary</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Padding</h6>
						<div className="plt-token-value-item d-flex align-items-center">
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">H:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">V:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>

							<span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
								<EditIcon />
							</span>
						</div>
					</div>
				</div>
			</CommonModal>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewTextareaComponent;
