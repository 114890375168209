import React, { useState } from "react";
import "./ThemeToggle.scss";
import { useGlobalContext } from "../../../context/GlobalContext";
import SelectInput from "components/SelectInput";

const ThemeToggle = () => {
  const { theme, setTheme, themes } = useGlobalContext();
  const [selectedTheme, setSelectedTheme] = useState({
    label: theme,
    value: theme,
  });
  const themeOptions = themes.map((theme) => ({ label: theme, value: theme }));
  const toggleTheme = (value) => {
    const newTheme = value.value;
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
  };

  return (
    <div style={{ maxWidth: "150px" }}>
      <SelectInput
        className='custom-dropdown'
        options={themeOptions}
        // selectedOption={selectedScale}
        defaultValue={selectedTheme}
        onSelect={toggleTheme}
      />
    </div>
  );
};

export default ThemeToggle;
