import React, { useState } from "react";
import "./PreviewRadius.scss";

import RadiusCard from "../../../Common/RadiusCard";
import { RadiusCharacterDatas } from "../../../../assets/data/data";
import AddButton from "../../../Buttons/AddButton";
import TabNavigation from "../../TabNavigation/index";
import CustomSelect from "../../../Common/CustomSelect";
import useScrollManagement from "../../../../hooks/useScrollManagement";
import CommonModal from "../../../Common/CommonModal";
import { CusotmRadius } from "components/Icons";
import { useGlobalContext } from "context/GlobalContext";
import { getValueByPath } from "helpers/token";
import AuthAction from "components/auth/AuthAction";

const PreviewRadius = () => {
  const tabData = ["Principles", "Major styles"];
  const [activeSideTab, setActiveSideTab] = useState(0);

  const { designSystem } = useGlobalContext();
  const radiusCardData = getValueByPath(designSystem, "core.borderRadius");

  const combinedRadiusCharacterDatas = RadiusCharacterDatas.filter(
    (characterData) =>
      radiusCardData &&
      Object.values(radiusCardData).some(
        (beData) => beData?.key === characterData?.key
      )
  ).map((characterData) => {
    const matchedBeData = Object.values(radiusCardData).find(
      (beData) => beData?.key === characterData?.key
    );
    return { ...characterData, ...matchedBeData };
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const [isStyleScaleModalOpen, setStyleScaleModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    setConfirmModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setConfirmModalOpen(false);
  };

  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
    scrollToSection(index);
  };

  const [selectedFont, setSelectedFont] = useState("Light");
  const fonts = ["Light", "Medium", "Intense"];
  const handleFontChange = (value) => setSelectedFont(value);

  const [radius, setRadius] = useState({
    name: "heading-3xl",
    description: "",
    weight: "Regular",
    size: "16",
    lineHeight: "130%",
    letterSpacing: "0px",
    paragraphSpacing: "15px",
  });

  const [charCount, setCharCount] = useState(0);
  const maxChar = 20;

  const handleInputChange = (field, value) => {
    if (field === "description" && value.length > maxChar) {
      return;
    }
    setRadius({ ...radius, [field]: value });
    if (field === "description") {
      setCharCount(value.length);
    }
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleRadius = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const radiusValues = [
    "0px",
    "4px",
    "6px",
    "8px",
    "12px",
    "16px",
    "20px",
    "28px",
    "9999px",
  ];
  const [selectedValue, setSelectedValue] = useState(radiusValues[0]);

  const handleValueChange = (value) => {
    setSelectedValue(value);
  };

  const sectionRefs = React.useRef([React.createRef(), React.createRef()]);

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  return (
    <div className='d-flex justify-content-between w-full'>
      <div className='tab-content-container'>
        <div className='plt-content-block'>
          {/* <div className='plt-dropdown-container dis-pb-32'> */}
          {/* <div className="plt-dropdown-set d-flex align-items-center">
              <h6 className="plt-dropdown-title dis-pr-12 plt-body-compact-sm plt-font-color-primary">
                Style set:
              </h6>
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-style">
                <CustomSelect
                  options={fonts}
                  selectedOption={selectedFont}
                  onSelect={handleFontChange}
                />
              </div>
            </div> */}
          {/* <div className='plt-value-wrapper-top d-flex flex-column'>
              <div className='plt-value-wrapper d-flex align-items-center dis-mb-16'>
                <h6 className='plt-value-title dis-pr-8 plt-heading-md font-weight-600 plt-font-color-primary'>
                  Style set:
                </h6>
                <h4 className='plt-value-txt plt-heading-xs font-weight-600 plt-font-color-primary'>
                  Light
                </h4>
              </div>
            </div>
            <AuthAction>
              <span
                onClick={() => setStyleScaleModalOpen(true)}
                className='plt-modal-link plt-ui-text-sm-medium cursor-pointer d-block'
              >
                Change style set
              </span>
            </AuthAction> */}
          {/* </div> */}
          <div
            ref={sectionRefs.current[0]}
            className='plt-content-wrapper dis-mb-48'
          >
            <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
              <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
                Principles
              </h6>
            </div>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Elevations are the layered surfaces that form the foundation of
              the UI. They create a blank canvas where other UI will be placed,
              such as text, icons, backgrounds, and borders.
            </p>
          </div>
        </div>
        <div className='plt-content-block'>
          <div className='plt-content-wrapper dis-mb-24'>
            <div
              ref={sectionRefs.current[1]}
              className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'
            >
              <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
                Major styles
              </h6>
              {/* <span
                onClick={() => setModalOpen(true)}
                className='plt-function-icon'
              >
                <AddButton />
              </span> */}
            </div>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Elevations contribute to the visual hierarchy and spatial
              relationships between components.
            </p>
          </div>
        </div>

        <div className='plt-common-card-wrapper radius-card-wrapper d-flex'>
          {combinedRadiusCharacterDatas.map((data) => (
            // <div key={data.id} className="plt-common-card">
            <div key={data.id} className='plt-common-card'>
              <RadiusCard RadiusBoxData={data} radiusValues={radiusValues} />
            </div>
          ))}
        </div>
      </div>

      <div className='plt-token-contents '>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>

			<CommonModal
				isOpen={isModalOpen}
				onRequestClose={() => closeModal(false)}
				title="Add radius style"
				onApply={() => openModal(false)}
				onCancelText="Cancel"
				onApplyText="Apply"
			>
				<div className="plt-modal-body-content">
					<div className="plt-preview plt-heading-xl dis-mb-32">
						<div className="plt-common-card-img-box dss-small-radius m-auto"></div>
					</div>
					<div className="modal-value-wrapper dis-mb-32">
						<h6 className="modal-value-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4">Style name</h6>
						<div className="modal-value-block w-100">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm">radius-150</p>
						</div>
					</div>
					<div className="radius-modal-detail d-flex">
						<div className="plt-select-dropdown-wrapper dis-mr-8">
							<h6 className="plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4">
								Value
							</h6>
							<div className="modal-value-block w-100">
								<div className="plt-dropdown-wrapper plt-dropdown-wrapper-value">
									<CustomSelect options={radiusValues} selectedOption={selectedValue} onSelect={handleValueChange} />
								</div>
							</div>
						</div>
						<div className="d-block plt-iconbtn custom-radius-btn" onClick={toggleRadius}>
							<CusotmRadius />
						</div>
					</div>
					{/* <div className="custom-radius-detail d-flex align-items-center justify-content-between"> */}
					<div className={`custom-radius-wrapper ${isCollapsed ? "active" : ""}`}>
						<div className="custom-radius-detail d-flex align-items-center justify-content-between">
							<div className="plt-select-dropdown-wrapper">
								<div className="modal-value-block w-100">
									<span className="dss-border-radius dss-border-radius-top-lft dis-mr-8"></span>
									<input className="dss-border-radius-value dss-body-compact-sm" placeholder="0" />
								</div>
							</div>
							<div className="plt-select-dropdown-wrapper">
								<div className="modal-value-block w-100">
									<span className="dss-border-radius dss-border-radius-top-rgt dis-mr-8"></span>
									<input className="dss-border-radius-value dss-body-compact-sm" placeholder="0" />
								</div>
							</div>
							<div className="plt-select-dropdown-wrapper">
								<div className="modal-value-block w-100">
									<span className="dss-border-radius dss-border-radius-btm-lft dis-mr-8"></span>
									<input className="dss-border-radius-value dss-body-compact-sm" placeholder="0" />
								</div>
							</div>
							<div className="plt-select-dropdown-wrapper">
								<div className="modal-value-block w-100">
									<span className="dss-border-radius dss-border-radius-btm-rgt dis-mr-8"></span>
									<input className="dss-border-radius-value dss-body-compact-sm" placeholder="0" />
								</div>
							</div>
						</div>
					</div>
					<div className="modal-textarea-wrapper dis-mt-32 dis-mb-24">
						<h6 className="modal-textarea-title plt-ui-text-sm-regular plt-font-color-secondary">Add description</h6>
						{/* <textarea
              className="modal-textarea w-100"
              placeholder="Text"
            ></textarea> */}
            <textarea
              className='modal-textarea w-100'
              placeholder='Text'
              value={radius.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            ></textarea>
            <div className='modal-textarea-info d-flex align-items-center justify-content-between dis-mt-4'>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                Helper text
              </span>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                {charCount}/{maxChar}
              </span>
            </div>
          </div>
        </div>
      </CommonModal>

      <CommonModal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setConfirmModalOpen(false)}
        title='Change radius style?'
        onApply={() => setConfirmModalOpen(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
        // variant="modal-secondary"
      >
        <div className='plt-modal-body-content'>
          <p className='plt-body-md plt-font-color-primary'>
            This change will be reflected in the entire system.
          </p>
        </div>
      </CommonModal>

      <CommonModal
        isOpen={isStyleScaleModalOpen}
        onRequestClose={() => setStyleScaleModalOpen(false)}
        title='Change style set'
        onApply={() => setStyleScaleModalOpen(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
        // variant="modal-secondary"
      >
        <div className='plt-modal-body-content'>
          <div className='plt-select-dropdown-wrapper dis-pl-24 dis-pr-24'>
            <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Style set
            </h6>
            <div className='plt-dropdown-wrapper plt-dropdown-wrapper-style-set'>
              <CustomSelect
                options={fonts}
                selectedOption={selectedFont}
                onSelect={handleFontChange}
              />
            </div>
          </div>
        </div>
      </CommonModal>

      {/* <CommonModal
        isOpen={isCustomModalOpen}
        onRequestClose={() => setCustomModalOpen(false)}
        title="Custom radius"
        onApply={() => setCustomModalOpen(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
      >
        <div className="plt-modal-body-content">

        </div>
      </CommonModal> */}
    </div>
  );
};

export default PreviewRadius;
