import React from "react";
import textareaguidelines1 from "assets/images/png/textareaguidelines1.png";
import textareaguidelines2 from "assets/images/png/textareaguidelines2.png";
import textareaguidelines3 from "assets/images/png/textareaguidelines3.png";
import textareaguidelines4 from "assets/images/png/textareaguidelines4.png";

const ComponentPrimaryDatas = [
	{
		id: 1,
		title: "Do",
		titleClassname: "active",
		image: textareaguidelines1,
		description:
			"Position labels to the left of text areas, aligning them with the top for clarity, and maintain consistent spacing between labels and input areas.",
	},
	{
		id: 2,
		title: "Don’t",
		titleClassname: "inactive",
		image: textareaguidelines2,
		description:
			"Avoid placing labels above or below text areas and ensure they are properly aligned to the left. Maintain appropriate spacing between labels and input fields.",
	},
	{
		id: 3,
		title: "Do",
		titleClassname: "active",
		image: textareaguidelines3,
		description:
			"Align text areas consistently to the left and ensure uniform spacing around them for a clear and organized appearance.",
	},
	{
		id: 4,
		title: "Don’t",
		titleClassname: "inactive",
		image: textareaguidelines4,
		description:
			"Avoid misaligning text areas and ensure consistent, even spacing to maintain a clean and organized layout.",
	},
];

const GuidelineCardTextarea = () => {
	return (
		<div className="guideline-card d-flex justify-content-between">
			{ComponentPrimaryDatas.map((data) => (
				<div key={data.id} className="guideline-wrapper">
					<h6 className={`guideline-badge plt-body-compact-md dis-mb-8 ${data.titleClassname}`}>{data.title}</h6>
					<div className="guideline-detail-wrapper d-flex align-items-center justify-content-center">
						<div className="guideline-img">
							<img src={data.image} alt="userimage" />
						</div>
					</div>
					<p className="guideline-desc plt-body-md dis-mt-12">{data.description}</p>
				</div>
			))}
		</div>
	);
};

export default GuidelineCardTextarea;
