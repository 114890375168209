import InvoiceTableRow from "./InvoiceTableRaw";

const InvoiceTable = ({ data, setInvoices }) => {
  return (
    <div className='plt-settings-table'>
      <div className='plt-table-wrapper'>
        {/* Team */}
        <div className='plt-table-container'>
          <table className='plt-table plt-table-team'>
            <thead>
              <tr>
                <th className='plt-table-head'>Invoice number</th>
                <th className='plt-table-head'>email</th>
                <th className='plt-table-head'>Invoice date</th>
                <th className='plt-table-head'>Amount</th>
                <th className='plt-table-head'>Status</th>
                <th className='plt-table-head'></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((invoice, index) => (
                <InvoiceTableRow
                  key={invoice.id}
                  row={invoice}
                  slno={index + 1}
                  setInvoices={setInvoices}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
