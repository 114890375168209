import React, { useEffect, useState } from "react";
import "assets/scss/pages/DesignSystem.scss";
import "./PreviewPage.scss";
import PrimaryButton from "components/Common/PrimaryButton";
import Inputs from "components/Inputs";
import { ButtonIcon } from "components/Icons";
import Textarea from "components/Textarea";
import Tabs from "components/Tabs";
import RadioComponent from "components/Common/RadioComponent";
import CheckboxComponent from "components/Common/CheckboxComponent";
import ToggleComponent from "components/Common/ToggleComponent";
// import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import ThemeToggle from "components/Common/ThemeToggle";

const PreviewPage = () => {
	const navigate = useNavigate();

	const goHome = () => {
		navigate("/");
	};

	return (
		<div className="preview-page ">
			<header>
				<div className="container">
					<div className="menu-top d-flex justify-content-between dis-mb-32">
						<div className="logo" onClick={goHome}></div>
						<nav className="desktop">
							<ul>
								<li>
									<PrimaryButton 
									text="About" 
									// className="dss-btn dss-btn-link dss-btn-md plt-heading-xs"
									variant= "link"
									size= "md"	
									className="plt-heading-xs"
									 />
								</li>
								<li>
									<PrimaryButton 
									text="Services" 
									// className="dss-btn dss-btn-link dss-btn-md plt-heading-xs"
									variant= "link"
									size= "md"	
									className="plt-heading-xs"
									 />
								</li>
								<li className="btn-nav">
									<PrimaryButton 
									text="Contact" 
									// className="dss-btn dss-btn-outlined dss-btn-md"
									variant= "outlined"
									size= "md"	
									 />
								</li>
							</ul>
						</nav>
						<nav className="mobile">
							<h3>
								<i className="fa-solid fa-bars"></i>
							</h3>
							<ul>
								<li>
									<PrimaryButton 
									text="About" 
									// className="dss-btn dss-btn-link dss-btn-md plt-heading-xs"
									variant= "link"
									size= "md"	
									className="plt-heading-xs"
									 />
								</li>
								<li>
									<PrimaryButton 
									text="Services" 
									// className="dss-btn dss-btn-link dss-btn-md plt-heading-xs"
									variant= "link"
									size= "md"	
									className="plt-heading-xs"
							  />
								</li>
								<li className="btn-nav">
									<PrimaryButton 
									text="Contact" 
									// className="dss-btn dss-btn-outlined dss-btn-md"
									variant= "outlined"
									size= "md"	
							  />
								</li>
							</ul>
						</nav>
						<div className="flex-fit">
							<ThemeToggle></ThemeToggle>
						</div>
					</div>
					<div className="preview-banner d-flex justify-content-between">
						<div className="call-header">
							<h2 className="plt-heading-3xl font-color-white">You sell more with web automation</h2>
							<p className="plt-heading-xs font-color-white dis-mt-10">
								We create marketing solutions for your company to sell more, in the best way
							</p>
						</div>
						<div className="banner-tab">
							<Tabs
								dataItem={[
									{
										key: 1,
										label: "Sign in",
										content: (
											<div>
												<h3 className="plt-heading-xs plt-font-color-primary dis-mb-16">Log in</h3>
												<div className="banner-form">
													<form action="#">
														<Inputs
															className="dis-mb-12"
															leadingIcon={false}
															tailingIcon={true}
															prefix={false}
															suffix={false}
															tag={false}
															readOnlyState={false}
															disabled={false}
															label="Username"
															placeholder="Username"
														/>
														<Inputs
															className="dis-mb-12"
															leadingIcon={false}
															tailingIcon={true}
															prefix={false}
															suffix={false}
															tag={false}
															readOnlyState={false}
															disabled={false}
															label="Password"
															placeholder="Password"
														/>
														<ToggleComponent
															className="dis-mb-16"
															size="large"
															hasLabel={true}
															toggletext="Keep me signed in"
															token="large"
															disabled={false}
														/>
														<PrimaryButton 
														text="Sign in" 
														// className="dss-btn dss-btn-primary dss-btn-md"
														variant= "primary"
														size= "md"														
														 />
													</form>
												</div>
											</div>
										),
									},
									{
										key: 2,
										label: "Sign up",
										content: (
											<div>
												<h3 className="plt-heading-xs plt-font-color-primary dis-mb-16">Sign up</h3>
												<div className="banner-form">
													<form action="#">
														<Inputs
															className="dis-mb-12"
															leadingIcon={false}
															tailingIcon={false}
															prefix={false}
															suffix={false}
															tag={false}
															readOnlyState={false}
															disabled={false}
															label="Name"
															placeholder="Name"
														/>
														<Inputs
															className="dis-mb-12"
															leadingIcon={false}
															tailingIcon={false}
															prefix={false}
															suffix={false}
															tag={false}
															readOnlyState={false}
															disabled={false}
															label="Email"
															placeholder="Email"
														/>
														<Inputs
															className="dis-mb-12"
															leadingIcon={false}
															tailingIcon={false}
															prefix={false}
															suffix={false}
															tag={false}
															readOnlyState={false}
															disabled={false}
															label="Password"
															placeholder="Password"
														/>
														<Inputs
															className="dis-mb-12"
															leadingIcon={false}
															tailingIcon={false}
															prefix={false}
															suffix={false}
															tag={false}
															readOnlyState={false}
															disabled={false}
															label="Confirm Password"
															placeholder="Confirm Password"
														/>
														<h3 className="plt-heading-xs plt-font-color-primary dis-mb-16">
															What kind of item would you like to add?
														</h3>
														<RadioComponent
															className="dis-mb-12"
															size="md"
															hasLabel={true}
															options={[
																{ value: "option1", label: "Service" },
																{ value: "option2", label: "Product" },
															]}
															checkedValue="option1"
															variant="default"
															disabled={false}
														/>
														<Textarea
															className="dis-mb-16"
															validation={false}
															tag={false}
															disabled={false}
															readOnlyState={false}
															label="Any comments or suggestions?"
															placeholder="Comments"
														/>
														<CheckboxComponent
															className="dis-mb-16"
															label="I read and agree to terms & conditions"
															size="normal"
															variant="outline"
															value={true}
														/>
														<PrimaryButton 
														text="Sign up" 
														// className="dss-btn dss-btn-primary dss-btn-md"
														variant= "primary"
														size= "md"	
														 />
													</form>
												</div>
											</div>
										),
									},
								]}
								variant="someVariant"
							/>
						</div>
					</div>
				</div>
			</header>

			<div className="description">
				<div className="container">
					<p className="plt-body-lg">
						With years of experience in web development and marketing automation, Design system brings solutions that
						help your team sell more in both B2C and B2B segments.
					</p>
					<div className="d-flex justify-content-center">
						<form action="#" className="header-form d-flex">
							<div className="header-input">
								<Inputs
									leadingIcon={false}
									tailingIcon={false}
									prefix={false}
									suffix={false}
									tag={false}
									readOnlyState={false}
									disabled={false}
									fluid={true}
									label="your mail"
								/>
							</div>
							<PrimaryButton 
							text="know more" 
							// className="dss-btn dss-btn-primary dss-btn-md"
							variant= "primary"
							size= "md"	
							 />
						</form>
					</div>
				</div>
			</div>

			<div className="info">
				<div className="container">
					<div className="info-sub d-flex justify-content-between">
						<div className="text-info">
							<h3 className="plt-heading-md  font-color-primary">
								We are a digital agency. We work on its positioning, content and aesthetics, always focused on
								conversion.
							</h3>
							<p className="plt-body-md">
								At our digital agency, we're more than pixel pushers—we're strategists, storytellers, and conversion
								professionals. We dissect your brand, audience, and market. Then, armed with insights, we position you
								for digital victory.
							</p>
						</div>

						<img src="https://i.postimg.cc/C5p1893x/mockup1.jpg" alt="Imagem de chamada" />
					</div>
				</div>
			</div>

			<div className="services">
				<div className="container">
					<div className="title-services">
						<h3 className="plt-heading-lg plt-font-color-secondary">How can we help your company</h3>
					</div>

					<div className="flex-services">
						<div className="space-services">
							<div className="box-services">
								<img src="https://i.postimg.cc/yx2wWztL/img3.jpg" alt="Email Marketing" />
								<div className="wrapper-services">
									<h2>Email Marketing</h2>
									<p>Learn how to use email marketing in your company to boost your sales in an automated way.</p>
									<PrimaryButton 
									text="Budget" 
									// className="dss-btn dss-btn-ghost dss-btn-lg"
									variant= "ghost"
									size= "lg"	
									 />
								</div>
							</div>
						</div>

						<div className="space-services">
							<div className="box-services">
								<img src="https://i.postimg.cc/50Kcdz3d/img1.jpg" alt="Sales Page" />
								<div className="wrapper-services">
									<h2>Sales Page</h2>
									<p>
										We create 100% optimized, conversion-oriented sales pages. Request a quote and have your page to
										sell on the internet today.
									</p>
									<PrimaryButton 
									text="Budget" 
									// className="dss-btn dss-btn-ghost dss-btn-lg"
									variant= "ghost"
									size= "lg"	 />
								</div>
							</div>
						</div>

						<div className="space-services">
							<div className="box-services">
								<img src="https://i.postimg.cc/wM1C6Bft/img2.jpg" alt="Capture Pages" />
								<div className="wrapper-services">
									<h2>Capture and Signup Pages</h2>
									<p>Capture visitors' email addresses on your website and create a lead base for your company.</p>
									<PrimaryButton 
									text="Budget" 
									// className="dss-btn dss-btn-ghost dss-btn-lg"
									variant= "ghost"
									size= "lg" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<footer>
				<div className="container">
					<div className="footer-sub d-flex flex-wrap justify-content-between">
						<div className="box-footer">
							<h2>Address:</h2>
							<p>Rua Rafael Bandeira, 328 - Centro, Florianópolis/SC 88015-450, Brasil</p>
						</div>
						<div className="box-footer text-center">
							<h2>Phone Number</h2>
							<p>(48) 99901-3620</p>
						</div>
						<div className="box-footer">
							<h2>Receive our news</h2>
							<form action="#" className="d-flex">
								<div className="footer-input">
									<Inputs
										leadingIcon={false}
										tailingIcon={false}
										prefix={false}
										suffix={false}
										tag={false}
										readOnlyState={false}
										disabled={false}
										fluid={false}
										className="dss-input-sm"
										label="your mail"
									/>
								</div>
								<PrimaryButton
									// className={`dss-btn dss-btn-secondary dss-btn-icon dss-btn-md`}
									variant= "secondary"
									size= "md"
									icon={true}
									prefixIcon={false}
									suffixIcon={<ButtonIcon />}
								/>
							</form>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default PreviewPage;
