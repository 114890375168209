import React, { useState, useRef, useEffect } from "react";
import { CardSettingsIcon } from "components/Icons";
import EditTeamModal from "./EditTeamModal";
import { deleteMember } from "api";
import { toast } from "react-toastify";
import { useGlobalContext } from "context/GlobalContext";
import userAvatar from "assets/images/png/userAvatar.png";
import { updateMember } from "api";
import TeamAccessColumn from "./TeamAccessColumn";
import UpgradePlanAlertModal from "components/modal/UpgradePlanAlertModal";

const TeamTableRaw = ({ row, slno, roles, setTeams, designSystems }) => {
  const { setLoading } = useGlobalContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [isUpgradePlanAlertModalOpen, setUpgradePlanAlertModalOpen] =
    useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeUpgradePlanAlertModal = () => {
    setUpgradePlanAlertModalOpen(false);
  };

  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const deleteState = (id) => {
    setTeams((prevState) => prevState.filter((team) => team.id !== id));
  };

  const handleDeleteMember = async (id) => {
    if (confirm("Are you sure to delete") == true) {
      try {
        setLoading(true);
        const data = await deleteMember(id);
        toast.success(data.message);
        deleteState(id);
      } catch (error) {
        toast.error(error.message);
        console.error("Failed to delete memeber", error);
      } finally {
        setLoading(false);
      }
    }
  };
  const updateState = (data) => {
    setTeams((prevState) =>
      prevState.map((team) => {
        if (team.id !== data.id) {
          return team;
        } else {
          return {
            ...team,
            ...data,
          };
        }
      })
    );
  };
  const handleUpdateMemeber = async (input, role) => {
    try {
      closeModal();
      setLoading(true);
      const data = await updateMember(selectedData.id, input, role);
      if (data.upgrade) {
        setUpgradePlanAlertModalOpen(true);
      } else {
        updateState(data.data);
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to update memeber", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpgradePlantApply = () => {};

  return (
    <tr>
      <td>
        <div className='plt-sl-number d-flex align-items-center'>
          <p className='plt-body-md plt-font-color-primary'>
            {slno}
          </p>
          {/* <div className='plt-table-hover-icon dis-ml-8'></div> */}
        </div>
      </td>
      <td>
        <div className='plt-team-name d-flex align-items-center plt-body-sm plt-font-color-primary'>
          <img
            src={userAvatar}
            alt='profile'
            className='user-image dis-mr-12'
          />
          {row?.email}
        </div>
      </td>
      <td>
        <div className='plt-role-name plt-body-md'>
          <p className='plt-badge plt-badge-neutral plt-ui-text-xs-medium plt-font-color-primary'>
            {roles?.find((role) => role.value == row?.role_id)?.label}
          </p>
        </div>
      </td>
      <td>
        <div className='plt-asccess-description  plt-body-sm plt-font-color-secondary'>
          <TeamAccessColumn
            data={row}
            openModal={openModal}
            setSelectedData={setSelectedData}
            designSystems={designSystems}
          />
        </div>
      </td>
      <td>
        <div className='plt-status-description d-flex'>
          <div className='plt-status-detail plt-body-md'>
            {" "}
            <p
              className={`plt-badge plt-badge-fill ${row?.registered_at ? "plt-badge-fill-success" : "plt-badge-fill-caution"} plt-ui-text-xs-medium plt-font-color-primary`}
            >
              {row?.registered_at ? "Joined" : "Pending"}
            </p>
          </div>
        </div>
      </td>
      <td>
        <div className='plt-date-detail d-flex'>
          <div className='plt-body-sm plt-font-color-primary'>{row?.created_at}</div>
        </div>
      </td>
      <td>
        <div className='plt-date-detail d-flex'>
          <div className='plt-body-sm plt-font-color-primary'>
            {" "}
            {row?.user?.last_login_at}
          </div>
        </div>
      </td>
      <td>
        <div className='menu cursor-pointer'>
          <div
            className='plt-select-dropdown-wrapper plt-menu-dropdown'
            ref={dropdownRef}
          >
            <span onClick={() => setIsDropdownOpen((prev) => !prev)}>
              <CardSettingsIcon />
            </span>
            {isDropdownOpen && (
              <div className='user-dropdown'>
                <ul>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => {
                      openModal();
                      setSelectedData(row);
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                    onClick={() => handleDeleteMember(row?.id)}
                  >
                    Delete user
                  </li>
                </ul>
              </div>
            )}
          </div>
          <EditTeamModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            onApply={handleUpdateMemeber}
            title='Member details'
            data={selectedData}
            roles={roles}
            designSystems={designSystems}
          />
          <UpgradePlanAlertModal
            isOpen={isUpgradePlanAlertModalOpen}
            onRequestClose={closeUpgradePlanAlertModal}
            onApply={handleUpgradePlantApply}
            title='Upgrade Plan'
          />
        </div>
      </td>
    </tr>
  );
};

export default TeamTableRaw;
