import ComponentCard from "components/Common/ComponentCard";
import PrimaryButton from "components/Common/PrimaryButton";
import React from "react";
import Skeleton from "react-loading-skeleton";

const ButtonComponentCard = ({
  data,
  setButtonState,
  setButtonSize,
  showModal,
  isLoading,
  tokenDataType,
}) => {
  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
      >
        usage
      </span>
    </p>
  );

  const code = `dss-btn dss-btn-${data?.variant} ${data?.size ? `dss-btn-${data?.size}` : ""} ${data?.fluid ? `dss-btn-fluid` : ""} ${data?.icon ? `dss-btn-icon` : ""} ${data?.disabled ? "disabled" : ""} ${data?.iconOnly ? `icon-only` : ""}`;

  return (
    <div key={data.id} className="plt-content-wrapper">
      <div className="plt-content-wrapper-block dis-mb-24">
        <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
          {data.title}
        </h6>
        <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
          {data.description}
        </p>
      </div>
      <ComponentCard
        code={code}
        edit={true}
        showCode={true}
        editClick={() => {
          if (data?.title === "disabled") {
            // setButtonSize("large");
            setButtonState("Disabled");
          } else {
            setButtonSize((prev) => (prev = data?.title?.toLowerCase()));
          }
          // buttonSize &&
          showModal(data.variant, data.size, tokenDataType, data.disabled);
        }}
      >
        {isLoading ? (
          <Skeleton height={36} width={150} />
        ) : (
          <PrimaryButton
            prefixIcon={data.prefixIcon}
            suffixIcon={data.suffixIcon}
            size={data.size}
            variant={data.variant}
            disabled={data.disabled}
            icon={data.icon}
            iconOnly={data.iconOnly}
            text={data.text}
          />
        )}
      </ComponentCard>
      <AllUsage />
    </div>
  );
};

export default ButtonComponentCard;
