import React, { useState } from "react";

import UserMenu from "components/UserMenu";
import UserImage from "assets/images/png/userAvatar.png";
import { PlusButtonWhiteicon } from "components/Icons";
import { PremiumIcon } from "components/Icons";

const SettingsHeader = ({
  user,
  activeTab,
  isModalOpen,
  setModalOpen,
  canAddMember,
  setCanAddMember,
  ugradePlanAlertModalOpen,
  setUpgradePlanAlertModalOpen,
  canCreateDesignSystem,
  setCreateDSModalOpen,
}) => {
  const apiUrl = process.env.REACT_APP_SHEET_URL;
  let buttonComponent = "";
  if (activeTab?.title === "Design systems") {
    buttonComponent = (
      <div className='plt-btn-wrapper d-flex dis-mr-24'>
        <button
          className='plt-btn plt-btn-primary'
          onClick={() => {
            if (canCreateDesignSystem) {
              setCreateDSModalOpen(true);
            } else {
              setUpgradePlanAlertModalOpen(true);
            }
          }}
        >
          <span className='dss-icon dis-mr-4'>
            {!canCreateDesignSystem ? <PremiumIcon /> : <PlusButtonWhiteicon />}
          </span>
          Add system
        </button>
      </div>
    );
  } else if (activeTab?.title === "Team") {
    buttonComponent = (
      <div className='plt-btn-wrapper d-flex dis-mr-24'>
        <button
          className='plt-btn plt-btn-primary'
          onClick={() => {
            if (canAddMember) {
              setModalOpen(true);
            } else {
              setUpgradePlanAlertModalOpen(true);
            }
          }}
        >
          <span className='dss-icon dis-mr-4'>
            {!canAddMember ? <PremiumIcon /> : <PlusButtonWhiteicon />}
          </span>
          Add member
        </button>
      </div>
    );
  }
  return (
    <div className='setting-profile-dropdown d-flex justify-content-between'>
      <div className='setting-header-left'>
        <h6 className='plt-setting-head plt-heading-md plt-font-color-primary'>
          {activeTab?.title}
        </h6>
        <p className='plt-setting-desc plt-body-sm plt-font-color-primary'>
          Configure the functionality of user screens in settings.
        </p>
      </div>
      <div className='setting-header-rgt d-flex align-items-center'>
        {buttonComponent}
        <div className='tab-profile-dropdown'>
          <UserMenu
            UserImage={user?.image ? `${apiUrl}${user?.image_url}` : UserImage}
            showDomain={false}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsHeader;
