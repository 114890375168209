export const WEIGHT = [
  {
    key: "regular",
    token: "core.fontWeight.regular",
    value: "400",
  },
  {
    key: "medium",
    token: "core.fontWeight.medium",
    value: "500",
  },
  {
    key: "semiBold",
    token: "core.fontWeight.semiBold",
    value: "600",
  },
  {
    key: "bold",
    token: "core.fontWeight.bold",
    value: "700",
  },
];

export const BUTTON_STATES = [
  { name: "Default", value: 1 },
  { name: "Hovered", value: 2 },
  { name: "Pressed", value: 3 },
  { name: "Disabled", value: 4 },
];

export const FONTS = ["Inter", "Bricolage Grotesque", "Lato"];

export const SCALE = [
  { label: "1.250- Major third", value: 1.25 },
  { label: "1.067 - Minor second", value: 1.067 },
  { label: "1.125 - Major second", value: 1.125 },
  { label: "1.200 - Minor third", value: 1.2 },
];

export const BODY_SIZE = [
  { label: "16px", value: 16 },
  { label: "17px", value: 17 },
  { label: "18px", value: 18 },
  { label: "19px", value: 19 },
];

export const PERMISIONS = [
  { value: 1, label: "Editor" },
  { value: 2, label: "Viewer" },
  { value: 3, label: "No access" },
];
export const ROLES = [
  { value: 1, label: "Admin" },
  { value: 2, label: "Member" },
];
export const TYPE = [
  { value: 1, label: "Private" },
  { value: 2, label: "Open to public" },
];

//Button constants
export const buttonStates = [
  { value: "Default", name: "Default" },
  { value: "Hovered", name: "Hovered" },
  { value: "Pressed", name: "Pressed" },
  { value: "Disabled", name: "Disabled" },
];
export const textdecoration = [
  { value: "Underline", name: "Underline" },
  { value: "Overline", name: "Overline" },
  { value: "line-through", name: "line-through" },
  { value: "None", name: "None" },
];
export const buttonTabData = [
  "Primary",
  "Secondary",
  "Outline",
  "Danger",
  "Ghost",
  "Link",
];
