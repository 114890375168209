import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import radioDetail from "../../../../assets/images/png/radioDetail.png";
import radioAlignment from "../../../../assets/images/png/radioAlignment.png";
import radioPlacement from "../../../../assets/images/png/radioPlacement.png";
import radioPlacementImg from "../../../../assets/images/png/radioPlacementImg.png";
import radioPlacementlabel from "../../../../assets/images/png/radioPlacementlabel.png";
import GuidelineCardRadio from "../../../Common/Guidelines/GuidelineCardRadio";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
	{
		number: 1,
		title: "Radio button input",
		description: "Indicates the state of a radio button. By default, no option will be preselected.",
	},
	{ number: 2, title: "Radio button label", description: "Describes the information you want to select or unselect." },
];

const UsageRadioComponent = () => {
	const { isLoading } = useGlobalContext();
	const tabData = ["Anatomy", "Alignment", "Placement", "Guidelines"];

	const [activeSideTab, setActiveSideTab] = useState(0);

	const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[0]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Anatomy
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							The radio button component is comprised of a set of clickable circles (the inputs) with text labels
							positioned to the right. If there is a group of radio buttons, a group label can be added.
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={radioDetail} alt="userimage" />
					</div>

					<ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
						{details.map((detail) => (
							<DetailItem
								key={detail.number}
								number={detail.number}
								title={detail.title}
								description={detail.description}
							/>
						))}
					</ul>

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Alignment
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							Radio button labels are positioned to the right of their inputs in languages that read left to right. If
							there is a radio button grouping, they can be laid out vertically or horizontally depending on the use
							case and the structure of the UI. When possible, arrange the radio button and checkbox groups vertically
							for easier reading.
						</p>
					</div>

					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center dis-p-0">
						<img src={radioAlignment} alt="userimage" />
					</div>

					<div className="dis-mt-40 dis-mb-40">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Placement</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								The radio button component is often used in forms. Forms can be placed on a full page, in a modal, or in
								a side panel. A radio button can also be used for changing settings in a menu or selecting a singular
								item in a data table.
							</p>
						</div>

						<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
							<img src={radioPlacement} alt="userimage" />
						</div>

						<div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap dis-mt-24">
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								Radio buttons in a form should be placed at least 32px (layout-03) below or before the next component.
								Spacing of 24px (layout-02) or 16px (layout-01) can also be used when space is more restricted or if the
								form is more complex.
							</p>

							<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
								<img src={radioPlacementImg} alt="userimage" />
							</div>

							<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
								<img src={radioPlacementlabel} alt="userimage" />
							</div>
						</div>
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Guidelines</h6>
						<ul className="plt-detail-list dis-mt-12">
							<li className="plt-body-md plt-font-color-primary">
								We recommend checkbox labels being fewer than three words.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								If you are tight on space, consider rewording the label. Do not truncate checkbox label text with an
								ellipsis.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Long labels may wrap to a second line, and this is preferable to truncation.
							</li>
							<li className="plt-body-md plt-font-color-primary">
								Text should wrap beneath the checkbox so the control and label are top aligned.
							</li>
						</ul>
					</div>

					<GuidelineCardRadio />
				</div>
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default UsageRadioComponent;
