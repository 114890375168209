import React, { useState, useEffect, useCallback } from "react";
import "./EditHeadingModal.scss";
import CommonModal from "components/Common/CommonModal";
import { toast } from "react-toastify";

const UploadFontModal = ({ isOpen, onRequestClose, onApply, title }) => {
  const [file, setFile] = useState();
  const [name, setName] = useState();

  const handleApply = () => {
    if (!name && !variant && !file) {
      toast.warning("Please enter fields");
      return;
    }
    let request = {
      family: name,
      variant: "400",
      file: file,
      type: "manual",
    };
    onApply(request);
    onRequestClose();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      onApply={handleApply}
      onCancelText='Cancel'
      onApplyText='Upload'
      bodyClassName='upload-font-modal'
    >
      <div className='plt-modal-body-content'>
        <div className='plt-select-dropdown-wrapper dis-ml-24 dis-mr-24'>
          <div className='upload-font-content d-flex align-items-center justify-content-between dis-mb-24 w-100'>
            <div className='plt-select-dropdown-wrapper w-100'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4'>
                Font name
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  type='text'
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className='plt-select-dropdown-wrapper'>
            <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4'>
              File
            </h6>
            <input
              type='file'
              accept='.woff, .woff2, .ttf, .otf'
              onChange={(e) => setFile(e.target.files[0])}
              className='input-field-custom'
            />
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default UploadFontModal;
