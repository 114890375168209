import React, { useState, useEffect, useCallback } from "react";
import "components/modal/EditHeadingModal.scss";
import CommonModal from "components/Common/CommonModal";

const RenameModal = ({ isOpen, onRequestClose, onApply, title, data }) => {
	const [name, setName] = useState("");
	useEffect(() => {
		setName(data?.design_system_name);
	}, [data]);

	const handleApply = () => {
		onApply(name);
	};

	return (
		<CommonModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={title}
			onApply={handleApply}
			Rename
			design
			system
			classPrefix="plt"
			onCancelText="Cancel"
			onApplyText="Rename"
			// bodyClassName='overflow-unset'
		>
			{/* <div className='plt-dropdown-secondary '>
        <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
          <div className='d-flex justify-content-center  align-items-center dis-p-20'>
            <div className='plt-token-details dis-mb-32'>
              <div className='plt-token-value-item d-flex align-items-center'>
                <p className='plt-badge  h-fit plt-token-value-name plt-body-compact-sm dis-mr-12'>
                  <span>Name</span>
                </p>
                <input
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className='input-field-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

			<div className="plt-modal-body-content">
				<div className="plt-select-dropdown-wrapper dis-pl-24 dis-pr-24">
					<h6 className="plt-select-dropdown-title plt-ui-text-sm-regular  plt-font-color-secondary dis-mb-4">Name</h6>
					<div className="plt-input-wrapper">
						<input
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							className="input-field-custom w-100"
						/>
					</div>
				</div>
			</div>
		</CommonModal>
	);
};

export default RenameModal;
