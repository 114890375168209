import React, { useState } from "react";

import "./PreviewModalComponent.scss";
import TabNavigation from "components/DesignSystemPage/TabNavigation";
import ComponentCard from "components/Common/ComponentCard";
import CommonModal from "components/Common/CommonModal";
import CustomSelect from "components/Common/CustomSelect";
import { ModalCloseIcon } from "components/Icons";
import PrimaryButton from "components/Common/PrimaryButton";
import { EditIcon } from "components/Icons";
import { ButtonIcon } from "components/Icons";

const PreviewModalComponent = ({
  tabData,
  handleTabClick,
  activeTab,
  setSelectedTab,
}) => {
  const AllUsage = () => (
    <p className='plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32'>
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className='plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer'
      >
        usage
      </span>
    </p>
  );

  const [isModalVisible, setModalVisibility] = useState(false);
  const showModal = () => {
    setModalVisibility(true);
  };

  const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
  const [buttonState, setButtonState] = useState(buttonStates[0]);

  return (
    <div className='tab-content-wrapper d-flex justify-content-between'>
      <div className='tab-content-container'>
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Default
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </p>
        </div>
        <div className='dis-mb-32'>
          <div className='plt-content-wrapper dis-mb-24'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
              Header main component
            </h6>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Use a primary button to call attention to an action on a form or
              to highlight the strongest call to action on a page.
            </p>
          </div>
          <ComponentCard editClick={showModal}>
            <div
              className={`plt-modal-overlay`}
              style={{
                // width: "100%",
                position: "relative",
                padding: "24px",
                zIndex: 0,
                background: "#bdbdbd",
              }}
            >
              <div className='dss-modal-content'>
                <div
                  className='dss-modal-header'
                  style={{
                    borderBottom: 0,
                  }}
                >
                  <div className='dss-modal-header-leading'>
                    <span className={`dss-modal-header-icon prefix-icon`}>
                      <ButtonIcon color='var(--dss-color-icon-modal-primary)' />
                    </span>
                    <h5 className='dss-modal-header-title dss-font-color-primary'>
                      Title text
                    </h5>
                  </div>
                  <span className='dss-modal-close-icon dss-iconbtn'>
                    <ModalCloseIcon />
                  </span>
                </div>
              </div>
            </div>
          </ComponentCard>
          <AllUsage />
        </div>
        <div className='dis-mb-32'>
          <div className='plt-content-wrapper dis-mb-24'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
              Body main component
            </h6>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Use a primary button to call attention to an action on a form or
              to highlight the strongest call to action on a page.
            </p>
          </div>
          <ComponentCard editClick={showModal}>
            <div
              className={`plt-modal-overlay`}
              style={{
                width: "100%",
                position: "relative",
                padding: "24px",
                zIndex: 0,
                background: "#bdbdbd",
              }}
            >
              <div className='dss-modal-body'>
                <div className='dss-modal-body-content'>
                  <div className='dss-preview'>modal-body</div>
                </div>
              </div>
            </div>
          </ComponentCard>
          <AllUsage />
        </div>
        <div className='dis-mb-32'>
          <div className='plt-content-wrapper dis-mb-24'>
            <h6 className='plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8'>
              Footer
            </h6>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Use a primary button to call attention to an action on a form or
              to highlight the strongest call to action on a page.
            </p>
          </div>
          <ComponentCard editClick={showModal}>
            <div
              className={`plt-modal-overlay`}
              style={{
                width: "100%",
                position: "relative",
                padding: "24px",
                zIndex: 0,
                background: "#bdbdbd",
              }}
            >
              <div
                className='dss-modal-footer'
                style={{
                  borderTop: 0,
                }}
              >
                <PrimaryButton
                  // className={`dss-btn dss-btn-secondary dss-ui-text-md-medium `}
                  text='Cancel'
                  variant='secondary'
                  size='md'
                  // onClick={onRequestClose}
                />
                <PrimaryButton
                  // className={`dss-btn dss-btn-primary dss-ui-text-md-medium `}
                  text='Apply'
                  variant='primary'
                  size='md'
                  // onClick={onApply}
                />
              </div>
            </div>
          </ComponentCard>
          <AllUsage />
        </div>
      </div>

      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title='Tokens'
        onApply={() => setModalVisibility(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
        variant='modal-tertiary'
      >
        <div className='dss-modal-body-content'>
          <div className='plt-dropdown-secondary'>
            <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
              <div className='plt-dropdown-wrapper plt-dropdown-wrapper-common'>
                <CustomSelect
                  options={buttonStates}
                  selectedOption={buttonState}
                />
              </div>
            </div>
          </div>
          <div className='dss-preview plt-heading-xl dis-mb-32'></div>
          <div className='plt-token-details dis-mb-32'>
            <h6 className='plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4'>
              Padding
            </h6>
            <div className='plt-token-value-item d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                <span className='dis-mr-4'>H:</span>
                <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                  spacing-200
                </p>
              </div>
              <div className='d-flex align-items-center'>
                <span className='dis-mr-4'>V:</span>
                <p className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12'>
                  spacing-200
                </p>
              </div>

              <span className='plt-token-value-icon'>
                <EditIcon />
              </span>
            </div>
          </div>
        </div>
      </CommonModal>
      <div className='plt-token-contents'>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewModalComponent;
