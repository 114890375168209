import React, { useRef, useState } from "react";
import "./Inputs.scss";
import { ButtonIconSample, ErrorIcon } from "../Icons";
import Tag from "components/Tag";

const Inputs = ({
	placeholder = "value",
	disabled,
	readOnlyState,
	denseClassName,
	className,
	value,
	inputId,
	readOnly,
	tailingIcon,
	leadingIcon,
	prefix,
	suffix,
	fluid,
	label,
	validation = true,
	tag = false,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [isFocus, setFocus] = useState(false);
	const [error, setError] = useState(null);
	const inputRef = useRef(null);
	const handleFocus = () => {
		if (!disabled && !tag) {
			// if (!disabled) {
			setFocus(true);
			inputRef?.current?.focus();
		}
	};
	const handleBlur = () => {
		setFocus(false);
		if (validation && inputValue == null) {
			setError(true);
		} else if (validation) {
			validateInput(inputValue);
		}
	};
	const handleChange = (e) => {
		setInputValue(e.target.value);
		if (validation) {
			validateInput(e.target.value);
		}
	};
	const validateInput = (value) => {
		if (value.length < 1) {
			setError("Input must be at least 5 characters long");
		} else {
			setError(null);
		}
	};
	return (
		<div
			className={`dss-input-wrapper dss-input ${denseClassName} ${className ? className : ""} ${fluid ? "dss-input-fluid" : ""}  ${disabled ? "disabled" : ""} ${readOnlyState ? "read-only" : ""}`}
		>
			<div className="dss-input-label-wrapper">
				{!fluid && (
					<label htmlFor={inputId} className="dss-input-label">
						{label}
					</label>
				)}
				<div
					onClick={handleFocus}
					className={`dss-input-container ${inputValue?.length ? "has-value" : ""} ${isFocus ? "focused" : "blur"} ${error ? "error" : ""}
            ${prefix ? "prefix" : ""} ${leadingIcon ? "leadingicon" : ""} ${tag ? "withtag" : ""}
            `}
				>
					<div className="dss-input-leading">
						{leadingIcon == true ? (
							<span className="dss-input-icon">
								<ButtonIconSample color="#171717" width="16px" height="16px" />
							</span>
						) : (
							leadingIcon
						)}
						{fluid && (
							<label htmlFor={inputId} className="dss-input-label">
								{label}
							</label>
						)}
						{prefix == true ? <p className="dss-prefix-text">Prefix</p> : prefix}
						{tag ? (
							<Tag
								size="small"
								prefixIcon={true}
								statusIcon={false}
								mode={"closable"}
								varient="labelCloseicon"
								disable={disabled}
							/>
						) : (
							<input
								ref={inputRef}
								readOnly={readOnly || tag}
								onFocus={handleFocus}
								onBlur={handleBlur}
								onChange={handleChange}
								id={inputId}
								value={inputValue}
								disabled={disabled}
								type="text"
								placeholder={fluid ? "" : placeholder}
								className="dss-input-field"
							/>
						)}
					</div>
					<div className="dss-input-trailing d-flex ml-auto">
						{suffix == true ? <p className="dss-suffix-text">Suffix</p> : suffix}
						{tailingIcon == true ? (
							<span className="dss-input-icon">
								<ButtonIconSample color="#171717" width="16px" height="16px" />
							</span>
						) : (
							tailingIcon
						)}
					</div>
				</div>
			</div>
			<div className={`dss-error-container ${error && "active"}`}>
				<span className="dss-error-icon">
					<ErrorIcon />
				</span>
				<h6 className="dss-error-message">Helpful validation message</h6>
			</div>
		</div>
	);
};

export default Inputs;
