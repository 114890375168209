import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getComponentsSideData } from "assets/data/sideTabData";
import { useGlobalContext } from "context/GlobalContext";
// import TokenButtonComponent from "components/DesignSystemPage/ButtonComponent/TokenButtonComponent";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import PreviewButtonComponent from "components/DesignSystemPage/ButtonComponent/PreviewButtonComponent";
import UsageButtonComponent from "components/DesignSystemPage/ButtonComponent/UsageButtonComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";
import { buttonTokenTabData } from "assets/data/tokenTabData";

const DesignSystemComponents = () => {
  const { id } = useParams();
  const { designSystemMeta, designSystem } = useGlobalContext();

  const componentName = "button";
    const componentKey = `Component/${componentName}`;
    const componentData = designSystem[componentKey];
    const tokenTableData = buttonTokenTabData(componentData, componentName);

  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const bannerData = {
    heading: "Buttons",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <PreviewButtonComponent setSelectedTab={setSelectedTab} />;
      case 1:
        return <UsageButtonComponent />;
      case 2:
        return <TokenTableComponent tokenTableData={tokenTableData} />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title='Components'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default DesignSystemComponents;
