import React, { useState, useCallback, useEffect } from "react";
import "components/modal/EditHeadingModal.scss";
import CommonModal from "components/Common/CommonModal";
import FileInput from "components/FileInput"; // Adjust the import path as necessary
import Cropper from "react-easy-crop";
import { getCroppedImg } from "utils/cropImage"; // Correctly import the function
import "./ThumbnailModal.scss"; // Import your custom styles

const ThumbnailModal = ({ isOpen, onRequestClose, onApply, title, aspectRatio, selectedType, error: initialError }) => {
	const [file, setFile] = useState(null);
	const [error, setError] = useState(initialError);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [imageSrc, setImageSrc] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const handleApply = async () => {
		if (!error && croppedAreaPixels) {
			const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
			onApply(croppedImage);
		}
	};

	const handleFileChange = (selectedFile) => {
		if (!selectedFile) {
			setError("File size should be less than 5MB.");
		} else {
			const reader = new FileReader();
			reader.readAsDataURL(selectedFile);
			reader.onload = () => {
				setImageSrc(reader.result);
				setFile(selectedFile);
				setError(null);
			};
		}
	};

	useEffect(() => {
		if (!isOpen) {
			// Clear the state when the modal is closed
			setFile(null);
			setError(initialError);
			setCrop({ x: 0, y: 0 });
			setZoom(1);
			setCroppedAreaPixels(null);
			setImageSrc(null);
		}
	}, [isOpen, initialError]);

	return (
		<CommonModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={title}
			onApply={handleApply}
			classPrefix={`${selectedType === "thumbnail" ? "plt" : "dss"}`}
			onCancelText="Cancel"
			onApplyText="Change image"
			bodyClassName="dis-pl-24 dis-pr-24"
		>
			<div className="modal-content">
				<div className="file-input-container">
					<h6 className="modal-title">Select file</h6>
					<FileInput accept="image/*" maxSize={5 * 1024 * 1024} onFileChange={handleFileChange} error={error} />
				</div>
				{imageSrc && (
					<div className="crop-container">
						<Cropper
							image={imageSrc}
							crop={crop}
							zoom={zoom}
							aspect={aspectRatio} // Set aspect ratio from props
							onCropChange={setCrop}
							onZoomChange={setZoom}
							onCropComplete={onCropComplete}
						/>
					</div>
				)}
			</div>
		</CommonModal>
	);
};

export default ThumbnailModal;
