// stylesSideTabData.js

export const getStylesSideTabData = (id) => {
	return [
		{ title: "Colors", link: `/${id}/styles/colors` },
		{ title: "Typography", link: `/${id}/styles/typography` },
		{ title: "Spacing", link: `/${id}/styles/spacing` },
		// { title: "Grid", link: `/${id}/styles/grid` },
		{ title: "Border Radius", link: `/${id}/styles/radius` },
		{ title: "Elevation", link: `/${id}/styles/elevation` },
		{ title: "Iconography", link: `/${id}/styles/iconography` },
		{ title: "Accessibility", link: `/${id}/styles/accessibility` },
		{ title: "Core tokens", link: `/${id}/styles/tokens` },
	];
};

export const getComponentsSideData = (id) => {
	return [
		{ title: "Alert", link: `/${id}/components/alert` },
		{ title: "Avatar", link: `/${id}/components/avatar` },
		{ title: "Avatar Group", link: `/${id}/components/avatarGroup` },
		{ title: "Button", link: `/${id}/components/button` },
		{ title: "Check box", link: `/${id}/components/checkbox` },
		{ title: "Empty State", link: `/${id}/components/emptyState` },
		{ title: "Inputs", link: `/${id}/components/inputs` },
		{ title: "Modal", link: `/${id}/components/modal` },
		{ title: "Pagination", link: `/${id}/components/pagination` },
		{ title: "Radio button", link: `/${id}/components/radio` },
		{ title: "Table", link: `/${id}/components/table` },
		{ title: "Tag", link: `/${id}/components/tag` },
		{ title: "Tabs", link: `/${id}/components/tabs` },
		{ title: "Textarea", link: `/${id}/components/textarea` },
		{ title: "Toggle", link: `/${id}/components/toggles` },
		{ title: "Toaster", link: `/${id}/components/toaster` },
		{ title: "Tooltip", link: `/${id}/components/tooltip` },
		// { title: "Menu", link: `/${id}/components/menu` },
	];
};

export const getPatternsSideData = (id) => {
	return [{ title: "Forms", link: `/${id}/patterns/forms` }];
};
