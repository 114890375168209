export const primaryColorShade = [
  {
    key: "{primary.100}",
    value: "#EFE7F5",
    varName: "--dss-color-primary-100",
    tokenName: "core.primary.100",
  },
  {
    key: "{primary.200}",
    value: "#CFB3DF",
    varName: "--dss-color-primary-200",
    tokenName: "core.primary.200",
  },
  {
    key: "{primary.300}",
    value: "#B78FD0",
    varName: "--dss-color-primary-300",
    tokenName: "core.primary.300",
  },
  {
    key: "{primary.400}",
    value: "#965CBB",
    varName: "--dss-color-primary-400",
    tokenName: "core.primary.400",
  },
  {
    key: "{primary.500}",
    value: "#823CAD",
    varName: "--dss-color-primary-500",
    tokenName: "core.primary.500",
  },
  {
    key: "{primary.600}",
    value: "#630B99",
    varName: "--dss-color-primary-600",
    tokenName: "core.primary.600",
  },
  {
    key: "{primary.700}",
    value: "#5A0A8B",
    varName: "--dss-color-primary-700",
    tokenName: "core.primary.700",
  },
  {
    key: "{primary.800}",
    value: "#46086D",
    varName: "--dss-color-primary-800",
    tokenName: "core.primary.800",
  },
  {
    key: "{primary.900}",
    value: "#360654",
    varName: "--dss-color-primary-900",
    tokenName: "core.primary.900",
  },
  {
    key: "{primary.1000}",
    value: "#2A0540",
    varName: "--dss-color-primary-1000",
    tokenName: "core.primary.1000",
  },
];

export const secondaryColorShade = [
  {
    key: "{secondary.100}",
    value: "#FFE1D7",
    varName: "--dss-color-secondary-100",
    tokenName: "core.secondary.100",
  },
  {
    key: "{secondary.200}",
    value: "#FFA689",
    varName: "--dss-color-secondary-200",
    tokenName: "core.secondary.200",
  },
  {
    key: "{secondary.300}",
    value: "#FF9A79",
    varName: "--dss-color-secondary-300",
    tokenName: "core.secondary.300",
  },
  {
    key: "{secondary.400}",
    value: "#FF8B64",
    varName: "--dss-color-secondary-400",
    tokenName: "core.secondary.400",
  },
  {
    key: "{secondary.500}",
    value: "#FF794D",
    varName: "--dss-color-secondary-500",
    tokenName: "core.secondary.500",
  },
  {
    key: "{secondary.600}",
    value: "#FF6D3E",
    varName: "--dss-color-secondary-600",
    tokenName: "core.secondary.600",
  },
  {
    key: "{secondary.700}",
    value: "#FF622F",
    varName: "--dss-color-secondary-700",
    tokenName: "core.secondary.700",
  },
  {
    key: "{secondary.800}",
    value: "#FC4A0F",
    varName: "--dss-color-secondary-800",
    tokenName: "core.secondary.800",
  },
  {
    key: "{secondary.900}",
    value: "#ED3B00",
    varName: "--dss-color-secondary-900",
    tokenName: "core.secondary.900",
  },
  {
    key: "{secondary.1000}",
    value: "#E03700",
    varName: "--dss-color-secondary-1000",
    tokenName: "core.secondary.1000",
  },
];

export const supportingColorShade1 = [
  {
    key: "{supportingColor1.100}",
    value: "#E7F1FC",
    varName: "--dss-color-supporting-color1-100",
    tokenName: "core.supporting.color1.100",
  },
  {
    key: "{supportingColor1.200}",
    value: "#B5D5F7",
    varName: "--dss-color-supporting-color1-200",
    tokenName: "core.supporting.color1-200",
  },
  {
    key: "{supportingColor1.300}",
    value: "#92C0F3",
    varName: "--dss-color-supporting-color1-300",
    tokenName: "core.supporting.color1-300",
  },
  {
    key: "{supportingColor1.400}",
    value: "#60A4ED",
    varName: "--dss-color-supporting-color1-400",
    tokenName: "core.supporting.color1-400",
  },
  {
    key: "{supportingColor1.500}",
    value: "#4192E9",
    varName: "--dss-color-supporting-color1-500",
    tokenName: "core.supporting.color1-500",
  },
  {
    key: "{supportingColor1.600}",
    value: "#1177E4",
    varName: "--dss-color-supporting-color1-600",
    tokenName: "core.supporting.color1-600",
  },
  {
    key: "{supportingColor1.700}",
    value: "#0F6CCF",
    varName: "--dss-color-supporting-color1-700",
    tokenName: "core.supporting.color1-700",
  },
  {
    key: "{supportingColor1.800}",
    value: "#0C54A2",
    varName: "--dss-color-supporting-color1-800",
    tokenName: "core.supporting.color1-800",
  },
  {
    key: "{supportingColor1.900}",
    value: "#09417D",
    varName: "--dss-color-supporting-color1-900",
    tokenName: "core.supporting.color1-900",
  },
  {
    key: "{supportingColor1.1000}",
    value: "#073260",
    varName: "--dss-color-supporting-color1-1000",
    tokenName: "core.supporting.color1-1000",
  },
];

export const supportingColorShade2 = [
  {
    key: "{supportingColor2.100}",
    value: "#FFFAEC",
    varName: "--dss-color-supporting-color2-100",
    tokenName: "core.supporting.color2-100",
  },
  {
    key: "{supportingColor2.200}",
    value: "#F3E1B2",
    varName: "--dss-color-supporting-color2-200",
    tokenName: "core.supporting.color2-200",
  },
  {
    key: "{supportingColor2.300}",
    value: "#EDD28D",
    varName: "--dss-color-supporting-color2-300",
    tokenName: "core.supporting.color2-300",
  },
  {
    key: "{supportingColor2.400}",
    value: "#E5BD5A",
    varName: "--dss-color-supporting-color2-400",
    tokenName: "core.supporting.color2-400",
  },
  {
    key: "{supportingColor2.500}",
    value: "#E0B139",
    varName: "--dss-color-supporting-color2-500",
    tokenName: "core.supporting.color2-500",
  },
  {
    key: "{supportingColor2.600}",
    value: "#D89D08",
    varName: "--dss-color-supporting-color2-600",
    tokenName: "core.supporting.color2-600",
  },
  {
    key: "{supportingColor2.700}",
    value: "#C58F07",
    varName: "--dss-color-supporting-color2-700",
    tokenName: "core.supporting.color2-700",
  },
  {
    key: "{supportingColor2.800}",
    value: "#996F06",
    varName: "--dss-color-supporting-color2-800",
    tokenName: "core.supporting.color2-800",
  },
  {
    key: "{supportingColor2.900}",
    value: "#775604",
    varName: "--dss-color-supporting-color2-900",
    tokenName: "core.supporting.color2-900",
  },
  {
    key: "{supportingColor2.1000}",
    value: "#5B4203",
    varName: "--dss-color-supporting-color2-1000",
    tokenName: "core.supporting.color2-1000",
  },
];

export const supportingColorShade3 = [
  {
    key: "{supportingColor3.100}",
    value: "#FFEEF5",
    varName: "--dss-color-supporting-color3-100",
    tokenName: "dss-supporting-color3-100",
  },
  {
    key: "{supportingColor3.200}",
    value: "#FECADF",
    varName: "--dss-color-supporting-color3-200",
    tokenName: "dss-supporting-color3-200",
  },
  {
    key: "{supportingColor3.300}",
    value: "#FEB0D0",
    varName: "--dss-color-supporting-color3-300",
    tokenName: "dss-supporting-color3-300",
  },
  {
    key: "{supportingColor3.400}",
    value: "#FE8CBA",
    varName: "--dss-color-supporting-color3-400",
    tokenName: "dss-supporting-color3-400",
  },
  {
    key: "{supportingColor3.500}",
    value: "#FD76AD",
    varName: "--dss-color-supporting-color3-500",
    tokenName: "dss-supporting-color3-500",
  },
  {
    key: "{supportingColor3.600}",
    value: "#FD5498",
    varName: "--dss-color-supporting-color3-600",
    tokenName: "dss-supporting-color3-600",
  },
  {
    key: "{supportingColor3.700}",
    value: "#E64C8A",
    varName: "--dss-color-supporting-color3-700",
    tokenName: "dss-supporting-color3-700",
  },
  {
    key: "{supportingColor3.800}",
    value: "#B43C6C",
    varName: "--dss-color-supporting-color3-800",
    tokenName: "dss-supporting-color3-800",
  },
  {
    key: "{supportingColor3.900}",
    value: "#8B2E54",
    varName: "--dss-color-supporting-color3-900",
    tokenName: "dss-supporting-color3-900",
  },
  {
    key: "{supportingColor3.1000}",
    value: "#6A2340",
    varName: "--dss-color-supporting-color3-1000",
    tokenName: "dss-supporting-color3-1000",
  },
];

export const supportingColorShade4 = [
  {
    key: "{supportingColor4.100}",
    value: "#FFEEF5",
    varName: "--dss-color-supporting-color4-100",
    tokenName: "dss-supporting-color4-100",
  },
  {
    key: "{supportingColor3.200}",
    value: "#FECADF",
    varName: "--dss-color-supporting-color3-200",
    tokenName: "dss-supporting-color3-200",
  },
  {
    key: "{supportingColor3.300}",
    value: "#FEB0D0",
    varName: "--dss-color-supporting-color3-300",
    tokenName: "dss-supporting-color3-300",
  },
  {
    key: "{supportingColor3.400}",
    value: "#FE8CBA",
    varName: "--dss-color-supporting-color3-400",
    tokenName: "dss-supporting-color3-400",
  },
  {
    key: "{supportingColor3.500}",
    value: "#FD76AD",
    varName: "--dss-color-supporting-color3-500",
    tokenName: "dss-supporting-color3-500",
  },
  {
    key: "{supportingColor3.600}",
    value: "#FD5498",
    varName: "--dss-color-supporting-color3-600",
    tokenName: "dss-supporting-color3-600",
  },
  {
    key: "{supportingColor3.700}",
    value: "#E64C8A",
    varName: "--dss-color-supporting-color3-700",
    tokenName: "dss-supporting-color3-700",
  },
  {
    key: "{supportingColor3.800}",
    value: "#B43C6C",
    varName: "--dss-color-supporting-color3-800",
    tokenName: "dss-supporting-color3-800",
  },
  {
    key: "{supportingColor3.900}",
    value: "#8B2E54",
    varName: "--dss-color-supporting-color3-900",
    tokenName: "dss-supporting-color3-900",
  },
  {
    key: "{supportingColor3.1000}",
    value: "#6A2340",
    varName: "--dss-color-supporting-color3-1000",
    tokenName: "dss-supporting-color3-1000",
  },
];

export const neutralColorShade = [
  {
    key: "{neutrals.100}",
    value: "#FFFFFF",
    varName: "--dss-color-neutrals-100",
    tokenName: "dss-neutrals-100",
  },
  {
    key: "{neutrals.200}",
    value: "#F6F6F6",
    varName: "--dss-color-neutrals-200",
    tokenName: "dss-neutrals-200",
  },
  {
    key: "{neutrals.300}",
    value: "#F0F0F0",
    varName: "--dss-color-neutrals-300",
    tokenName: "dss-neutrals-300",
  },
  {
    key: "{neutrals.400}",
    value: "#DDDDDD",
    varName: "--dss-color-neutrals-400",
    tokenName: "dss-neutrals-400",
  },
  {
    key: "{neutrals.500}",
    value: "#A6A6A6",
    varName: "--dss-color-neutrals-500",
    tokenName: "dss-neutrals-500",
  },
  {
    key: "{neutrals.600}",
    value: "#585858",
    varName: "--dss-color-neutrals-600",
    tokenName: "dss-neutrals-600",
  },
  {
    key: "{neutrals.700}",
    value: "#434343",
    varName: "--dss-color-neutrals-700",
    tokenName: "dss-neutrals-700",
  },
  {
    key: "{neutrals.800}",
    value: "#353535",
    varName: "--dss-color-neutrals-800",
    tokenName: "dss-neutrals-800",
  },
  {
    key: "{neutrals.900}",
    value: "#1F1F1F",
    varName: "--dss-color-neutrals-900",
    tokenName: "dss-neutrals-900",
  },
  {
    key: "{neutrals.1000}",
    value: "#171717",
    varName: "--dss-color-neutrals-1000",
    tokenName: "dss-neutrals-1000",
  },
];

export const negativeColorShade = [
  {
    key: "{semantics.negative.100}",
    value: "#FAEDEC",
    varName: " --dss-color-semantics-negative-100",
    tokenName: "core.semantics.neutrals-100",
  },
  {
    key: "{semantics.negative.200}",
    value: "#F5D9D9",
    varName: " --dss-color-semantics-negative-200",
    tokenName: "core.semantics.neutrals-200",
  },
  {
    key: "{semantics.negative.300}",
    value: "#F0C6C6",
    varName: " --dss-color-semantics-negative-300",
    tokenName: "core.semantics.neutrals-300",
  },
  {
    key: "{semantics.negative.400}",
    value: "#EBB3B3",
    varName: " --dss-color-semantics-negative-400",
    tokenName: "core.semantics.neutrals-400",
  },
  {
    key: "{semantics.negative.500}",
    value: "#E6A0A0",
    varName: " --dss-color-semantics-negative-500",
    tokenName: "core.semantics.neutrals-500",
  },
  {
    key: "{semantics.negative.600}",
    value: "#E18D8D",
    varName: " --dss-color-semantics-negative-600",
    tokenName: "core.semantics.neutrals-600",
  },
  {
    key: "{semantics.negative.700}",
    value: "#DC7A7A",
    varName: " --dss-color-semantics-negative-700",
    tokenName: "core.semantics.neutrals-700",
  },
  {
    key: "{semantics.negative.800}",
    value: "#D76767",
    varName: " --dss-color-semantics-negative-800",
    tokenName: "core.semantics.neutrals-800",
  },
  {
    key: "{semantics.negative.900}",
    value: "#D25454",
    varName: " --dss-color-semantics-negative-900",
    tokenName: "core.semantics.neutrals-900",
  },
  {
    key: "{semantics.negative.1000}",
    value: "#CD4141",
    varName: " --dss-color-semantics-negative-1000",
    tokenName: "core.semantics.neutrals-1000",
  },
];

export const cautionColorShade = [
  {
    key: "{semantics.caution.100}",
    value: "#FAEDEC",
    varName: " --dss-color-semantics-caution-100",
    tokenName: "core.semantics.caution-100",
  },
  {
    key: "{semantics.caution.200}",
    value: "#F5E2C8",
    varName: " --dss-color-semantics-caution-200",
    tokenName: "core.semantics.caution-200",
  },
  {
    key: "{semantics.caution.300}",
    value: "#F0D7A4",
    varName: " --dss-color-semantics-caution-300",
    tokenName: "core.semantics.caution-300",
  },
  {
    key: "{semantics.caution.400}",
    value: "#EBCD80",
    varName: " --dss-color-semantics-caution-400",
    tokenName: "core.semantics.caution-400",
  },
  {
    key: "{semantics.caution.500}",
    value: "#E6C25C",
    varName: " --dss-color-semantics-caution-500",
    tokenName: "core.semantics.caution-500",
  },
  {
    key: "{semantics.caution.600}",
    value: "#E1B738",
    varName: " --dss-color-semantics-caution-600",
    tokenName: "core.semantics.caution-600",
  },
  {
    key: "{semantics.caution.700}",
    value: "#DCAC14",
    varName: " --dss-color-semantics-caution-700",
    tokenName: "core.semantics.caution-700",
  },
  {
    key: "{semantics.caution.800}",
    value: "#D7A200",
    varName: " --dss-color-semantics-caution-800",
    tokenName: "core.semantics.caution-800",
  },
  {
    key: "{semantics.caution.900}",
    value: "#D29800",
    varName: " --dss-color-semantics-caution-900",
    tokenName: "core.semantics.caution-900",
  },
  {
    key: "{semantics.caution.1000}",
    value: "#CD8E00",
    varName: " --dss-color-semantics-caution-1000",
    tokenName: "core.semantics.caution-1000",
  },
];

export const positiveColorShade = [
  {
    key: "{semantics.positive.100}",
    value: "#E6F4EA",
    varName: " --dss-color-semantics-positive-100",
    tokenName: "core.semantics.positive.100",
  },
  {
    key: "{semantics.positive.200}",
    value: "#CCE9D6",
    varName: " --dss-color-semantics-positive-200",
    tokenName: "core.semantics.positive.200",
  },
  {
    key: "{semantics.positive.300}",
    value: "#B2DEC1",
    varName: " --dss-color-semantics-positive-300",
    tokenName: "core.semantics.positive.300",
  },
  {
    key: "{semantics.positive.400}",
    value: "#98D3AD",
    varName: " --dss-color-semantics-positive-400",
    tokenName: "core.semantics.positive.400",
  },
  {
    key: "{semantics.positive.500}",
    value: "#7EC898",
    varName: " --dss-color-semantics-positive-500",
    tokenName: "core.semantics.positive.500",
  },
  {
    key: "{semantics.positive.600}",
    value: "#64BD83",
    varName: " --dss-color-semantics-positive-600",
    tokenName: "core.semantics.positive.600",
  },
  {
    key: "{semantics.positive.700}",
    value: "#4AB26E",
    varName: " --dss-color-semantics-positive-700",
    tokenName: "core.semantics.positive.700",
  },
  {
    key: "{semantics.positive.800}",
    value: "#30A759",
    varName: " --dss-color-semantics-positive-800",
    tokenName: "core.semantics.positive.800",
  },
  {
    key: "{semantics.positive.900}",
    value: "#169D44",
    varName: " --dss-color-semantics-positive-900",
    tokenName: "core.semantics.positive.900",
  },
  {
    key: "{semantics.positive.1000}",
    value: "#00892F",
    varName: " --dss-color-semantics-positive-1000",
    tokenName: "core.semantics.positive.1000",
  },
];

export const informativeColorShade = [
  {
    key: "{semantics.informative.100}",
    value: "#E6F0FA",
    varName: " --dss-color-semantics-informative-100",
    tokenName: "core.semantics.informative.100",
  },
  {
    key: "{semantics.informative.200}",
    value: "#CCE1F5",
    varName: " --dss-color-semantics-informative-200",
    tokenName: "core.semantics.informative.200",
  },
  {
    key: "{semantics.informative.300}",
    value: "#B2D2F0",
    varName: " --dss-color-semantics-informative-300",
    tokenName: "core.semantics.informative.300",
  },
  {
    key: "{semantics.informative.400}",
    value: "#98C3EB",
    varName: " --dss-color-semantics-informative-400",
    tokenName: "core.semantics.informative.400",
  },
  {
    key: "{semantics.informative.500}",
    value: "#7EB4E6",
    varName: " --dss-color-semantics-informative-500",
    tokenName: "core.semantics.informative.500",
  },
  {
    key: "{semantics.informative.600}",
    value: "#64A5E1",
    varName: " --dss-color-semantics-informative-600",
    tokenName: "core.semantics.informative.600",
  },
  {
    key: "{semantics.informative.700}",
    value: "#4A96DC",
    varName: " --dss-color-semantics-informative-700",
    tokenName: "core.semantics.informative.700",
  },
  {
    key: "{semantics.informative.800}",
    value: "#3087D7",
    varName: " --dss-color-semantics-informative-800",
    tokenName: "core.semantics.informative.800",
  },
  {
    key: "{semantics.informative.900}",
    value: "#1678D2",
    varName: " --dss-color-semantics-informative-900",
    tokenName: "core.semantics.informative.900",
  },
  {
    key: "{semantics.informative.1000}",
    value: "#0069CD",
    varName: " --dss-color-semantics-informative-1000",
    tokenName: "core.semantics.informative.1000",
  },
];
