import React from "react";
import "./ToggleComponent.scss";

const ToggleComponent = ({ hasLabel, size, checked, disabled, token, onChange,toggletext, className }) => {
	return (
		<label className={`dss-toggler dss-toggler-${size}  ${className ? className : ''}`}>
			<div className="dss-toggler-elem">
				<input
					className="dss-toggler-toggler-input"
					type="checkbox"
					checked={checked}
					onChange={onChange}
					disabled={disabled}
				/>
			</div>
			{hasLabel && <span className={`dss-toggler-text dss-font-toggle-uitext-${token}`}>{toggletext}</span>}
		</label>
	);
};

export default ToggleComponent;
