import React from "react";
import inputguidelines1 from "assets/images/png/inputguidelines1.png";
import inputguidelines2 from "assets/images/png/inputguidelines2.png";
import inputguidelines3 from "assets/images/png/inputguidelines3.png";
import inputguidelines4 from "assets/images/png/inputguidelines4.png";

const ComponentPrimaryDatas = [
	{
		id: 1,
		title: "Do",
		titleClassname: "active",
		image: inputguidelines1,
		description:
			"Place labels to the left of text input fields, ensuring they are aligned with the top of the field for clarity. Maintain consistent spacing between the label and input field.",
	},
	{
		id: 2,
		title: "Don’t",
		titleClassname: "inactive",
		image: inputguidelines2,
		description:
			"Avoid placing labels above or below the text field, and do not misalign labels to the left. Ensure labels are not too close or too far from the input fields.",
	},
	{
		id: 3,
		title: "Do",
		titleClassname: "active",
		image: inputguidelines3,
		description:
			"Place labels to the left and align them with the top of text fields, ensuring consistent spacing between the label and input field for clear identification and a tidy appearance.",
	},
	{
		id: 4,
		title: "Don’t",
		titleClassname: "inactive",
		image: inputguidelines4,
		description:
			"Avoid placing labels above or below the text field, and do not misalign labels to the left. Ensure labels are not too close or too far from the input fields.",
	},
];

const GuidelineCardInput = () => {
	return (
		<div className="guideline-card d-flex justify-content-between">
			{ComponentPrimaryDatas.map((data) => (
				<div key={data.id} className="guideline-wrapper">
					<h6 className={`guideline-badge plt-body-compact-md dis-mb-8 ${data.titleClassname}`}>{data.title}</h6>
					<div className="guideline-detail-wrapper d-flex align-items-center justify-content-center">
						<div className="guideline-img">
							<img src={data.image} alt="userimage" />
						</div>
					</div>
					<p className="guideline-desc plt-body-md dis-mt-12">{data.description}</p>
				</div>
			))}
		</div>
	);
};


export default GuidelineCardInput;
