import React, { useEffect, useState } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";

import { tokenDropdown, uiTextTokens } from "api";
import {
  ComponentBtnDatas,
  ComponentDangerDatas,
  ComponentFluidDatas,
  ComponentGostDatas,
  ComponentLinkDatas,
  ComponentOutlineDatas,
  ComponentPrimaryDatas,
  ComponentSecondaryDatas,
  FillBtnDatas,
  FillIconBtnDatas,
} from "assets/data/data";
import TabNavigation from "components/DesignSystemPage/TabNavigation";
import "./PreviewButtonComponent.scss";

import ColorTokenModal from "components/TokenModals/ColorTokenModal";
import PaddingTokenModal from "components/TokenModals/PaddingTokenModal";
import RadiusTokenModal from "components/TokenModals/RadiusTokenModal";
import TypoTokenModal from "components/TokenModals/TypoTokenModal";

import ShadowTokenModal from "components/TokenModals/ShadowTokenModal";
import TextTokenModal from "components/TokenModals/TextTokenModal";
import { getCssVariableValue } from "helpers/style";
import { updateCSSVariablesAllObj } from "helpers/token";
import { getSpacingValues } from "helpers";
import { addToLocalStorage } from "hooks/storageUtils";

import useTokensData from "assets/data/buttonTokenData";
import useScrollManagement from "hooks/useScrollManagement";
import colorSectionData from "pages/styles/colorSectionData";

import { buttonStates, textdecoration, buttonTabData } from "constants";
import ButtonComponentCard from "./ButtonComponentCard";
import ButtonEditModal from "./ButtonEditModal";

const PreviewButtonComponent = ({ setSelectedTab }) => {
  const { id } = useParams();
  const { isLoading, setButtonSelectedColor, designSystem } =
    useGlobalContext();
  const colorSections = colorSectionData();

  const [selectedTokenData, setSelectedTokenData] = useState({});
  const [selectedtextdecoration, setSelectedtextdecoration] = useState(
    textdecoration[0]
  );
  const [activeSideTab, setActiveSideTab] = useState(0);
  const [spaceHValue, setSpaceHValue] = useState({ label: "", value: "" });
  const [spaceVValue, setSpaceVValue] = useState({ label: "", value: "" });
  const tokensData = useTokensData();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [buttonState, setButtonState] = useState(() => buttonStates[0]);
  const [buttonSize, setButtonSize] = useState();
  const [isColorTokenModalOpen, setColorTokenModalOpen] = useState(false);
  const [isPaddingTokenModalOpen, setPaddingTokenModalOpen] = useState(false);
  const [isShadowTokenModalOpen, setShadowTokenModalOpen] = useState(false);
  const [isTextTokenModalOpen, setTextTokenModalOpen] = useState(false);
  const [isTypoTokenModalOpen, setTypoTokenModalOpen] = useState(false);
  const [tokenType, setTokenType] = useState("");
  const [isRadiusTokenModalOpen, setRadiusTokenModalOpen] = useState(false);
  const [tokenState, setTokenState] = useState({ key: "", value: "" });
  const [selectedClassName, setSelectedClassName] = useState("");
  const [componentInfo, setComponentInfo] = useState({});
  const [selectedTypography, setSelectedTypography] = useState([]);
  //shadow change function and state
  const [selectedShadow, setSelectedShadow] = useState(
    selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[buttonSize]
  );
  const [boxShadowStyle, setBoxShadowStyle] = useState([]);
  // Extract the radius key from selectedTokenData and remove curly braces
  const [selectedRadius, setSelectedRadius] = useState({
    key: designSystem?.light?.borderRadius?.button?.[buttonSize]?.value,
  });
  const [selectedToken, setSelectedToken] = useState(null);
  const { setLoading, pusherIsLoading } = useGlobalContext();
  const [data, setData] = useState({});
  const [uiData, setUiData] = useState({});
  const [elevationData, setElevationData] = useState([]);

  const handleTextdecorationChange = (value) =>
    setSelectedtextdecoration(value);
  const sectionRefs = React.useRef(buttonTabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  useEffect(() => {
    setSelectedShadow(
      selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[
        buttonSize
      ]
    );
  }, [selectedTokenData]);

  useEffect(() => {
    const constructBoxShadow = (shadow) => {
      if (!shadow) return "";

      const { x, y, blur, spread, color } = shadow;
      return `${x} ${y} ${blur} ${spread} ${getCssVariableValue(color)}`;
    };
    setBoxShadowStyle(constructBoxShadow(selectedShadow?.value));
  }, [selectedTokenData, selectedShadow]);

  const radiusValues = designSystem?.core?.borderRadius
    ? Object.keys(designSystem?.core?.borderRadius).map((key, index) => ({
        id: key,
        key: `{borderRadius.${key}}`,
        label: `dss-border-radius-${key}`,
        value: designSystem?.core?.borderRadius[key]?.value,
      }))
    : [];

  useEffect(() => {
    if (selectedTokenData && buttonState && buttonSize && designSystem) {
      const { horizontal, vertical } = getSpacingValues(
        selectedTokenData,
        buttonState,
        buttonSize
      );
      setSpaceHValue(horizontal);
      setSpaceVValue(vertical);
    }
    setSelectedRadius({
      key: designSystem?.light?.borderRadius?.button?.[buttonSize]?.value,
    });
  }, [selectedTokenData, buttonState, buttonSize, designSystem]);

  const spaces = designSystem?.core?.spacing
    ? Object.keys(designSystem?.core?.spacing).map((key) => ({
        label: `spacing.${key}`,
        value: designSystem?.core?.spacing[key]?.value,
      }))
    : [];

  const textDecorations = designSystem?.core?.textDecoration
    ? Object.keys(designSystem?.core?.textDecoration).map((key) => ({
        label: `textDecoration.${key}`,
        value: designSystem?.core?.textDecoration[key]?.value,
      }))
    : [];

  const handleHorizontalChange = (selectedOption) => {
    setSpaceHValue(selectedOption);
  };

  const handleVerticalChange = (selectedOption) => {
    setSpaceVValue(selectedOption);
  };

  const handleRadiusChange = (item) => {
    setSelectedRadius(item);
  };

  const handleStateChange = (name, value) => {
    setButtonState(value);
  };

  const onApplyChanges = async () => {
    addToLocalStorage([tokenState]);
    setColorTokenModalOpen(false);
  };

  const showModal = (variant, size, tokenData, disabled = false) => {
    setModalVisibility(true);
    setSelectedClassName(variant);
    setComponentInfo({
      variant: variant,
      size: size,
      disabled: disabled,
    });
    setSelectedTokenData(tokenData);
  };

  useEffect(() => {
    const initialToken =
      selectedTokenData &&
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ];
    setSelectedToken(initialToken);
  }, [selectedTokenData]);

  useEffect(() => {
    const fetchData = async () => {
      const types =
        "fontWeights,fontSizes,lineHeights,letterSpacing,fontFamilies,textDecoration";
      try {
        const data = await tokenDropdown(id, types);
        setData(data);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      }
    };

    const fetchUItext = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const userId = user?.id;
        setLoading(true);
        if (userId) {
          const { data } = await uiTextTokens(id);
          setUiData(data);
        } else {
          console.error("User ID not found in localStorage");
        }
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setLoading(false);
      }
    };
    if (!pusherIsLoading) {
      fetchData();
      fetchUItext();
    }
  }, [pusherIsLoading]);
  const filterUiTexts = (data, filter) =>
    data &&
    Object.values(data)?.filter((ui) => {
      return ui.key.includes(filter);
    });

  const uiTextData = filterUiTexts(uiData, "core.font.uitext");

  useEffect(() => {
    setElevationData(designSystem?.core?.boxShadow);
  }, [buttonState, designSystem]);

  const handleTokenChange = (token) => {
    const varNamePrefix =
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ]?.varName;

    const cssVariables = {
      [`${varNamePrefix}-font-family`]: token.fontFamily.value,
      [`${varNamePrefix}-font-weight`]: token.fontWeight.value,
      [`${varNamePrefix}-font-size`]: token.fontSize.value,
      [`${varNamePrefix}-line-height`]: token.lineHeight.value,
    };
    updateCSSVariablesAllObj(cssVariables);
    setSelectedToken({ value: token.data, data: token });
    setSelectedTypography(token);
  };

  const updateCSSVariables = (varName, value) => {
    document.documentElement.style.setProperty(varName, value);
  };

  const handleApplyPaddingChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
          buttonSize
        ]?.key,
        value: `{${spaceVValue.label}} {${spaceHValue.label}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setPaddingTokenModalOpen(false);
  };

  const handleApplyShadowChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.elevation?.[
          buttonSize
        ]?.key,
        value: `{${selectedShadow.key}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setShadowTokenModalOpen(false);
  };

  const handleApplyTextChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[
          buttonSize
        ]?.key,
        value: `{${spaceVValue.label}} {${spaceHValue.label}}`,
      },
    ];

    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.spacing?.[buttonSize]
        ?.varName,
      `${spaceVValue?.value} ${spaceHValue?.value}`
    );
    addToLocalStorage(tokenData);

    setTextTokenModalOpen(false);
  };

  const handleApplyRadiusChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[
          buttonSize
        ].key,
        value: `${selectedRadius.key}`,
      },
    ];
    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.radius?.[buttonSize]
        .varName,
      selectedRadius.value
    );
    addToLocalStorage(tokenData);

    setRadiusTokenModalOpen(false);
  };

  const handleApplyTypographyChange = () => {
    const key =
      selectedTokenData[buttonState?.value.toLowerCase()]?.typography?.[
        buttonSize
      ]?.key;

    const tokenData = [
      {
        key,
        value: selectedTypography.data,
      },
    ];

    addToLocalStorage(tokenData);
    setTypoTokenModalOpen(false);
  };

  // Link button - Text decoration
  const handleApplyTextDecorationChange = () => {
    const tokenData = [
      {
        key: selectedTokenData[buttonState?.value.toLowerCase()]?.["default"]
          ?.underline?.[buttonSize].key,
        value: `${selectedtextdecoration.key}`,
      },
    ];
    updateCSSVariables(
      selectedTokenData[buttonState?.value.toLowerCase()]?.["default"]
        ?.underline?.[buttonSize].varName,
      selectedtextdecoration.value
    );
    addToLocalStorage(tokenData);

    setTextTokenModalOpen(false);
  };

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  return (
    <div className='tab-content-wrapper d-flex justify-content-between'>
      <div className='tab-content-container'>
        <div className='plt-content-wrapper dis-mb-24'>
          <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
            <h6
              ref={sectionRefs.current[0]}
              className='plt-contents-head plt-heading-md plt-font-color-primary'
            >
              Primary
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentPrimaryDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={tokensData[0]?.primary}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
            <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
              Fluid width
            </h6>
          </div>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentFluidDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={tokensData[0]?.primary}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6
            ref={sectionRefs.current[1]}
            className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'
          >
            Secondary
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentSecondaryDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={tokensData[2]?.default}
          />
        ))}
        <div
          ref={sectionRefs.current[2]}
          className='plt-content-wrapper dis-mb-24'
        >
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Outline
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentOutlineDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={tokensData[4]?.stroke}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6
            ref={sectionRefs.current[3]}
            className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'
          >
            Danger
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentDangerDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={tokensData[1]?.danger}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6
            ref={sectionRefs.current[4]}
            className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'
          >
            Ghost
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentGostDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={tokensData[3]?.ghost}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6
            ref={sectionRefs.current[5]}
            className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'
          >
            Link
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentLinkDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={tokensData[5]?.link}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Button with icon
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {ComponentBtnDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={""}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Fill button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillBtnDatas.map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={""}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Fill Icon button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas("primary").map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={""}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Default Icon button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas("secondary").map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={""}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Outline Icon button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas("outlined").map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={""}
          />
        ))}
        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Danger Icon button
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas("danger").map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={""}
          />
        ))}

        <div className='plt-content-wrapper dis-mb-24'>
          <h6 className='plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8'>
            Ghost button | Circle
          </h6>
          <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {FillIconBtnDatas("ghost").map((data) => (
          <ButtonComponentCard
            data={data}
            setButtonState={setButtonState}
            setButtonSize={setButtonSize}
            showModal={showModal}
            isLoading={isLoading}
            tokenDataType={""}
          />
        ))}
      </div>
      <ButtonEditModal
        isModalVisible={isModalVisible}
        setModalVisibility={setModalVisibility}
        setButtonState={setButtonState}
        buttonStates={buttonStates}
        buttonState={buttonState}
        handleStateChange={handleStateChange}
        selectedToken={selectedToken}
        componentInfo={componentInfo}
        selectedTokenData={selectedTokenData}
        setTokenType={setTokenType}
        setTypoTokenModalOpen={setTypoTokenModalOpen}
        setRadiusTokenModalOpen={setRadiusTokenModalOpen}
        setColorTokenModalOpen={setColorTokenModalOpen}
        setPaddingTokenModalOpen={setPaddingTokenModalOpen}
        selectedShadow={selectedShadow}
        setShadowTokenModalOpen={setShadowTokenModalOpen}
        setTextTokenModalOpen={setTextTokenModalOpen}
        selectedClassName={selectedClassName}
        buttonSize={buttonSize}
        spaceHValue={spaceHValue}
        spaceVValue={spaceVValue}
      />
      {isColorTokenModalOpen && (
        <ColorTokenModal
          isColorTokenModalOpen={isColorTokenModalOpen}
          setColorTokenModalOpen={setColorTokenModalOpen}
          setButtonSelectedColor={setButtonSelectedColor}
          buttonState={buttonState}
          selectedClassName={componentInfo}
          componentInfo={componentInfo}
          colorSections={colorSections}
          onApplyChanges={onApplyChanges}
          tokenType={tokenType}
          selectedTokenData={selectedTokenData}
          setTokenState={setTokenState}
        />
      )}

      {isPaddingTokenModalOpen && (
        <PaddingTokenModal
          setPaddingTokenModalOpen={setPaddingTokenModalOpen}
          isPaddingTokenModalOpen={isPaddingTokenModalOpen}
          handleApplyPaddingChange={handleApplyPaddingChange}
          buttonState={buttonState}
          selectedClassName={selectedClassName}
          spaceVValue={spaceVValue}
          spaceHValue={spaceHValue}
          spaces={spaces}
          handleHorizontalChange={handleHorizontalChange}
          handleVerticalChange={handleVerticalChange}
        />
      )}
      {isShadowTokenModalOpen && (
        <ShadowTokenModal
          setShadowTokenModalOpen={setShadowTokenModalOpen}
          isShadowTokenModalOpen={isShadowTokenModalOpen}
          handleApplyPaddingChange={handleApplyPaddingChange}
          buttonState={buttonState}
          selectedClassName={selectedClassName}
          spaceVValue={spaceVValue}
          spaceHValue={spaceHValue}
          selectedShadow={selectedShadow}
          elevationData={elevationData}
          boxShadowStyle={boxShadowStyle}
          setSelectedShadow={setSelectedShadow}
          handleApplyShadowChange={handleApplyShadowChange}
        />
      )}

      {isTextTokenModalOpen && (
        <TextTokenModal
          handleTextdecorationChange={handleTextdecorationChange}
          selectedtextdecoration={selectedtextdecoration}
          textdecoration={textdecoration}
          isTextTokenModalOpen={isTextTokenModalOpen}
          setTextTokenModalOpen={setTextTokenModalOpen}
          handleApply={handleApplyTextDecorationChange}
          buttonState={buttonState}
          selectedClassName={selectedClassName}
          setSelectedtextdecoration={setSelectedtextdecoration}
          textDecorations={textDecorations}
        />
      )}

      <RadiusTokenModal
        isRadiusTokenModalOpen={isRadiusTokenModalOpen}
        setRadiusTokenModalOpen={setRadiusTokenModalOpen}
        selectedClassName={selectedClassName}
        buttonState={buttonState}
        handleRadiusChange={handleRadiusChange}
        handleApplyRadiusChange={handleApplyRadiusChange}
        selectedRadius={selectedRadius}
        setSelectedRadius={setSelectedRadius}
        radiusValues={radiusValues}
      />
      {isTypoTokenModalOpen && (
        <TypoTokenModal
          isTypoTokenModalOpen={isTypoTokenModalOpen}
          setTypoTokenModalOpen={setTypoTokenModalOpen}
          buttonState={buttonState}
          selectedClassName={selectedClassName}
          selectedToken={selectedToken}
          handleTokenChange={handleTokenChange}
          handleApplyTypographyChange={handleApplyTypographyChange}
          uiTextData={uiTextData}
        />
      )}
      <div className='plt-token-contents'>
        <TabNavigation
          tabs={buttonTabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewButtonComponent;
