import SuperAdminLayout from "components/layouts/SuperAdminLayout";
import React from "react";

const SuperAdmin = () => {
	return (
		<SuperAdminLayout>
			<div className="tab-content-wrapper">
				<div className="tab-content-container">Super admin settings</div>
			</div>
		</SuperAdminLayout>
	);
};

export default SuperAdmin;
