import React, { useState } from "react";
import "./RadiusUsage.scss";
import radiusImg from "../../../../assets/images/png/radiusImg.png";
import radiusImgRaised from "../../../../assets/images/png/radiusImgRaised.png";
import radiusImgOverlay from "../../../../assets/images/png/radiusImgOverlay.png";
import useScrollManagement from "hooks/useScrollManagement";
import TabNavigation from "../../TabNavigation/index";
import UsageCardImg from "../../../Common/UsageCardImg";
import { RadiusUsageDatas } from "../../../../assets/data/data";

const RadiusUsage = () => {
	const tabData = ["Basic principles", "Content guidelines"];
	const [activeTab, setActiveTab] = useState(0);

	const sectionRefs = React.useRef([React.createRef(), React.createRef()]);

	const scrollToSection = useScrollManagement(sectionRefs.current, activeTab, setActiveTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveTab(index);
		scrollToSection(index);
	};

	return (
		<div className="d-flex justify-content-between w-full">
			<div className="tab-content-container">
				<div className="plt-content-block dis-mb-48">
					<div className="plt-content-wrapper">
						<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
							<h6 ref={sectionRefs.current[0]} className="plt-contents-head plt-heading-md plt-font-color-primary">
								Basic principles
							</h6>
						</div>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-12">
							It is important to maintain the same style of radius for the same types components. Inconsistency will
							create a messy UI.
						</p>
					</div>
				</div>

				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">Small</h6>
						</div>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-12">
							This color is primary and is used for main elements and background.
						</p>
						<h5 className="plt-badge plt-body-compact-sm">radius-md</h5>
					</div>
				</div>

				<div className="plt-common-card-wrapper d-flex dis-mb-48">
					<div className="plt-application-card">
						<div className="plt-application-card-wrapper">
							<h3 className="plt-common-card-badge font-color-black">border-radius-0</h3>
							<div className="usage-img dis-ml-32">
								<img src={radiusImg} alt="userimage" />
							</div>
						</div>
					</div>
				</div>

				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">Medium</h6>
						</div>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-12">
							This color is primary and is used for main elements and background.
						</p>
						<h5 className="plt-badge plt-body-compact-sm">radius-md</h5>
					</div>
				</div>

				<div className="plt-common-card-wrapper d-flex dis-mb-48">
					<div className="plt-application-card">
						<div className="plt-application-card-wrapper">
							<h3 className="plt-common-card-badge font-color-black">border-radius-150</h3>
							<div className="usage-img dis-ml-32">
								<img src={radiusImgRaised} alt="userImage" />
							</div>
						</div>
					</div>
				</div>

				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">Large</h6>
						</div>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-12">
							This color is primary and is used for main elements and background.
						</p>
						<h5 className="plt-badge plt-body-compact-sm">radius-md</h5>
					</div>
				</div>

				<div className="plt-common-card-wrapper d-flex dis-mb-48">
					<div className="plt-application-card">
						<div className="plt-application-card-wrapper">
							<h3 className="plt-common-card-badge font-color-black">border-radius-400</h3>
							<div className="usage-img dis-ml-32">
								<img src={radiusImgRaised} alt="userimage" />
							</div>
						</div>
					</div>
				</div>

				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">Full</h6>
						</div>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-12">
							This color is primary and is used for main elements and background.
						</p>
						<h5 className="plt-badge plt-body-compact-sm">radius-md</h5>
					</div>
				</div>

				<div className="plt-common-card-wrapper d-flex dis-mb-48">
					<div className="plt-application-card">
						<div className="plt-application-card-wrapper">
							<h3 className="plt-common-card-badge font-color-black">border-radius-full</h3>
							<div className="usage-img dis-ml-32">
								<img src={radiusImgOverlay} alt="userimage" />
							</div>
						</div>
					</div>
				</div>

				<div className="plt-common-card-wrapper d-flex">
					<div className="plt-content-block">
						<div className="plt-content-wrapper">
							<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
								<h6 ref={sectionRefs.current[1]} className="plt-contents-head plt-heading-md plt-font-color-primary">
									Content guidelines
								</h6>
							</div>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-12">
								Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate
								user actions and engagement.
							</p>
						</div>
					</div>

					<div className="plt-content-block">
						<div className="plt-content-wrapper dis-mb-8">
							<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
								<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary">
									Use sentence case capitalization
								</h6>
							</div>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400 dis-mb-12">
								Only capitalize the first letter of the button and any proper nouns. Most feature names aren’t
								capitalized or considered proper nouns when following our capitalization guidance.
							</p>
						</div>
					</div>

					<div className="plt-usage-card-wrapper d-flex justify-content-between">
						{RadiusUsageDatas.map((data) => (
							// <div key={data.id} className="plt-common-card">
							<UsageCardImg UsageBoxData={data} key={data.id} />
						))}
					</div>
				</div>
			</div>
			<div className="plt-token-contents ">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default RadiusUsage;
