import React, { useState } from "react";
import "assets/scss/pages/_DesignSystemComponents.scss";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import { useParams } from "react-router-dom";
import { getComponentsSideData } from "assets/data/sideTabData";
import { useGlobalContext } from "context/GlobalContext";

import PreviewInputComponent from "components/DesignSystemPage/InputComponent/PreviewInputComponent";
import UsageInputComponent from "components/DesignSystemPage/InputComponent/UsageInputComponent";
import TokenTableComponent from "components/Common/TokenTableComponent/TokenTableComponent";
import { textfieldTokenTabData } from "assets/data/tokenTabData";

const Inputs = () => {
  const { id } = useParams();
 const { designSystemMeta, designSystem } = useGlobalContext();
   
      const componentName = "textfield";
       const componentKey = `Component/${componentName}`;
       const componentData = designSystem[componentKey];
       const tokenTableData = textfieldTokenTabData(componentData, componentName);

  const tabData = [
    "Default",
    "Fluid",
    // "Primary colors",
    // "Secondary colors",
    // "Supporting colors",
    // "Neutral colors",
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const bannerData = {
    heading: "Inputs",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PreviewInputComponent
            tabData={tabData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setSelectedTab={setSelectedTab}
          />
        );
      case 1:
        return (
          <UsageInputComponent/>
        );
      case 2:
         return <TokenTableComponent tokenTableData={tokenTableData} />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title='Components'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default Inputs;
