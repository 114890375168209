import React, { useState } from "react";
import CommonModal from "components/Common/CommonModal";
import upgradeImg from "../../assets/images/png/upgradeImg.png";
import "./UpgradePlanAlertModal.scss";
import { PremiumButtonIcon } from "components/Icons";
import { StarButtonIcon } from "components/Icons";
import { ToolButtonIcon } from "components/Icons";
import ContactusModal from "./ContactusModal";
import { sendContactUsMail } from "api";
import { useGlobalContext } from "context/GlobalContext";
import { toast } from "react-toastify";

const UpgradePlanAlertModal = ({ isOpen, onRequestClose, onApply, title }) => {
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const { setLoading } = useGlobalContext();

  const handleApply = () => {
    onRequestClose();
    setContactModalOpen(true);
  };

  const closeContactusModal = () => {
    setContactModalOpen(false);
  };

  const handleContactApply = async (message) => {
    try {
      setLoading(true);
      const response = await sendContactUsMail(message);
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Failed to send contact mail", error);
    } finally {
      setLoading(false);
      setContactModalOpen(false);
    }
  };
  return (
    <>
      <CommonModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={title}
        classPrefix="plt"
        onApply={handleApply}
        onCancelText="Cancel"
        onApplyText="Upgrade"
        // bodyClassName='overflow-unset'
      >
        <div className="upgrade dis-ml-24 dis-mr-24">
          <div className="upgrade-img">
            <img src={upgradeImg} alt="upgradeImg" />
          </div>
          <div className="upgrade-content">
            <h6 className="upgrade-title plt-heading-xl plt-font-color-primary dis-mt-16 dis-mb-8">
              Enjoy powerful collaboration as a team
            </h6>
            <p className="upgrade-txt plt-body-sm plt-font-color-primary dis-mb-16">
              Upgrade to Dessign System teams and create quality designs
              together.
            </p>
            <h4 className="upgrade-sub-title plt-heading-sm plt-font-color-secondary dis-mb-8">
              Here’s what you get with Dessign System Pro
            </h4>
            <ul className="upgrade-list dis-mb-16">
              <li className="upgrade-list-item dis-mt-4">
                <span className="upgrade-list-icon plt-btn-icon">
                  <PremiumButtonIcon />
                </span>
                <span className="upgrade-list-txt plt-body-md plt-font-color-secondary">
                  Premium content in everyone’s hand
                </span>
              </li>
              <li className="upgrade-list-item dis-mt-4">
                <span className="upgrade-list-icon plt-btn-icon">
                  <StarButtonIcon />
                </span>
                <span className="upgrade-list-txt plt-body-md plt-font-color-secondary">
                  A strong brand across design
                </span>
              </li>
              <li className="upgrade-list-item dis-mt-4">
                <span className="upgrade-list-icon plt-btn-icon">
                  <ToolButtonIcon />
                </span>
                <span className="upgrade-list-txt plt-body-md plt-font-color-secondary">
                  Time saving tools for editing your design system.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </CommonModal>
      <ContactusModal
        isOpen={isContactModalOpen}
        onRequestClose={closeContactusModal}
        onApply={handleContactApply}
        title="Contact us"
      />
    </>
  );
};

export default UpgradePlanAlertModal;
