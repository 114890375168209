import React, { useRef, useState } from "react";Tag
import "./Tag.scss";
import {  ButtonIconSample, CloseIcon, StatusIcon } from "../Icons";

function Tag({size,type="default",label="Basic tag",disable,prefixIcon,statusIcon,prefixClass,mode,closeIconClass,varient,counter=0}) {
  return (
    <div className={`dss-tag dss-tag-${type} dss-tag-${size} dss-tag-spacing-${varient} ${mode == "closable" && 'dss-tag-closable'} ${disable && 'disabled'}`}>
      <div className="dss-tag-leading">
    {
prefixIcon == true || false ? <span className={`dss-prefix-icon ${prefixClass}`}>
  
        <ButtonIconSample color={disable ? '#17171740' : 'black'}/>
      </span> : <span className={` ${prefixClass}`}>
        {prefixIcon}
      </span> 
    }
     {
statusIcon == true || false ? <span className={`dss-prefix-icon ${prefixClass}`}>
        <StatusIcon color={disable ? '#17171740' : 'black'}/>
      </span> : <span className={` ${prefixClass}`}>
        {statusIcon}
      </span> 
    }
    
      <span className={`dss-tag-label`}>{label}</span>
      </div>
      {
        mode == "counter" ? <span className={`dss-tag-counter`}>| {counter}</span> : mode == "closable" ? <span className={`dss-close-icon ${closeIconClass}`}>
          <CloseIcon />
        </span> : null
      }
    </div>
  )
}

export default Tag;
