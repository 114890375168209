import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";
import TokenDropdown from "components/TokenDropdown";

function TextTokenModal({
  isTextTokenModalOpen,
  setTextTokenModalOpen,
  selectedClassName,
  buttonState,
  spaceVValue,
  spaceHValue,
  textdecoration,
  selectedtextdecoration,
  handleTextdecorationChange,
  handleApply,
  setSelectedtextdecoration,
  textDecorations,
}) {
  return (
    <CommonModal
      isOpen={isTextTokenModalOpen}
      onRequestClose={() => setTextTokenModalOpen(false)}
      title='Change token'
      onApply={() => handleApply()}
      onCancelText='Cancel'
      onApplyText='Apply'
    >
      <div className='plt-modal-body-content'>
        <div
          className='plt-preview dis-mb-32'
          style={{ fontFamily: buttonState }}
        >
          <PrimaryButton
            text={"Button text"}
            className={` ${buttonState} ${selectedClassName} d-flex m-auto`}
            disabled={buttonState === "disabled"}
            style={{
              padding: `${spaceVValue?.value}  ${spaceHValue?.value}`,
            }}
          />
        </div>

        <div className='plt-padding-wrapper d-flex  dis-mb-32'>
          <div className='plt-select-dropdown-wrapper d-flex align-items-center w-100'>
            <h6 className='plt-select-dropdown-title dis-mb-4 plt-ui-text-sm-regular'>
              Text decoration
            </h6>
            <div className='plt-dropdown-wrapper'>
              <TokenDropdown
                name='textdecoration'
                options={textDecorations}
                selectedOption={selectedtextdecoration}
                onSelect={(option) => setSelectedtextdecoration(option)}
              />
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
}

export default TextTokenModal;
