import { generate } from "@ant-design/colors";
import AuthAction from "components/auth/AuthAction";
import Permission from "components/auth/Permission";
import React, { useState } from "react";
import { ChromePicker } from "react-color";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalContext } from "../../../context/GlobalContext";
import { addToLocalStorage } from "../../../hooks/storageUtils";
import useColorConversion from "../../../hooks/useColorConversion";
import CommonModal from "../../Common/CommonModal";
import PrimaryButton from "../../Common/PrimaryButton";
import { ColorCopyIcon } from "../../Icons";
import { ColorEditIcon, ModalCloseIcon } from "../../Icons/";
import "./ColorPickerComponent.scss";

const ColorSwatch = ({
  color,
  onClick,
  index,
  activeIndex,
  initialColor,
  varName,
  type,
  tokenType,
  shade,
  selectedButtonColor,
}) => {
  const { designSystemMeta } = useGlobalContext();
  const hexColor = color?.toUpperCase();

  const rgb = hexColor
    ? {
        r: parseInt(hexColor.slice(1, 3), 16),
        g: parseInt(hexColor.slice(3, 5), 16),
        b: parseInt(hexColor.slice(5, 7), 16),
      }
    : null;

  const rgbColor = rgb ? `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})` : null;

  const brightness = rgb
    ? Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)
    : null;

  const colorActive =
    (type?.length && selectedButtonColor?.key === shade?.key) ||
    (initialColor === shade.key && !selectedButtonColor?.key);

  const textColorClass =
    brightness > 125
      ? "plt-theme-pallette-item-dark"
      : "plt-theme-pallette-item-light";

  const className = `plt-theme-pallette-item ${
    activeIndex === index ||
    colorActive ||
    (initialColor === shade.key && !selectedButtonColor)
      ? "active"
      : ""
  } ${textColorClass}`.trim();

  const buttonClassName = `${className} button`;
  const baseColorStyle = {
    backgroundColor: `var(${varName})`,
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(hexColor);
    toast.success(`Copied ${hexColor} to clipboard!`);
  };

  const detailBlockClassName = `plt-color-detail-block ${activeIndex === index ? "active" : ""}`;

  const handleClick = !type?.length ? onClick : undefined;
  const colorClick = type?.length ? onClick : undefined;

  return (
    <>
      {hexColor && rgbColor && tokenType ? (
        <div
          className={`${type === "bg" || type === "text" || type === "border" ? buttonClassName : className} `}
          onClick={colorClick}
          style={baseColorStyle}
        >
          <div className={detailBlockClassName}>
            {hexColor && rgbColor && tokenType ? (
              <>
                <div className='plt-body-xs plt-theme-pallette-block'>
                  <span className='plt-body-xs plt-theme-pallette-text'>{`HEX ${hexColor}`}</span>
                </div>
                <div className='plt-body-xs d-flex align-items-center plt-theme-pallette-block'>
                  <>
                    <span className='plt-body-xs plt-theme-pallette-text'>
                      {rgbColor}
                    </span>
                    <div
                      onClick={handleCopyClick}
                      className='plt-theme-pallette-copy-icon dis-ml-4'
                    >
                      <ColorCopyIcon />
                    </div>
                  </>
                </div>

                <div className='plt-body-xs d-flex align-items-center plt-theme-pallette-block '>
                  <span className='plt-body-xs plt-theme-pallette-text'>
                    {tokenType}-{`${(index + 1) * 100}`}
                  </span>
                  <AuthAction>
                    <Permission designSystem={designSystemMeta}>
                      <div
                        onClick={handleClick}
                        className='plt-theme-pallette-edit-icon dis-ml-8 cursor-pointer'
                      >
                        <ColorEditIcon />
                      </div>
                    </Permission>
                  </AuthAction>
                </div>
              </>
            ) : (
              <Skeleton baseColor='#a6a6a663' width={100} height={110} />
            )}
          </div>
        </div>
      ) : (
        <Skeleton baseColor='#a6a6a663' width={85} height={125} />
      )}
    </>
  );
};

const ColorPickerModal = ({
  isOpen,
  onClose,
  color,
  setColorModalOpen,
  handleColorChange,
  handleShadeChange,
  setColor,
  index,
}) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [colorOption, setColorOption] = useState("shadeRange");

  const handleColorOptionChange = (event) => {
    setColorOption(event.target.value);
  };

  const handleApplyClick = () => {
    if (colorOption === "shadeRange") {
      handleColorChange(color);
    } else if (colorOption === "editShade") {
      handleShadeChange(color, index);
    }
    setColorModalOpen(false);
    setIsColorPickerOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className='plt-modal-overlay'>
      <div className='plt-modal-content'>
        <div className='plt-modal-header'>
          <h5 className='plt-modal-header-title plt-heading-sm plt-font-color-primary'>
            Edit base color
          </h5>
          <span className='plt-modal-close-icon plt-iconbtn' onClick={onClose}>
            <ModalCloseIcon />
          </span>
        </div>
        <div className='plt-modal-body dis-pl-24 dis-pr-24'>
          <ChromePicker
            color={color}
            onChangeComplete={(colorResult) => setColor(colorResult.hex)}
          />
        </div>
        <div className='plt-modal-footer'>
          <PrimaryButton
            onClick={() => setIsColorPickerOpen(true)}
            text={"Apply"}
            // className={`dss-btn dss-btn-primary dss-ui-text-md-medium `}
            variant='primary'
            size='md'
          />
        </div>
      </div>
      <CommonModal
        isOpen={isColorPickerOpen}
        onRequestClose={() => setIsColorPickerOpen(false)}
        title='Apply?'
        onApply={handleApplyClick}
        onCancelText='Cancel'
        onApplyText='Apply'
        // variant="modal-secondary"
      >
        <div className='plt-modal-body-content'>
          <div className='dss-custom-radio-group dss-custom-radio-group-vertical dis-pr-24 dis-pl-24 dis-pt-12 dis-pb-12'>
            <label
              className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default ${
                colorOption === "shadeRange" ? "checked" : ""
              }`}
            >
              <input
                className='dss-radio-input'
                type='radio'
                id='shadeRange'
                name='colorOption'
                value='shadeRange'
                checked={colorOption === "shadeRange"}
                onChange={handleColorOptionChange}
              />
              <span
                className='dss-radio-label dss-body-compact-md'
                htmlFor='shadeRange'
              >
                Change resulting shade range
              </span>
            </label>
            <label
              className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default ${
                colorOption === "editShade" ? "checked" : ""
              }`}
            >
              <input
                className='dss-radio-input'
                type='radio'
                id='editShade'
                name='colorOption'
                value='editShade'
                checked={colorOption === "editShade"}
                onChange={handleColorOptionChange}
              />
              <span
                className='dss-radio-label dss-body-compact-md'
                htmlFor='editShade'
              >
                Edit only this shade
              </span>
            </label>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

const AlphaPickerModal = ({
  isOpen,
  onClose,
  setColorModalOpen,
  onApply,
  index,
  setColor,
  color,
}) => {
  const handleApplyClick = () => {
    onApply(color, index);
    setColorModalOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className='shades'
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#fff",
        padding: "20px",
        zIndex: 1000,
        border: "1px solid #ccc",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className='d-flex justify-content-between dis-mb-12'>
        <h3 className='plt-heading-sm plt-font-color-primary'>
          Edit base color
        </h3>
        <span
          className='cursor-pointer plt-iconbtn plt-iconbtn-sm'
          onClick={onClose}
        >
          <ModalCloseIcon />
        </span>
      </div>

      <ChromePicker
        color={color}
        onChangeComplete={(colorResult) => setColor(colorResult.hex)}
      />

      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <PrimaryButton
          onClick={() => handleApplyClick(true)}
          text={"Apply"}
          // className={`plt-btn plt-btn-primary`}
          variant='primary'
          size='md'
        />
      </div>
    </div>
  );
};

const ColorPickerComponent = (props) => {
  const { selectedButtonColor, setButtonSelectedColor, theme } =
    useGlobalContext();

  const {
    baseColor,
    shades,
    tokenType,
    type,
    setTokenState,
    buttonState,
    tokenState,
    initialColor,
    disbleColorPickerModal = false,
  } = props;

  const [color, setColor] = useState(baseColor);
  const [colorShades, setColorShades] = useState(shades);

  React.useEffect(() => {
    setColor(baseColor);
  }, [baseColor]);

  React.useEffect(() => {
    setColorShades(shades);
    setColorShade(shades);
  }, [shades]);

  React.useEffect(() => {
    shades?.length ?? setColorShade(shades);
  }, [shades]);

  const [colorShade, setColorShade] = useState(shades);

  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [colorAlphaModalOpen, setColorAlphaModalOpen] = useState(false);
  const { rgbaToHex } = useColorConversion();
  const [activeIndex, setActiveIndex] = useState(type?.length ? null : 5);
  const [activeShadeIndex, setActiveShadeIndex] = useState(null);

  const openColorPicker = (index) => {
    setActiveShadeIndex(index);
    setColorModalOpen(true);
  };

  // Change all colors
  const handleColorChange = async (newColor) => {
    setColor(newColor);
    const newShades = generateColorShades(newColor, tokenType, theme);
    setColorShades(newShades);
    updateCSSVariables(newShades);

    const newShadesFiltered = newShades.map(({ key, value }) => {
      return {
        key: key.replace("core.", `Theme/${theme}.`),
        value,
      };
    });
    addToLocalStorage(newShadesFiltered);
  };

  const updateCSSVariables = (shades) => {
    shades.forEach((shade) => {
      document.documentElement.style.setProperty(shade.varName, shade.value);
    });
  };

  // Change individual color
  const handleShadeChange = async (newColor, index) => {
    if (!colorShades[index]) {
      console.error(`Shade at index ${index} is undefined`);
      return;
    }

    const isRgba = /^rgba/.test(newColor);
    const convertedColor = isRgba ? rgbaToHex(newColor) : newColor;

    const updatedShades = colorShades.map((shade, idx) => {
      if (idx === index) {
        return { ...shade, value: convertedColor };
      }
      return shade;
    });
    // *
    try {
      document.documentElement.style.setProperty(
        updatedShades[index].varName,
        convertedColor
      );

      const tokenData = [
        {
          key: updatedShades[index].tokenName.replace(
            "core.",
            `Theme/${theme}.`
          ),
          value: convertedColor,
        },
      ];
      addToLocalStorage(tokenData);
      setColorShades(updatedShades);
    } catch (error) {
      console.error("Failed to update token:", error);
    }
  };

  const openAlphaPickerForShade = (index) => {
    setActiveShadeIndex(index);
    setColorAlphaModalOpen(true);
  };

  const handleColorClick = (shade, index) => {
    setButtonSelectedColor(shade, index);
    const value = getComputedStyle(document.documentElement).getPropertyValue(
      shade.varName
    );
    const shadeKeyValue = shade.key;

    // setActiveIndex(index);
    const applyChanges = (cssVarName, tokenKey) => {
      document.documentElement.style.setProperty(cssVarName, value);

      setTokenState({
        key: tokenKey,
        value:
          type === "bg" ||
          type === "inputBg" ||
          type === "text" ||
          type === "border"
            ? shadeKeyValue
            : value,
      });
    };

    if (type === "inputBg") {
      applyChanges(tokenState.varName, tokenState.key);
    }

    if (type === "bg") {
      applyChanges(
        tokenState[buttonState?.value?.toLowerCase()]?.bg.varName,
        tokenState[buttonState?.value.toLowerCase()]?.bg.key
      );
    }

    if (type === "border") {
      applyChanges(
        tokenState[buttonState?.value?.toLowerCase()]?.border.varName,
        tokenState[buttonState?.value?.toLowerCase()]?.border.key
      );
    } else if (type === "text") {
      applyChanges(
        tokenState[buttonState?.value?.toLowerCase()]?.text.varName,
        tokenState[buttonState?.value?.toLowerCase()]?.text.key
      );
    } else if (type === "elevation") {
      applyChanges(shade.varName, shade.key);
    } else {
      if (index === 5 && !disbleColorPickerModal) {
        setColor(value);
        openColorPicker(index);
      } else if (!disbleColorPickerModal) {
        setColorShade(value);
        openAlphaPickerForShade(index);
      }
    }
  };

  return (
    <div className='plt-color-picker-container dis-mb-16'>
      <div className='plt-color-picker-modal'>
        <ColorPickerModal
          setColor={setColor}
          index={activeShadeIndex}
          isOpen={colorModalOpen}
          setColorModalOpen={setColorModalOpen}
          onClose={() => setColorModalOpen(false)}
          color={color}
          handleColorChange={handleColorChange}
          handleShadeChange={handleShadeChange}
        />
        <AlphaPickerModal
          index={activeShadeIndex}
          setColor={setColorShade}
          isOpen={colorAlphaModalOpen}
          setColorModalOpen={setColorAlphaModalOpen}
          onClose={() => setColorAlphaModalOpen(false)}
          color={colorShade}
          onApply={handleShadeChange}
        />
      </div>
      <div className='d-flex flex-column'>
        <div className='plt-theme-pallette-lists d-flex'>
          {colorShades?.map((shade, index) => (
            <ColorSwatch
              type={type}
              initialColor={initialColor}
              key={index}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue(shade.varName)}
              varName={shade.varName}
              tokenType={tokenType}
              selectedButtonColor={selectedButtonColor}
              shade={shade}
              onClick={() => handleColorClick(shade, index)}
              index={index}
              activeIndex={activeIndex}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const generateColorShades = (baseColor, tokenType, theme) => {
  // Use the Ant Design generate function to create the color palette
  const shades = generate(baseColor);

  return shades.map((shade, index) => {
    const shadeValue = (index + 1) * 100; // Generate shade values from 100 to 1000
    return {
      key: `Theme/${theme}.color.${tokenType}.${shadeValue}`,
      value: shade,
      varName: `--dss-color-${tokenType.replace(/\./g, "-")}-${shadeValue}`,
      tokenName: `dss-color-${tokenType.replace(/\./g, "-")}-${shadeValue}`,
    };
  });
};

export default ColorPickerComponent;
