import React from 'react';
import './/ComponentButtonCard.scss';
import PrimaryButton from '../../../components/Common/PrimaryButton';

const ComponentButtonCard = ({ state, buttonType }) => {
	return (
		<div className="plt-component-card">
			<div className="plt-component-btn-wrapper d-flex align-items-center justify-content-center">
				<PrimaryButton
					// onClick={{}}
					text={'Button text'}
					className={`dss-btn-${buttonType} ${state ? state : ''} pointer-none`}
					size= "lg"
				/>
			</div>
		</div>
	);
};

export default ComponentButtonCard;
