import CommonModal from "components/Common/CommonModal";
import PrimaryButton from "components/Common/PrimaryButton";

function TypoTokenModal({
  isTypoTokenModalOpen,
  setTypoTokenModalOpen,
  buttonState,
  selectedClassName,
  selectedToken,
  handleTokenChange,
  handleApplyTypographyChange,
  uiTextData,
}) {
  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };
  return (
    <CommonModal
      isOpen={isTypoTokenModalOpen}
      onRequestClose={() => setTypoTokenModalOpen(false)}
      title="Change token"
      onApply={() => handleApplyTypographyChange()}
      onCancelText="Cancel"
      onApplyText="Apply"
    >
      <div className="plt-modal-body-content">
        <div
          className="plt-preview  dis-mb-32"
          style={{ fontFamily: buttonState }}
        >
          <PrimaryButton
            style={{
              fontFamily: selectedToken?.data?.fontFamily?.value,
              fontSize: selectedToken?.data?.fontSize?.value,
              lineHeight: selectedToken?.data?.lineHeight?.value,
              fontWeight: selectedToken?.data?.fontWeight?.value,
            }}
            onClick={{}}
            text={"Button text"}
            className={`${selectedClassName} ${buttonState} $  d-flex m-auto`}
          />
        </div>
        <div className="modal-token-wrapper">
          <div className="token-list dss-custom-radio-group flex-column">
            {uiTextData.map((token) => {
              return (
                <div key={token.id} className="token-item">
                  <label
                    className={`dss-radio-wrapper dss-custom-radio-group-md dss-radio-default ${
                      isEqual(selectedToken?.value, token.data) ? "checked" : ""
                    }`}
                  >
                    <input
                      className="dss-radio dss-radio-input dss-radio-default"
                      name="radio-group"
                      type="radio"
                      id={token.id}
                      checked={isEqual(selectedToken?.value, token.data)}
                      onChange={() => handleTokenChange(token)}
                    />
                    <div className="dss-radio-label" htmlFor={token.id}>
                      <p className="plt-badge plt-body-sm">{token.className}</p>
                    </div>
                  </label>
                  <div className="token-info dis-ml-40">
                    <p className="plt-body-sm font-color-black dis-mt-12">
                      Size: {token.fontSize.value}
                    </p>
                    <p className="plt-body-sm">
                      <span className="plt-body-sm font-color-black">
                        Line height: {token.lineHeight.value}
                      </span>
                      <span className="plt-body-sm font-color-black dis-ml-32">
                        Weight: {token.fontWeight.value}
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </CommonModal>
  );
}

export default TypoTokenModal;
