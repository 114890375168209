import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";

import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import TabNavigation from "components/DesignSystemPage/TabNavigation";
import ColorToken from "components/DesignSystemPage/AllTokens/ColorToken";
import useScrollManagement from "hooks/useScrollManagement";

import { getStylesSideTabData } from "assets/data/sideTabData";
import AllTokenData from "components/DesignSystemPage/AllTokens/allTokenData";

const DesignSystemAllTokens = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();

  const tabData = [
    "Color",
    "Typography",
    "Spacing",
    "Border radius",
    "Elevation",
    "Sizing",
    "Font",
    "Border width",
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeSideTab, setActiveSideTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const TokenColorDatas = AllTokenData();

  useEffect(() => {
    // Simulating a data fetch
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };

  const sectionRefs = TokenColorDatas?.map(() => useRef(null));
  const scrollToSection = useScrollManagement(
    sectionRefs,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index, sectionRefs);
  };

  const bannerData = {
    heading: "Tokens",
    description:
      "Each token represents a specific aspect of the design, such as color, typography, spacing, or components. By defining and organizing these tokens",
    bannerImage: designSystemMeta.banner_url,
  };

  return (
    <DesignSystemLayout
      selectedTab={selectedTab}
      setSelectedTab={handleHeaderTabClick}
      bannerData={bannerData}
      tabs={[]}
      sideTabs={getStylesSideTabData(id)}
      title='Foundation'
    >
      <div className='tab-content-wrapper d-flex justify-content-between'>
        {selectedTab === 0 && (
          <ColorToken
            TokenColorDatas={TokenColorDatas}
            sectionRefs={sectionRefs}
            isLoading={isLoading}
          />
        )}
        <div className='plt-token-contents '>
          <TabNavigation
            tabs={tabData}
            title={"CONTENTS"}
            activeIndex={activeSideTab}
            onTabClick={handleTabClick}
          />
        </div>
      </div>
    </DesignSystemLayout>
  );
};

export default DesignSystemAllTokens;
