import React, { useState, useEffect } from "react";
import CommonModal from "components/Common/CommonModal";
import FormValidationError from "components/FormValidationError";
import SelectInput from "components/SelectInput";
import { PERMISIONS } from "constants";
import userAvatar from "../../../assets/images/png/userAvatar.png";
import ManageAccessCommonModal from "components/Common/CommonModal/ManageAccessCommonModal";

const EditDesignsystemsModal = ({ isOpen, onRequestClose, onApply, title, data }) => {
	const [errors, setErrors] = useState({});
	const [selectedRole, setSelectedRole] = useState();
	const [permission, setPermission] = useState([]);
	const [formData, setFormData] = useState([]);

	useEffect(() => {
		data?.users?.map((user) => {
			setFormData({
				...formData,
				[user.id]: user.access_id,
			});
		});
	}, [data]);

	const handleAccessChange = (value, userId) => {
		setFormData({
			...formData,
			[userId]: value.value,
		});
	};

	const handleApply = () => {
		let input = [];
		let isError = false;

		data.users?.map((user) => {
			if (formData[user.id]) {
				let obj = { userId: user.id, access_id: formData[user.id] };
				input.push(obj);
			}
		});

		if (!isError) {
			onApply(input, data.id);
			setErrors({});
		}
	};

	return (
		<CommonModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={title}
			onApply={handleApply}
			classPrefix="plt"
			onCancelText="Cancel"
			onApplyText="Save"
			// bodyClassName='overflow-unset'
		>
			<div className="plt-modal-body-content">
				<div className="plt-profile-box d-flex align-items-center dis-p-24 dis-pt-16 dis-pb-16">
					{data?.design_system_name}

					<div className="plt-profile-box-info">
						{/* <p className='plt-profile-name plt-body-compact-sm plt-font-color-primary dis-mb-4'>
              Jim Jimbo
            </p> */}
						<p className="plt-profile-mail plt-body-compact-xs plt-font-color-primary">{data?.email}</p>
					</div>
				</div>

				<div className="plt-select-dropdown-wrapper dis-mt-16 dis-pl-24 dis-pr-24">
					<h6 className="plt-select-dropdown-title plt-ui-text-md-medium dis-mb-4">Users with design system access</h6>
					<ul className="plt-file-group w-100">
						{data?.users?.map((user) => (
							<li className="plt-file-list d-flex align-items-center" key={user.id}>
								<h3 className="plt-body-md font-weight-400 d-flex justify-content-between">
									<img src={userAvatar} alt="profile" className="user-image-sm " />
									<span className="plt-body-md font-weight-400  dis-ml-12 ">{user.name}</span>
									{/* <span className="plt-body-md font-weight-400"></span> */}
								</h3>

								<div className="plt-ui-text-sm-medium plt-font-color-primary plt-select-dropdown-secondary ml-auto">
									{data.admin_id === user.id ? "Owner" : ""}
									{data.admin_id !== user.id && (
										<SelectInput
											options={PERMISIONS}
											defaultValue={PERMISIONS.find((p) => p.value === user?.pivot?.access_id)}
											onSelect={(selectedValue) => handleAccessChange(selectedValue, user.id)}
										/>
									)}
								</div>
							</li>
						))}
					</ul>
					{errors?.access && <FormValidationError error={errors?.access} />}
				</div>
			</div>
		</CommonModal>
	);
};

export default EditDesignsystemsModal;
