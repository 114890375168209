import React from "react";
import "./ToasterComponent.scss";
import { TickIconWhite } from "components/Icons";
import PrimaryButton from "../PrimaryButton";
import { ToasterCloseIcon } from "components/Icons";


const ToasterDisplay = ({
  prefix,
  closable,
  action,
  actionFooter,
  onClose,
}) => {
	return (
  <div className="dss-toaster">
    <div className="dss-toaster-leading">
    {prefix && <TickIconWhite />}
    <div
      className={`dss-toaster-msg-container ${actionFooter ? "dss-toaster-action-footer" : ""
        }`}
    >
      <h6 className="dss-toaster-msg">Message</h6>
      <div className="dss-toaster-trailing">
        {action && (
            <PrimaryButton
              // className="dss-btn dss-btn-link dss-btn-xs w-fit"
              // className="w-fit"
              variant="link"
              size= "xs"
              text="Action"
            />
          )}
       </div>
    </div>
    </div>
    
    {closable && (
      <span onClick={onClose} className="dss-toaster-close-icon">
        <ToasterCloseIcon  />
      </span>
    )}
   
  </div>
);
};

export default ToasterDisplay;
