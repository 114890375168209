import React, { useState } from "react";
import CommonModal from "components/Common/CommonModal";
import FormValidationError from "components/FormValidationError";
import SelectInput from "components/SelectInput";
import UserAvatar from "assets/images/png/userAvatar.png";
import "./AddMemberModal.scss";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { PERMISIONS } from "constants";

const AddMemberModal = ({ isOpen, onRequestClose, onApply, title, data }) => {
	const [errors, setErrors] = useState({});
	const [selectedRole, setSelectedRole] = useState();
	const [emails, setEmails] = useState([]);
	const [focused, setFocused] = useState(false);
	const [formData, setFormData] = useState([]);

	const handleRoleChange = (value, _email) => {
		setFormData({
			...formData,
			[_email]: value.value,
		});
	};

	const handleApply = async () => {
		let input = [];
		let isError = false;

		emails.map((email) => {
			if (formData[email]) {
				let obj = { email: email, access_id: formData[email] };
				input.push(obj);
			} else {
				setErrors({ role: "Please select design system access" });
				isError = true;
				return;
			}
		});

		if (!isError) {
			onApply(input, data.id);
			onRequestClose();
			setErrors({});
			setEmails([]);
		}
	};

	return (
		<CommonModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={title}
			onApply={handleApply}
			classPrefix="plt"
			onCancelText="Cancel"
			onApplyText="Invite members"
			// bodyClassName='overflow-unset'
		>
			<div className="plt-modal-body-content">
				<div className="plt-profile-box d-flex align-items-center dis-p-24 dis-pt-16 dis-pb-16">
					{data?.design_system_name}
				</div>
				<div className="plt-select-dropdown-wrapper dis-pl-24 dis-pr-24">
					<h6 className="plt-select-dropdown-title plt-ui-text-sm-medium dis-mb-4">Email address</h6>
					<div className="plt-input-wrapper">
						<ReactMultiEmail
							placeholder="Enter email(s)"
							emails={emails}
							onChange={(_emails) => {
								setEmails(_emails);
							}}
							className="input-field-custom input-field-custom-multi plt-ui-text-sm-regular plt-font-color-primary"
							autoFocus={true}
							onFocus={() => setFocused(true)}
							onBlur={() => setFocused(false)}
							getLabel={(email, index, removeEmail) => {
								return (
									<div data-tag key={index}>
										<div data-tag-item>{email}</div>
										<span data-tag-handle onClick={() => removeEmail(index)}>
											×
										</span>
									</div>
								);
							}}
						/>
						{errors?.email && <FormValidationError error={errors?.email} />}
					</div>
				</div>
				<div className="plt-select-members-wrapper dis-pl-24 dis-pr-24">
					<ul className="plt-members-group w-100">
						{emails.map((_email, index) => (
							<li className="plt-members-list d-flex align-items-center" key={index}>
								<div className="plt-member-img">
									<img src={UserAvatar} alt="profile" className="plt-member-image user-image" />
								</div>
								<h3 className="plt-body-md font-weight-400">{_email}</h3>
								<div className="plt-select-dropdown-secondary ml-auto">
									<SelectInput
										options={PERMISIONS}
										selectedOption={selectedRole ?? "Select"}
										onSelect={(selectedValue) => handleRoleChange(selectedValue, _email)}
									/>
								</div>
							</li>
						))}
						{errors?.role && <FormValidationError error={errors?.role} />}
					</ul>
				</div>
			</div>
		</CommonModal>
	);
};

export default AddMemberModal;
