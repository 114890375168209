import React, { useState } from "react";
import { DropdownArrow } from "./Icons";
import Dropdown from "components/Common/Dropdown/Dropdown";

const FilterDropdown = ({
  setIsDropdownOpen,
  isDropdownOpen,
  name,
  options,
  onSelect,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (option) => {
    setValue(option);
    onSelect(option);
    setIsDropdownOpen(false);
  };
  return (
    <div className='plt-filter-list'>
      <div className='plt-select-dropdown-wrapper plt-menu-dropdown'>
        <button
          className='plt-btn plt-btn-secondary dis-mr-12'
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          <span className='plt-ui-text-sm-medium plt-font-color-primary font-weight-500 font dis-mr-6'>
            {name}
          </span>
          <span className='dss-icon'>
            <DropdownArrow />
          </span>
        </button>

        <Dropdown
          isOpen={isDropdownOpen}
          onToggle={setIsDropdownOpen}
          customClass=''
          trigger={isDropdownOpen}
        >
          <ul onClick={(e) => e.stopPropagation()}>
            {options.map((option, index) => (
              <li
                key={index}
                className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'
                onClick={(e) => {
                  handleChange(option);
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </Dropdown>
      </div>
    </div>
  );
};

export default FilterDropdown;
