import React, { useState } from "react";
// import { TabsData } from "../../../../assets/data/data";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../Common/ComponentCard";
// import "./PreviewEmptystateComponent.scss";
// import Tabs from "../../../Tabs";
// import { ButtonIconSample, DropdownArrow } from "../../../Icons";
// import Inputs from "../../../Inputs";
import emptyStateImg from "../../../../assets/images/png/empty-state.png";
// import Textarea from "../../../Textarea";
import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import EditButton from "../../../Buttons/EditButton";
import PrimaryButton from "components/Common/PrimaryButton";
import { EditIcon } from "components/Icons";
import "./PreviewEmptystateComponent.scss";

const PreviewEmptystateComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
			>
				usage
			</span>
		</p>
	);

	const emptystateData = [
		{
			id: 1,
			// title: "Extra large",
			description:
				"Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.",
			// code: <button className="dds__button dds__button--primary" type="button">Primary Button</button>,
			prefixIcon: true,
			suffixIcon: true,
		},
	];

	// const previewemptystateData = [
	//   {
	//     title: "Normal Default",
	//     desc: commonDesc,
	//     validation: true,
	//     fluid: false,
	//   },
	// ];

	const [isModalVisible, setModalVisibility] = useState(false);
	const showModal = () => {
		setModalVisibility(true);
	};

	const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
	const [buttonState, setButtonState] = useState(buttonStates[0]);

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Default</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						Lorem Ipsum is simply dummy text of the printing and typesetting industry
					</p>
				</div>
				{/* {ComponentPrimaryDatas.map((data) => ( */}
				{emptystateData.map((data, index) => (
					<div key={index} className="plt-content-wrapper dis-mb-24">
						<div className="plt-content-wrapper-block dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
								Normal prefix and suffix with leading and trailing icon
							</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								Lorem Ipsum is simply dummy text of the printing and typesetting industry
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							{/* <Textarea  validation={textareaConfig.validation}/> */}
							<div className="empty-state-box">
								<div className="dss-empty-state-box d-flex">
									<div className="dss-empty-state-img">
										<img src={emptyStateImg} alt="guidelinesimage" />
									</div>
									<div className="dss-empty-state-content-wrapper">
										<div className="dss-empty-state-content">
											<p className="dss-empty-state-text dss-font-color-primary">
												No alert configurations yet.
											</p>
											<span className="dss-empty-state-para d-block dss-font-color-tertiary">
												Set a new one.
											</span>
										</div>
										<PrimaryButton
											text={"Button text"}
											// className={data.className}
											variant= "secondary"
											icon={true}
											prefixIcon={data.prefixIcon}
											suffixIcon={data.suffixIcon}
										/>
									</div>
								</div>
							</div>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
			</div>

			<CommonModal
				isOpen={isModalVisible}
				onRequestClose={() => setModalVisibility(false)}
				title="Tokens"
				onApply={() => setModalVisibility(false)}
				onCancelText="Cancel"
				onApplyText="Apply"
				variant="modal-tertiary"
			>
				<div className="plt-modal-body-content">
					<div className="plt-dropdown-secondary">
						<div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
								<CustomSelect options={buttonStates} selectedOption={buttonState} />
							</div>
						</div>
					</div>

					<div className="plt-preview plt-heading-xl dis-mb-32">
						{/* {previewemptystateData.map((data, index) => (
               <Textarea  validation={data.validation}/>
            ))} */}
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Border color active</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">text.button.fill.primary</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Background color active</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
								background.button.{buttonState.toLowerCase()}.primary
							</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("bg");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Text color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">text.button.fill.primary</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Padding</h6>
						<div className="plt-token-value-item d-flex align-items-center">
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">H:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">V:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>

							<span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
								<EditIcon />
							</span>
						</div>
					</div>
				</div>
			</CommonModal>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewEmptystateComponent;
