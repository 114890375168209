import React, { useState } from "react";
import "./PreviewTableComponent.scss";
import { TableComponentPrimaryDatas } from "../../../../assets/data/data";
import TabNavigation from "../../TabNavigation";
// import CheckboxComponent from "../../../Common/CheckboxComponent";
import ComponentCard from "../../../Common/ComponentCard";
// import ScaleTable from "../../Typography/ScaleToken/ScaleTable";
// import ScaleTableSpacing from "../../Spacing/ScaleTokenSpacing/ScaleTableSpacing";
import Table from "../../../Table";

import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import EditButton from "../../../Buttons/EditButton";
import { EditIcon } from "components/Icons";

const PreviewTableComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
	const tokenTableData = [
		{
			value: "128px",
			tokenName: "space-3200",
			sizeValue: "size-128",
		},
		{
			value: "112px",
			tokenName: "space-2800",
			sizeValue: "size-112",
		},
		{
			value: "96px",
			tokenName: "space-2400",
			sizeValue: "size-96",
		},
		{
			value: "80px",
			tokenName: "space-2800",
			sizeValue: "size-80",
		},
		{
			value: "64px",
			tokenName: "space-2000",
			sizeValue: "size-64",
		},
		{
			value: "24px",
			tokenName: "space-600",
			sizeValue: "size-24",
		},
	];

	const headers = ["Size preview", "Size token", "Size"];

	const [isModalVisible, setModalVisibility] = useState(false);
	const showModal = () => {
		setModalVisibility(true);
	};

	const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
	const [buttonState, setButtonState] = useState(buttonStates[0]);

	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
			>
				usage
			</span>
		</p>
	);

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Default</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						A basic text field. If you use a text field outside of a form component, always use a label and associate
						the label to the field properly.
					</p>
				</div>
				{TableComponentPrimaryDatas.map((data, index) => (
					<div key={index} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<Table data={tokenTableData} headers={headers} />
						</ComponentCard>
						<AllUsage />
					</div>
				))}
			</div>
			<CommonModal
				isOpen={isModalVisible}
				onRequestClose={() => setModalVisibility(false)}
				title="Tokens"
				onApply={() => setModalVisibility(false)}
				onCancelText="Cancel"
				onApplyText="Apply"
				variant="modal-tertiary"
			>
				<div className="plt-modal-body-content">
					<div className="plt-dropdown-secondary">
						<div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
							<div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
								<CustomSelect options={buttonStates} selectedOption={buttonState} />
							</div>
						</div>
					</div>

					<div className="plt-preview plt-heading-xl dis-mb-32">
						{TableComponentPrimaryDatas.map((data, index) => (
							<Table key={index} data={tokenTableData} headers={headers} />
						))}
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Padding</h6>
						<div className="plt-token-value-item d-flex align-items-center">
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">H:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>
							<div className="d-flex align-items-center">
								<span className="dis-mr-4">V:</span>
								<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">spacing-200</p>
							</div>

							<span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Title text color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">text.button.fill.primary</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>

					<div className="plt-token-details dis-mb-32">
						<h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">Body text color</h6>
						<div className="plt-token-value-item d-flex">
							<p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">text.button.fill.primary</p>
							<span
								className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
								onClick={() => {
									setTokenType("text");
									setColorTokenModalOpen(true);
								}}
							>
								<EditIcon />
							</span>
						</div>
					</div>
				</div>
			</CommonModal>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewTableComponent;
