import React from "react";
// import moment from "moment";
// import { useGlobalContext } from "context/GlobalContext";

const FormTableRaw = ({ data }) => {
  // const { setLoading } = useGlobalContext();

  return (
    <tr>
      <td>
        <div className='plt-invoice-num d-flex align-items-center'>
          <p className='plt-body-compact-sm plt-font-color-primary'>
            {data.item}
          </p>
          <div className='plt-table-hover-icon dis-ml-8'></div>
        </div>
      </td>
      <td>
        <p className='plt-badge plt-badge-surface plt-body-compact-sm'>
         {data.tokenName}
        </p>
      </td>
      <td>
        <div className='plt-amount-paidplt-color-column d-flex'>
          <div className='plt-body-compact-sm plt-font-color-primary'>
            {data.role}
          </div>
        </div>
      </td>
     
    </tr>
  );
};

export default FormTableRaw;
