import React, { useState } from "react";
import "./OverviewFormPattern.scss";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import FormDetail from "../../../../assets/images/png/FormDetail.png";
import FormArchitecture from "../../../../assets/images/png/FormArchitecture.png";
import FormArchitectureTable from "../../../../assets/images/png/FormArchitectureTable.png";
import FormLabel from "../../../../assets/images/png/FormLabel.png";
import FormMultiple from "../../../../assets/images/png/FormMultiple.png";
import FormScreen from "../../../../assets/images/png/FormScreen.png";
import FormColumnn from "../../../../assets/images/png/FormColumnn.png";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";
import FormTable from "../FormTable";

const details = [
	{
		number: 1,
		title: "Label",
		description:
			"Text describing the button action. Use action verbs or phrases to tell the user what will happen next, and follow the button label content guidelines.",
	},
	{
		number: 2,
		title: "Text field",
		description:
			"Text describing the button action. Use action verbs or phrases to tell the user what will happen next, and follow the button label content guidelines.",
	},
	{
		number: 3,
		title: "Teaxtarea text",
		description:
			" Most buttons don’t need an icon. Use an icon to add additional affordance where the icon has a clear and well-established meaning.",
	},
	{ number: 4, title: "Dropdown label", description: "The text entered or edited by the user." },
	{
		number: 5,
		title: "Teaxtarea text",
		description:
			"Most buttons don’t need an icon. Use an icon to add additional affordance where the icon has a clear and well-established meaning.",
	},
	{
		number: 6,
		title: "Button",
		description:
			"Most buttons don’t need an icon. Use an icon to add additional affordance where the icon has a clear and well-established meaning.",
	},
];

const formTableData = [
	{
		item: "Text",
		tokenName: "heading-sm",
		role: "Heading of the form",
	},
	{
		item: "",
		tokenName: "text-primary",
		role: "Color of the heading",
	},
	{
		item: "Body",
		tokenName: "body-sm",
		role: "",
	},
	{
		item: "",
		tokenName: "text-secondary",
		role: "Color of the body",
	},
	{
		item: "Spacing",
		tokenName: "spacing-100",
		role: "",
	},
	{
		item: "",
		tokenName: "spacing-400",
		role: "",
	},
	{
		item: "",
		tokenName: "spacing-600",
		role: "",
	},
];



const headers = ["Item", "Name/token", "Role"];

const OverviewFormPattern = () => {
	const { isLoading } = useGlobalContext();
	const tabData = [
		"Anatomy",
		"Basic architecture",
		"Label spacing",
		"Form length",
		"Multiple buttons",
		"Screen position",
		"Single and multi-column forms",
	];

	const [activeSideTab, setActiveSideTab] = useState(0);

	const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div className="plt-content-block">
					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[0]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Anatomy
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							It is important to maintain the same style of radius for the same types components. Inconsistency will
							create a messy UI.
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={FormDetail} alt="userimage" />
					</div>

					<ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
						{details.map((detail) => (
							<DetailItem
								key={detail.number}
								number={detail.number}
								title={detail.title}
								description={detail.description}
							/>
						))}
					</ul>

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[1]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Basic architecture
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							The basic architecture of the form comes down to its arrangement of components and application of
							foundational styles. Single column forms are used for most forms, with two-column forms used in the case
							of larger screen size.
						</p>
					</div>

					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={FormArchitecture} alt="userimage" />
					</div>

					<div className="dis-mt-16 dis-mb-40">
						<FormTable  data={formTableData}/>
					</div>

{/* 				
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center dis-mt-24 dis-mb-48">
						<img src={FormArchitectureTable} alt="userimage" />
					</div> */}

					<div className="plt-content-wrapper dis-mb-24">
						<h6
							ref={sectionRefs.current[2]}
							className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
						>
							Label spacing
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							In the case of a form-only screen, the forms are likely to be center-aligned. If an image takes up a
							portion of the screen, align the form to the center of the whitespace.
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center  dis-mb-48">
						<img src={FormLabel} alt="userimage" />
					</div>

					<div className="plt-content-wrapper dis-mb-48">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Form length</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							It is important to refrain from overwhelming the user with tedious, overly long forms. Keep the user’s
							attention on the form by breaking it down into steps.
						</p>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							In a single step, avoid making the user enter more than 10 fields.
						</p>
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Multiple buttons</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							In the case of multiple CTAs, align the primary and secondary actions to the bottom right with the
							recommended spacing, and the tertiary button to the bottom left. Back links are aligned to the top-left.
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center dis-mb-48">
						<img src={FormMultiple} alt="userimage" />
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">Screen position</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							In the case of a form-only screen, the forms are likely to be center-aligned. If an image takes up a
							portion of the screen, align the form to the center of the whitespace.
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center  dis-mb-48">
						<img src={FormScreen} alt="userimage" />
					</div>

					<div className="plt-content-wrapper dis-mb-24">
						<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
							Single and multi-column forms
						</h6>
						<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
							In the case of a form-only screen, the forms are likely to be centre-aligned. If an image takes up a
							portion of the screen, align the form to the center of the whitespace.
						</p>
					</div>
					<div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
						<img src={FormColumnn} alt="userimage" />
					</div>
				</div>
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default OverviewFormPattern;
