import React, { useState, useRef, useEffect } from "react";
import SettingsLayout from "components/layouts/SettingsLayout";
import TeamTable from "./TeamTable";
import AddMemberModal from "./AddMemeberModal";
import { useGlobalContext } from "context/GlobalContext";
import { getUser } from "hooks/storageUtils";
import { addMember, fetchTeamMembers, fetchRoles } from "api";
import "./Team.scss";
import UpgradePlanAlertModal from "components/modal/UpgradePlanAlertModal";
import { checkFeaturePermission } from "api";
import { fetchFeatureValueAndUsage } from "api";
import CircularLoader from "components/Common/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import FilterDropdown from "components/FilterDropdown";

const Team = () => {
  const [isModalOpen, setModalOpen] = useState(false); //Add memeber
  const [canAddMember, setCanAddMember] = useState(false); //permission
  const [isUpgradePlanAlertModalOpen, setUpgradePlanAlertModalOpen] =
    useState(false); //permission

  const [teams, setTeams] = useState();
  const [allTeams, setAllTeams] = useState();
  const [roles, setRoles] = useState([]);
  const [valueAndUsage, setValueAndUsage] = useState([]);

  const { setLoading } = useGlobalContext();

  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [accessDropdownOpen, setAccessDropdownOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

  const closeUpgradePlanAlertModal = () => {
    setUpgradePlanAlertModalOpen(false);
  };

  useEffect(() => {
    const getTeamMembers = async (userId) => {
      try {
        setLoading(true);
        const { data } = await fetchTeamMembers(userId);
        setTeams(data);
        setAllTeams(data);
      } catch (error) {
        console.error("Failed to fetch team memeber", error);
      } finally {
        setLoading(false);
      }
    };

    const getRoles = async () => {
      try {
        setLoading(true);
        const data = await fetchRoles();
        setRoles(
          data?.data?.map((d) => {
            return { value: d.id, label: d.name };
          })
        );
      } catch (error) {
        console.error("Failed to fetch design system", error);
      } finally {
        setLoading(false);
      }
    };
    getRoles();

    const user = getUser();
    getTeamMembers(user.id);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setAccessDropdownOpen(false);
        setStatusDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkPermission = async (adminId, permission) => {
      try {
        setLoading(true);
        const { data } = await checkFeaturePermission(adminId, permission);
        setCanAddMember(data);
      } catch (error) {
        toast.error(error.message);
        console.error("Failed to check permission", error);
      } finally {
        setLoading(false);
      }
    };
    let user = getUser();

    checkPermission(user.id, "total_users");

    const getFeatureValueAndUsage = async (adminId, permission) => {
      try {
        setLoading(true);
        const { data } = await fetchFeatureValueAndUsage(adminId, permission);
        setValueAndUsage(data);
      } catch (error) {
        console.error("Failed to get feture value and usage", error);
      } finally {
        setLoading(false);
      }
    };

    getFeatureValueAndUsage(user.id, "total_users");
  }, [teams]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleAddMember = async (request) => {
    try {
      setLoading(true);
      const user = getUser();
      const input = { data: request, admin_id: user.id };
      const { data, upgrade } = await addMember(input);
      setTeams((teams) => [...teams, ...data]);
      if (upgrade) {
        setUpgradePlanAlertModalOpen(true);
      }
    } catch (error) {
      console.error("Failed to add member", error);
    } finally {
      setLoading(false);
    }
  };
  const handleUpgradePlantApply = () => {};

  const handleRoleSelect = (value) => {
    let role = value === "Admin" ? 1 : 2;
    setTeams(allTeams.filter((all) => all.role_id === role));
  };
  const handleAccessSelect = (value) => {
    let access = value === "Viewer" ? 2 : 1;
    let filteredTeams = allTeams.filter((item) =>
      item.permission?.some((permission) => permission.access_id === access)
    );
    setTeams(filteredTeams);
  };
  const handleStatusSelect = (value) => {
    if (value === "Joined")
      setTeams(allTeams.filter((all) => all.registered_at));
    else {
      setTeams(allTeams.filter((all) => !all.registered_at));
    }
  };

  return (
    <SettingsLayout
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      canAddMember={canAddMember}
      setCanAddMember={setCanAddMember}
      isUpgradePlanAlertModalOpen={isUpgradePlanAlertModalOpen}
      setUpgradePlanAlertModalOpen={setUpgradePlanAlertModalOpen}
    >
      <div className='tab-content-wrapper tab-content-setting'>
        <div className='tab-content-container'>
          <div className={`plt-content-block dis-mb-32 w-100`}>
            <div className='plt-content-wrapper dis-mb-24'>
              <div className='plt-content-block-top d-flex align-items-center w-100'>
                <div className='plan-steps-box d-flex align-items-center w-100'>
                  <div className='plan-steps-img dis-mr-8'>
                    <CircularLoader
                      percentage={
                        (valueAndUsage?.usage / valueAndUsage?.value) * 100
                      }
                    />
                  </div>
                  <div className='plan-steps-content'>
                    <p className='plt-contents-desc plt-heading-sm plt-font-color-primary font-weight-700'>
                      {valueAndUsage?.usage || 0} of {valueAndUsage?.value}
                      <span className='d-block plt-body-md'>User added</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='plt-btn-wrapper d-flex'></div>
            </div>
            <div className='plt-filter-wrapper dis-mb-12 d-flex align-items-center'>
              <FilterDropdown
                name='Role'
                options={["Admin", "Member"]}
                setIsDropdownOpen={setIsDropdownOpen}
                isDropdownOpen={isDropdownOpen}
                onSelect={handleRoleSelect}
              />

              <FilterDropdown
                name='Access'
                options={["Viewer", "Editor"]}
                setIsDropdownOpen={setAccessDropdownOpen}
                isDropdownOpen={accessDropdownOpen}
                onSelect={handleAccessSelect}
              />
              <FilterDropdown
                name='Status'
                options={["Joined", "Pending"]}
                setIsDropdownOpen={setStatusDropdownOpen}
                isDropdownOpen={statusDropdownOpen}
                onSelect={handleStatusSelect}
              />
            </div>
            <TeamTable data={teams} roles={roles} setTeams={setTeams} />
          </div>
        </div>
      </div>
      <AddMemberModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onApply={handleAddMember}
        title='Add members'
        roles={roles}
      />
      <UpgradePlanAlertModal
        isOpen={isUpgradePlanAlertModalOpen}
        onRequestClose={closeUpgradePlanAlertModal}
        onApply={handleUpgradePlantApply}
        title='Upgrade Plan'
      />
    </SettingsLayout>
  );
};

export default Team;
