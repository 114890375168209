import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../../context/GlobalContext";
import {
	ComponentsIcon,
	ContentIcon,
	OverviewIcon,
	PatternsIcon,
	ResourceIcon,
	SidebarHamburger,
	StylesIcon,
} from "../../Icons";
import "./Sidebar.scss";

const getCssVariableValue = (variableName) => {
	return getComputedStyle(document.documentElement).getPropertyValue(variableName);
};

const Sidebar = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { activeTab, setActiveTab, isCollapsed, setIsCollapsed } = useGlobalContext();

	const [colorInfo, setColorInfo] = useState(getCssVariableValue("--dss-color-primary-300"));

	const toggleSidebar = () => {
		setIsCollapsed((prevState) => !prevState);
	};

	useEffect(() => {
		const handleColorChange = () => {
			setColorInfo(getCssVariableValue("--dss-color-primary-300"));
		};

		const observer = new MutationObserver(handleColorChange);
		observer.observe(document.documentElement, {
			attributes: true,
			attributeFilter: ["style"],
		});

		handleColorChange();

		return () => {
			observer.disconnect();
		};
	}, []);

	const sidebarItems = [
		{
			id: "overview",
			title: "Overview",
			link: `/`,
			icon: <OverviewIcon color={colorInfo} />,
		},
		{
			id: "styles",
			title: "Styles",
			link: `${id}/styles/colors`,
			icon: <StylesIcon color={colorInfo} />,
		},
		{
			id: "components",
			title: "Components",
			link: `${id}/components/button`,
			icon: <ComponentsIcon color={colorInfo} />,
		},
		{
			id: "content",
			title: "Content",
			link: `${id}/content/home`,
			icon: <ContentIcon color={colorInfo} />,
		},
		{
			id: "resources",
			title: "Resources",
			link: `${id}/resources/home`,
			icon: <ResourceIcon color={colorInfo} />,
		},
		{
			id: "patterns",
			title: "Patterns",
			link: `${id}/patterns/forms`,
			icon: <PatternsIcon color={colorInfo} />,
		},
		{
			id: "preview",
			title: "Preview",
			link: `${id}/preview`,
			icon: <PatternsIcon color={colorInfo} />,
		},
		// {
		//   id: "componentlist",
		//   title: "CompoList",
		//   link: `${id}/componentlist/home`,
		//   icon: <ResourceIcon color={colorInfo} />,
		// },
		// {
		//   id: "settings",
		//   title: "Settings",
		//   link: `${id}/settings`,
		//   icon: <SettingsIcon color={colorInfo} />,
		//   disabled: true, // Mark settings as disabled
		// },
		// {
		// 	id: "logout",
		// 	title: "Logout",
		// 	link: `/login`,
		// 	icon: <LogoutIcon color={colorInfo} />,
		// 	disabled: false, // Mark settings as disabled
		// },
	];

	const determineActiveTab = () => {
		if (location.pathname.includes("/overview")) {
			return "overview";
		} else if (location.pathname.includes("/styles")) {
			return "styles";
		} else if (location.pathname.includes("/components")) {
			return "components";
		} else if (location.pathname.includes("/settings")) {
			return "settings";
		} else if (location.pathname.includes("/content")) {
			return "content";
		} else if (location.pathname.includes("/resources")) {
			return "resources";
		} else if (location.pathname.includes("/patterns")) {
			return "patterns";
		} else if (location.pathname.includes("/componentlist")) {
			return "componentlist";
		}
		return ""; // Default, no active tab
	};

	useEffect(() => {
		const activeTab = determineActiveTab();
		setActiveTab(activeTab);
	}, [location, setActiveTab]);

	return (
		<div className="sidebar-container">
			<aside className="sidebar">
				<span onClick={toggleSidebar} className={`sidebar-hamburger cursor-pointer ${isCollapsed ? "" : "active"}`}>
					<SidebarHamburger />
				</span>
				<div className="sidebar-content">
					{sidebarItems.map((item) => {
						const isActive = activeTab === item.id;
						const isDisabled = item.disabled;

						return (
							<div
								key={item.id}
								onClick={() => {
									if (!isDisabled) {
										setActiveTab(item.id);
										if (item.title === "Overview") {
											navigate(`/${id}/overview/getting-started`);
										} else if (item.title === "Styles") {
											navigate(`/${id}/styles/colors`);
										} else if (item.title === "Components") {
											navigate(`/${id}/components/button`);
										} else if (item.title === "Content") {
											navigate(`/${id}/content/home`);
										} else if (item.title === "Resources") {
											navigate(`/${id}/resources/home`);
										} else if (item.title === "CompoList") {
											navigate(`/${id}/componentlist/home`);
										} else if (item.title === "Patterns") {
											navigate(`/${id}/patterns/forms`);
										} else if (item.title === "Preview") {
											navigate(`/${id}/preview`);
										} else if (item.title === "Settings") {
											navigate(`/${id}/settings`);
										}
									}
								}}
								className={`sidebar-item ${isActive ? "active" : ""} ${isDisabled ? "disabled" : ""}`}
							>
								<div className="sidebar-menu d-flex align-items-center justify-content-center">
									<span className="logo">{item.icon}</span>
								</div>
								<div className="sidebar-title plt-body-compact-xs dis-mt-4">{item.title}</div>
							</div>
						);
					})}
				</div>
			</aside>
		</div>
	);
};

export default Sidebar;
