import React from "react";
import "./CharacterBox.scss";

const CharacterBox = ({ characterBoxData, setSelectedTab, className }) => {
  return (
    <div className='plt-character-box dis-p-40'>
      {/* <h6 className="plt-character-title plt-heading-2xl plt-font-color-primary dss-font-family-secondary">{characterBoxData.name}</h6> */}
      <h6
        className={`${characterBoxData.font_family} ${className}`}
        style={{ fontFamily: characterBoxData.name }}
      >
        {characterBoxData.name}
      </h6>
      <p className='plt-character-des plt-body-sm dis-pt-8'>
        {characterBoxData.content}
        <span
          onClick={() => setSelectedTab(1)}
          className='plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer'
        >
          {characterBoxData.nested_desc}
        </span>{" "}
        {characterBoxData.content2}
      </p>
    </div>
  );
};

export default CharacterBox;
