import React, { useState, useRef } from "react";
import "./ScaleTokenSpacing.scss";
import ScaleTableSpacing from "./ScaleTableSpacing";
import TabNavigation from "../../TabNavigation/index";
import { useGlobalContext } from "context/GlobalContext";
import { getValueByPath } from "helpers/token";
import { removeTypeFromTokens } from "helpers/token";
import useScrollManagement from "../../../../hooks/useScrollManagement";

const ScaleTokenSpacing = () => {
	const { designSystem } = useGlobalContext();
	const tabData = ["Scales and tokens"];
	const tokenTableData = getValueByPath(designSystem, "core.spacing");
	const spacingData = removeTypeFromTokens(tokenTableData ? tokenTableData : [], "button"); //remove button specific spacing
	const [activeTab, setActiveTab] = useState(0);
	const [selectedTab, setSelectedTab] = useState(0);

	const [activeSideTab, setActiveSideTab] = useState(0);

	const sectionRefs = useRef([React.createRef(), React.createRef()]);

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleHeaderTabClick = (id) => {
		setSelectedTab(id);
	};

	const handleTabClick = (index) => {
		setActiveTab(index);
		scrollToSection(index);
	};

	const headers = ["Size preview", "Size token", "Size"];

	return (
		<div className="d-flex justify-content-between w-full">
			<div ref={sectionRefs?.current[0]} className="tab-content-container">
				<div className="plt-content-block dis-mb-0">
					<div className="plt-content-wrapper">
						<div className="plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8">
							<h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Scales and tokens</h6>
						</div>
					</div>
				</div>
				<ScaleTableSpacing data={spacingData} headers={headers} />
			</div>
			<div className="plt-token-contents ">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default ScaleTokenSpacing;
