import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";

import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import CommonEditor from "components/CommonEditor";

import "assets/scss/pages/DesignSystem.scss";

const ResourcesPage = () => {
  const { id, pageId } = useParams();
  const [page, setPage] = useState("");
  const [pages, setPages] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [bannerImage, setBannerImage] = useState("");
  const { designSystemMeta } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    let currentPage = {};
    if (designSystemMeta?.pages) {
      const resourcesPages = designSystemMeta.pages.filter(
        (page) => page.type == "resources"
      );

      setPages(resourcesPages);
      currentPage = designSystemMeta.pages.find(
        (page) => page.type == "resources"
      );
      setPage(currentPage);
    }

    if (pageId === "home") {
      navigate(`/${id}/resources/${currentPage?.id}`);
    }
  }, [designSystemMeta]);

  const bannerData = {
    heading: designSystemMeta?.design_system_name,
    description:
      "Foundations inform the basis of any great user interface, from accessibility standards to essential patterns for layout and interaction.",
    bannerImage: bannerImage,
  };

  let sideTabs = pages?.map((page) => {
    return { title: page.name, link: `/${id}/resources/${page.id}` };
  });

  const tabs = ["Resources"];
  return (
    <DesignSystemLayout
      designSystemName={designSystemMeta?.design_system_name}
      bannerData={bannerData}
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      setBannerImage={setBannerImage}
      sideTabs={sideTabs}
      title="Resources"
    >
      <CommonEditor page={page} />
    </DesignSystemLayout>
  );
};

export default ResourcesPage;
