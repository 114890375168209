import React from "react";
import TooltilGuideline1 from "assets/images/png/TooltilGuideline1.png";
import TooltilGuideline2 from "assets/images/png/TooltilGuideline2.png";

const ComponentPrimaryDatas = [
	{
		id: 1,
		title: "Do",
		titleClassname: "active",
		image: TooltilGuideline1,
		description:
			"Place tooltips adjacent to their trigger elements, aligning with the top for clarity. Maintain consistent spacing between tooltips and triggers. Use succinct, directive text within tooltips to ensure clear communication.",
	},
	{
		id: 2,
		title: "Don’t",
		titleClassname: "inactive",
		image: TooltilGuideline2,
		description:
			"Avoid using interactive elements within a tooltip to maintain simplicity. Tooltips should provide information without additional actions. Focus on delivering clear, non-interactive content to enhance usability.",
	},
];

const GuidelineCardTooltip = () => {
	return (
		<div className="guideline-card d-flex justify-content-between">
			{ComponentPrimaryDatas.map((data) => (
				<div key={data.id} className="guideline-wrapper">
					<h6 className={`guideline-badge plt-body-compact-md dis-mb-8 ${data.titleClassname}`}>{data.title}</h6>
					<div className="guideline-detail-wrapper d-flex align-items-center justify-content-center">
						<div className="guideline-img">
							<img src={data.image} alt="userimage" />
						</div>
					</div>
					<p className="guideline-desc plt-body-md dis-mt-12">{data.description}</p>
				</div>
			))}
		</div>
	);
};

export default GuidelineCardTooltip;
