import React, { useState, useRef } from "react";
import "./PreviewToggleComponent.scss";
import { ToggleComponentPrimaryDatas } from "../../../../assets/data/data";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../../components/Common/ComponentCard";
import ToggleComponent from "../../../Common/ToggleComponent";
import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import EditButton from "../../../Buttons/EditButton";
import { EditIcon } from "components/Icons";
import useScrollManagement from "hooks/useScrollManagement";

const PreviewToggleComponent = ({ setSelectedTab }) => {
	const tabData = ["Default", "Toggle button with label"];
	const [isModalVisible, setModalVisibility] = useState(false);
	const [activeSideTab, setActiveSideTab] = useState(0);
	const [checkedStates, setCheckedStates] = useState(ToggleComponentPrimaryDatas.map(() => true));

	const showModal = () => {
		setModalVisibility(true);
	};

	const AllUsage = () => (
		<p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
			See all states{" "}
			<span
				onClick={() => setSelectedTab(1)}
				className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
			>
				usage
			</span>
		</p>
	);

	const sectionRefs = useRef(tabData.map(() => React.createRef()));

	const scrollToSection = useScrollManagement(sectionRefs.current, activeSideTab, setActiveSideTab, "tab-content-body");

	const handleTabClick = (index) => {
		setActiveSideTab(index);
		scrollToSection(index);
	};

	const handleToggleChange = (index) => {
		setCheckedStates((prevStates) => prevStates.map((checked, i) => (i === index ? !checked : checked)));
	};

	return (
		<div className="tab-content-wrapper d-flex justify-content-between">
			<div className="tab-content-container">
				<div ref={sectionRefs?.current[0]} className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Default</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						A basic text field. If you use a text field outside of a form component, always use a label and associate
						the label to the field properly.
					</p>
				</div>
				{ToggleComponentPrimaryDatas.map((data, index) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<ToggleComponent
								size={data.size}
								hasLabel={false}
								token={data.token}
								checked={checkedStates[index]}
								disabled={data.disabled}
								onChange={() => handleToggleChange(index)}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
				<div ref={sectionRefs?.current[1]} className="plt-content-wrapper dis-mb-24">
					<h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">Toggle button with label</h6>
					<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
						A basic text field. If you use a text field outside of a form component, always use a label and associate
						the label to the field properly.
					</p>
				</div>
				{ToggleComponentPrimaryDatas.map((data, index) => (
					<div key={data.id} className="dis-mb-32">
						<div className="plt-content-wrapper dis-mb-24">
							<h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">{data.title}</h6>
							<p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
								{data.description}
							</p>
						</div>
						<ComponentCard editClick={showModal}>
							<ToggleComponent
								size={data.size}
								hasLabel={true}
								toggletext="Toggle Text"
								token={data.token}
								checked={checkedStates[index]}
								disabled={data.disabled}
								onChange={() => handleToggleChange(index)}
							/>
						</ComponentCard>
						<AllUsage />
					</div>
				))}
			</div>
			<div className="plt-token-contents">
				<TabNavigation tabs={tabData} title={"CONTENTS"} activeIndex={activeSideTab} onTabClick={handleTabClick} />
			</div>
		</div>
	);
};

export default PreviewToggleComponent;
